import {
  REQUEST,
  SUCCESS,
  FAILURE,
  GET_HOTEL_POLICIES,
  HOTEL_POLICIES,
  GET_POLICIES,
  ADD_HOTEL_POLICY,
  DELETE_HOTEL_POLICY,
} from '@/redux/store/action-types';


const initialState = {
  policies: JSON.parse(localStorage.getItem('policies')) || null,
  selectedPolicy: JSON.parse(localStorage.getItem('selectedPolicy')) || null,
  hotelPolicies: JSON.parse(localStorage.getItem('hotelPolicies')) || null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_HOTEL_POLICIES[REQUEST]:
      return {
        ...state,
        error: null,
      };
    case GET_HOTEL_POLICIES[SUCCESS]:
      return {
        ...state,
        ...action.payload,
        hotelPolicies: JSON.parse(localStorage.getItem('hotelPolicies')) || null,
      };
    case GET_HOTEL_POLICIES[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };

    case GET_POLICIES[REQUEST]:
      return {
        ...state,
        error: null,
      };
    case GET_POLICIES[SUCCESS]:
      return {
        ...state,
        ...action.payload,
        policies: JSON.parse(localStorage.getItem('policies')) || null,
      };
    case GET_POLICIES[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };

    case HOTEL_POLICIES[REQUEST]:
      return {
        ...state,
        error: null,
      };
    case HOTEL_POLICIES[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case HOTEL_POLICIES[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case ADD_HOTEL_POLICY[REQUEST]:
      return {
        ...state,
        error: null,
      };
    case ADD_HOTEL_POLICY[SUCCESS]:
      return {
        ...state,
        ...action.payload,
        hotelPolicies: JSON.parse(localStorage.getItem('hotelPolicies')) || null,
      };
    case ADD_HOTEL_POLICY[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };

    case DELETE_HOTEL_POLICY[REQUEST]:
      return {
        ...state,
        error: null,
      };
    case DELETE_HOTEL_POLICY[SUCCESS]:
      return {
        ...state,
        ...action.payload,
        hotelPolicies: JSON.parse(localStorage.getItem('hotelPolicies')) || null,
      };
    case DELETE_HOTEL_POLICY[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
