import {
  REQUEST,
  SUCCESS,
  FAILURE,
  GET_HOTEL,
  EDIT_HOTEL_SAVE,
  EDIT_HOTEL_FETCH,
  EDIT_HOTEL_ADD,
  GET_HOTELS,
  HOTEL_DELETE,
  IMAGE_SAVE,
} from '@/redux/store/action-types';
import axios from 'axios';
import action from '../action';
import Notification from '@/components/Notification';

export const getHotel = hotelId => async (dispatch) => {
  dispatch(action(GET_HOTEL[REQUEST]));
  try {
    const hotel = await axios.get(`${process.env.REACT_APP_API_URL}/user/getHotel/${hotelId}`);

    const hotelMarkets = await axios.get(`${process.env.REACT_APP_API_URL}/user/getHotelMarkets`);

    const hotelMarketInfo = await axios.get(`${process.env.REACT_APP_API_URL}/user/getHotelMarketInfo/${hotelId}`);

    const market = hotelMarkets.data.hotelMarkets.filter(
      val => val.Id === hotelMarketInfo.data.marketHotels.MarketId,
    );

    const marketLocation = {
      MarketId: market[0].Id,
      Id: hotelMarketInfo.data.marketHotels.Id,
      HotelId: hotelMarketInfo.data.marketHotels.HotelId,
      Location: `${market[0].MarketCode}-${market[0].LocationName}`,
    };

    const newHotel = {
      ...hotel.data.hotel,
      MarketLocation: marketLocation,
      HotelMarkets: hotelMarkets.data.hotelMarkets,
    };

    const res = {
      data: newHotel,
    };

    const res2 = {
      data: marketLocation,
    };

    localStorage.setItem('hotel', JSON.stringify(newHotel));
    localStorage.setItem('selectedMarket', JSON.stringify(marketLocation));

    dispatch(action(GET_HOTEL[SUCCESS], {
      hotel: res.data.newHotel,
      selectedMarket: res2.data.marketLocation,
    }));
  } catch (error) {
    dispatch(action(GET_HOTEL[FAILURE], { error }));
  }
};

export const getHotels = () => async (dispatch) => {
  dispatch(action(GET_HOTELS[REQUEST]));
  try {
    const result = await axios.get(`${process.env.REACT_APP_API_URL}/user/getHotels`);

    const user = JSON.parse(localStorage.getItem('user'));

    const newUser = {
      ...user,
      hotels: result.data.hotels,
    };

    const res = {
      data: newUser,
    };

    localStorage.setItem('user', JSON.stringify(newUser));

    dispatch(action(GET_HOTELS[SUCCESS], {
      user: res.data.newUser,
    }));
  } catch (error) {
    dispatch(action(GET_HOTELS[FAILURE], { error }));
  }
};

export const getNewHotel = () => async (dispatch) => {
  dispatch(action(GET_HOTEL[REQUEST]));
  try {
    const hotelMarkets = await axios.get(`${process.env.REACT_APP_API_URL}/user/getHotelMarkets`);

    const newHotel = {
      MarketLocation: null,
      HotelMarkets: hotelMarkets.data.hotelMarkets,
      HoteltypeId: 0,
    };

    const res = {
      data: newHotel,
    };

    localStorage.setItem('hotel', JSON.stringify(newHotel));
    localStorage.setItem('selectedMarket', JSON.stringify(null));

    dispatch(action(GET_HOTEL[SUCCESS], {
      hotel: res.data.newHotel,
      selectedMarket: null,
    }));
    dispatch(action(IMAGE_SAVE[SUCCESS], {
      images: [],
    }));
  } catch (error) {
    dispatch(action(GET_HOTEL[FAILURE], { error }));
  }
};

export const saveHotel = hotel => async (dispatch) => {
  dispatch(action(EDIT_HOTEL_SAVE[REQUEST]));
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/user/editHotel/${hotel.HotelId}`, { hotel });
    Notification.openNotification({
      message: 'Data is saved.',
      description: 'Data is saved.',
      type: 'success',
    });
    dispatch(action(EDIT_HOTEL_SAVE[SUCCESS]));
  } catch (error) {
    Notification.openNotification({
      message: 'Something happened',
      description: 'We are working on fixing problems',
      type: 'error',
    });
    dispatch(action(EDIT_HOTEL_SAVE[FAILURE], { error }));
  }
};

export const addHotel = hotel => async (dispatch) => {
  dispatch(action(EDIT_HOTEL_ADD[REQUEST]));

  let result = 0;

  try {
    const hotelId = await axios.post(`${process.env.REACT_APP_API_URL}/user/addHotel`, { hotel });

    Notification.openNotification({
      message: 'Data is saved.',
      description: 'Data is saved.',
      type: 'success',
    });

    dispatch(action(EDIT_HOTEL_ADD[SUCCESS]));

    result = hotelId.data.hotelId;
  } catch (error) {
    Notification.openNotification({
      message: 'Something happened',
      description: 'We are working on fixing problems',
      type: 'error',
    });
    dispatch(action(EDIT_HOTEL_ADD[FAILURE], { error }));
  }

  return result;
};

export const fetchHotel = hotelId => async (dispatch) => {
  dispatch(action(EDIT_HOTEL_FETCH[REQUEST]));
  try {
    const hotel = await axios.get(`${process.env.REACT_APP_API_URL}/user/getHotel/${hotelId}`);
    const hotelMarkets = await axios.get(`${process.env.REACT_APP_API_URL}/user/getHotelMarkets`);
    const hotelMarketInfo = await axios.get(`${process.env.REACT_APP_API_URL}/user/getHotelMarketInfo/${hotelId}`);

    const market = hotelMarkets.data.hotelMarkets.filter(
      val => hotelMarketInfo.data.marketHotels
             && val.Id === hotelMarketInfo.data.marketHotels.MarketId,
    );

    let marketLocation = {};
    if (market.length > 0) {
      marketLocation = {
        MarketId: market[0].Id,
        Id: hotelMarketInfo.data.marketHotels.Id,
        HotelId: hotelMarketInfo.data.marketHotels.HotelId,
        Location: `${market[0].MarketCode}-${market[0].LocationName}`,
      };
    }

    const newHotel = {
      ...hotel.data.hotel,
      MarketLocation: marketLocation,
      HotelMarkets: hotelMarkets.data.hotelMarkets,
    };

    const res = {
      data: newHotel,
    };

    const res2 = {
      data: marketLocation,
    };

    localStorage.setItem('hotel', JSON.stringify(newHotel));
    localStorage.setItem('selectedMarket', JSON.stringify(marketLocation));
    dispatch(action(EDIT_HOTEL_FETCH[SUCCESS], {
      hotel: res.data.newHotel,
      selectedMarket: res2.data.marketLocation,
    }));
  } catch (error) {
    dispatch(action(EDIT_HOTEL_FETCH[FAILURE], { error }));
  }
};

export const deleteHotel = hotel => async (dispatch) => {
  dispatch(action(HOTEL_DELETE[REQUEST]));
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}/user/deleteHotel/${hotel.HotelId}`);
    dispatch(action(HOTEL_DELETE[SUCCESS]));
  } catch (error) {
    dispatch(action(HOTEL_DELETE[FAILURE], { error }));
  }
};
