import {
  REQUEST,
  SUCCESS,
  FAILURE,
  INVENTORY_FETCH,
  INVENTORY_SAVE,
  PRODUCTS_FETCH,
  PRODUCT_DELETE,
  USER_LOGIN,
} from '@/redux/store/action-types';
import axios from 'axios';
import action from '../action';
import Notification from '@/components/Notification';

export const fetchProducts = hotelId => async (dispatch) => {
  dispatch(action(INVENTORY_FETCH[REQUEST]));
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/hotel-products/${hotelId}`,
    );

    dispatch(action(INVENTORY_FETCH[SUCCESS], {
      products: res.data.products,
    }));
  } catch (error) {
    dispatch(action(INVENTORY_FETCH[FAILURE], { error }));
  }
};

export const fetchProductsFull = (hotelId, onlyActive = false) => async (dispatch) => {
  dispatch(action(PRODUCTS_FETCH[REQUEST]));
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/hotel-products-full/${hotelId}`, {
        params:
        { onlyActive },
      },
    );

    dispatch(action(PRODUCTS_FETCH[SUCCESS], {
      products: res.data.products,
    }));
  } catch (error) {
    dispatch(action(PRODUCTS_FETCH[FAILURE], { error }));
  }
};

export const saveProduct = product => async (dispatch) => {
  dispatch(action(INVENTORY_SAVE[REQUEST]));
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/products/${product.id}`, { product });
    Notification.openNotification({
      message: 'Data is saved.',
      description: 'New inventory & prices will take effect next day',
      type: 'success',
    });
    dispatch(action(INVENTORY_SAVE[SUCCESS]));
  } catch (error) {
    Notification.openNotification({
      message: 'Something happened',
      description: 'We are working on fixing problems',
      type: 'error',
    });
    dispatch(action(INVENTORY_SAVE[FAILURE], { error }));
  }
};

export const saveProductFull = product => async (dispatch) => {
  let { ProductId } = product;
  const url = `${process.env.REACT_APP_API_URL}/product`;
  const isUpdate = Number(ProductId);

  try {
    if (isUpdate) {
      await axios.post(`${url}/updateFull`, { product });
    } else {
      ProductId = (await axios.post(`${url}/add`, { product })).data.productId;
    }
    Notification.openNotification({
      message: 'Data is saved.',
      description: 'Your changes will take immediate effect.',
      type: 'success',
    });
  } catch (error) {
    Notification.openNotification({
      message: 'Something happened',
      description: 'We are working on fixing problems',
      type: 'error',
    });
  }

  const selectedHotel = JSON.parse(localStorage.getItem('selectedHotel'));
  const newProduct = {
    id: Number(ProductId),
    name: product.ProductName,
    type: product.ProductType,
  };
  if (isUpdate) {
    console.log(selectedHotel.products, ProductId);
    selectedHotel.products = [...selectedHotel.products
      .map(p => (p.id === Number(ProductId) ? newProduct : p))];
  } else {
    selectedHotel.products = [...selectedHotel.products, newProduct];
  }
  dispatch(action(USER_LOGIN[SUCCESS], {
    selectedHotel,
  }));

  return ProductId;
};

export const deleteProduct = id => async (dispatch) => {
  dispatch(action(PRODUCT_DELETE[REQUEST]));
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}/products/${id}`);
    Notification.openNotification({
      message: 'Product was deleted.',
      type: 'success',
    });
    dispatch(action(PRODUCT_DELETE[SUCCESS], {
      id,
    }));

    const selectedHotel = JSON.parse(localStorage.getItem('selectedHotel'));
    selectedHotel.products = [...selectedHotel.products.filter(p => p.id !== id)];
    dispatch(action(USER_LOGIN[SUCCESS], {
      selectedHotel,
    }));
  } catch (error) {
    Notification.openNotification({
      message: 'Something happened',
      description: 'We are working on fixing problems',
      type: 'error',
    });
    dispatch(action(PRODUCT_DELETE[FAILURE], { error }));
  }
};
