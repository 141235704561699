/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
/* eslint-disable quotes */
/* eslint-disable jsx-quotes */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-useless-constructor */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  saveProduct,
  addImage,
  updateImageOrder,
  deleteImage,
  saveProductFull,
} from '@/redux/actions';
import {
  Button, Form, PageHeader, Input, Select, Switch, InputNumber,
  Divider,
} from 'antd';
import axios from 'axios';
import EditableMarkup from '@/components/EditableMarkup';
import Dropzone from '@/components/DropzoneComponent';
import styles from './styles.module.css';
import { ProductTypes } from '../../utils/helper';

const { TextArea } = Input;
const { Option } = Select;

const routes = [
  {
    path: 'dashboard',
    breadcrumbName: 'Dashboard',
  },
  {
    path: 'products',
    breadcrumbName: 'Products',
  },
];

class EditProductPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <PageHeader title="Products" breadcrumb={{ routes }}>
        <div className="wrap">
          <div className="content">
            <WrappedProductForm
              productId={this.props.match.params.productId}
              hotelId={this.props.selectedHotel.id}
              history={this.props.history}
              {...this.props.actions}
            />
          </div>
        </div>
      </PageHeader>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  selectedHotel: state.user.selectedHotel,
  products: state.inventory.products,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      saveProduct,
      addImage,
      updateImageOrder,
      deleteImage,
      saveProductFull,
    },
    dispatch,
  ),
});

class ProductForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ProductType: "",
      ProductName: "",
      IsFeatured: false,
      MaxGuest: 1,
      ProductHighlight: "",
      WhatYouGet: "",
      Service: "",
      RedemptionPeriod: 30,
      IsCheckedInRequired: false,
      IsKidAllow: 1,
      AvailableAddOns: [],
      MetaDescription: "",
      MetaKeyword: "",
      Images: [],
      formDisabled: false,
    };
  }

  async componentDidMount() {
    const { productId } = this.props;
    if (Number(productId)) {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/product/${productId}`);
      const { product } = res.data;
      const url = process.env.REACT_APP_CDN;

      const Images = product.Images.map((img) => {
        const path = img.Url.split('/');
        return (
          {
            ...img,
            Source: `${url}${img.Url}`,
            Name: path[path.length - 1],
          });
      });
      // this.props.form.setFieldsValue({ cover: this.state.Images });

      this.setState({ ...product, Images });
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.hotelId !== this.props.hotelId) {
      await this.props.history.push('/calendar');
    }
  }

  kids = () => [
    { key: 0, value: "Not Allowed" },
    { key: 1, value: "Allowed" },
    { key: 2, value: "Allowed, at full price" },
  ];

  // addOns = () => this.props.products.filter(product => product.ProductType === 4);// hotel's products with id == 4

  renderProductOptions = () => ProductTypes().map(opt => (
    <Option value={opt.value} key={opt.key}>
      {opt.value}
    </Option>
  ));

  handleSubmit = async (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(async (err, fieldsValue) => {
      if (err) {
        return;
      }
      this.setState({ formDisabled: true });

      const product = {
        ...fieldsValue,
        ProductId: this.props.productId,
        HotelId: this.props.hotelId,
      };
      const images = [...product.cover];
      delete product.cover;
      delete product.AvailableAddOns;

      const productId = await this.postProduct(product);
      if (productId) {
        const updatedItems = await this.updateImageOrders(images, this.props.hotelId, productId);
        await this.addingProcces(updatedItems);
        await this.deletingProcces(images, productId);
      }
      await this.props.history.push('/products');
    });
  };

  postProduct = async product => this.props.saveProductFull(product);

  updateImageOrders = async (images, HotelId, ProductId) => {
    const updatedItems = images.filter(image => !image.ToDelete).map((item, index) => ({
      ...item,
      Order: index + 1,
      Url: `/HotelImage/${HotelId}/${index + 1}_${item.Name}`,
      IsCover: index === 0,
      HotelId,
      ProductId,
    }));

    if (updatedItems) {
      const promises = Object.values(updatedItems).filter(item => !item.IsNew).map((item) => {
        if (item.Order === 1) {
          return this.props.updateImageOrder(item.Id, item.Order, ProductId);
        }

        this.props.updateImageOrder(item.Id, item.Order, ProductId);
        return Promise.resolve();
      });

      await Promise.all(promises);
    }

    return updatedItems;
  }

  addingProcces = async (updatedItems) => {
    if (updatedItems) {
      const promises = Object
        .values(updatedItems)
        .filter(item => item.IsNew && !item.ToDelete)
        .map((item) => {
          if (item.Order === 1) {
            return this.props.addImage(item);
          }

          this.props.addImage(item);
          return Promise.resolve();
        });

      await Promise.all(promises);
    }
  }

  deletingProcces = async (images, productId) => {
    for (const item of images) {
      if (item.ToDelete) {
        this.props.deleteImage(item.Id, productId);
      }
    }

    // const images = this.state.Images.filter(image => image.ToDelete === false);

    // this.setState({ Images: images });
  }

  renderProductTab = getFieldDecorator => (
    <>
      <Form.Item label="Product Type">
        {getFieldDecorator('ProductType', {
          rules: [{ required: true, message: 'Please select a type!' }],
          initialValue: this.state.ProductType,
        })(
          <Select name="ProductType">
            {ProductTypes().map(type => (
              <Select.Option key={type.key} value={type.key}>{type.value}</Select.Option>
            ))}
          </Select>,
        )}
      </Form.Item>
      <Form.Item label="Product Name">
        {getFieldDecorator('ProductName', {
          rules: [{ required: true, message: 'Please input a name!', whitespace: true }],
          initialValue: this.state.ProductName,
        })(
          <Input name="ProductName" />,
        )}
      </Form.Item>
      <Form.Item label="Max Guests">
        {getFieldDecorator('MaxGuest', {
          rules: [{ required: true, message: 'Please fill a maximum number of guests, from 1 to 20' }],
          initialValue: this.state.MaxGuest,
        })(
          <InputNumber min='1' max='20' name="MaxGuest" />,
        )}
      </Form.Item>
      {/* For admin purpose! */}
      {/* <Form.Item label="Is featured">
        <Switch name="IsFeatured" checked={this.state.IsFeatured} onChange={e => this.setState({ IsFeatured: e })} />
      </Form.Item> */}
      <Form.Item label="Product Highlight">
        {getFieldDecorator('ProductHighlight', {
          initialValue: this.state.ProductHighlight,
        })(
          <TextArea name="ProductHighlight" rows={4} />,
        )}
      </Form.Item>
      <Form.Item label="What You Get">
        {getFieldDecorator('WhatYouGet', {
          initialValue: this.state.WhatYouGet,
          rules: [
            { required: true, message: 'Please describe what your guests get' },
            { min: 25, message: 'Please enter at least 25 characters' },
          ],
        })(
          <EditableMarkup name='WhatYouGet' />,
        )}
      </Form.Item>
      {/* Looks like it is not used on website, since it is absent in app\models\product-model.js */}
      {/* <Form.Item label="Service">
        <TextArea name="Service" value={this.state.Service} onChange={this.handleChange} rows={4} />
      </Form.Item> */}
    </>
  );

  renderPriceTab = getFieldDecorator => (
    <div>
      <Form.Item label="Redemption Period (In Days)">
        {getFieldDecorator('RedemptionPeriod', {
          rules: [{ required: true, message: 'Please fill a redemption period' }],
          initialValue: this.state.RedemptionPeriod,
        })(
          <InputNumber name="RedemptionPeriod" />,
        )}
      </Form.Item>
      <Form.Item label="Check-In Date Required">
        {getFieldDecorator('IsCheckedInRequired', {
          valuePropName: 'checked',
          initialValue: this.state.IsCheckedInRequired,
        })(
          <Switch name="IsCheckedInRequired" />,
        )}
      </Form.Item>
      <Form.Item label="Kids Allowed?">
        {getFieldDecorator('IsKidAllow', {
          initialValue: this.state.IsKidAllow,
        })(
          <Select name="IsKidAllow">
            {this.kids().map(type => (
              <Select.Option key={type.key} value={type.key}>{type.value}</Select.Option>
            ))}
          </Select>,
        )}
      </Form.Item>
      {/* Decided not to implement it right now since the purpose is not clear. The same for Upgrades field */}
      {/* <Form.Item label="Available Add-Ons:">
        <Select
          name="AvailableAddons"
          mode="multiple"
          value={this.state.AddOns}
          onChange={e => this.setState({ AddOns: e })}
        >
          {this.kids().map(type => (
            <Select.Option key={type.key} value={type.key}>{type.value}</Select.Option>
          ))}
        </Select>
      </Form.Item> */}
    </div>
  );

  renderSeoTab = getFieldDecorator => (
    <div>
      <Form.Item label="Meta Description">
        {getFieldDecorator('MetaDescription', {
          initialValue: this.state.MetaDescription,
        })(
          <TextArea name="MetaDescription" />,
        )}
      </Form.Item>
      <Form.Item label="Meta Keywords">
        {getFieldDecorator('MetaKeyword', {
          initialValue: this.state.MetaKeyword,
        })(
          <TextArea name="MetaKeyword" />,
        )}
      </Form.Item>
    </div>
  );

  renderPhotosTab = getFieldDecorator => (
    <Form.Item label="Cover Photo">
      {getFieldDecorator('cover', {
        rules: [{
          type: 'Array',
          validator: (rule, value) => {
            if (value.filter(image => !image.ToDelete).length > 0) {
              return Promise.resolve();
            }
            return Promise.reject('Please upload a photo!');
          },
        }],
        initialValue: [...this.state.Images],
      })(
        <Dropzone
          name="cover"
          onUploadChange={(newFiles) => { this.setState({ Images: newFiles }); }}
        />,
      )}
    </Form.Item>
  )

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        name="product"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onSubmit={this.handleSubmit}
        disabled={this.state.formDisabled}
      >
        {this.renderPhotosTab(getFieldDecorator)}
        {this.renderProductTab(getFieldDecorator)}
        <Divider orientation='left'>Price</Divider>
        {this.renderPriceTab(getFieldDecorator)}
        <Divider orientation='left'>SEO</Divider>
        {this.renderSeoTab(getFieldDecorator)}
        <div className={styles.saveButton}>
          <Button type="primary" htmlType='submit' disabled={this.state.formDisabled}>
            Submit
          </Button>
        </div>
      </Form>
    );
  }
}

const WrappedProductForm = Form.create({ name: 'product' })(ProductForm);

export default connect(mapStateToProps, mapDispatchToProps)(EditProductPage);
