import {
  REQUEST,
  SUCCESS,
  FAILURE,
  GET_AMENITIES,
  GET_HOTEL_AMENITIES,
  AMENITIES,
  ADD_AMENITIE,
  DELETE_AMENITIE,
  HOTEL_AMENITIE_FETCH,
  HOTEL_AMENITIE_SAVE,
  HOTEL_AMENITIE_ADD,
  HOTEL_AMENITIE_DELETE,
  ACTIVE_AMENITIE,
} from '@/redux/store/action-types';
import axios from 'axios';
import action from '../action';

export const getHotelAmenities = hotelId => async (dispatch) => {
  dispatch(action(GET_HOTEL_AMENITIES[REQUEST]));
  try {
    const request = await axios.get(`${process.env.REACT_APP_API_URL}/user/getHotelAmenities/${hotelId}`);

    const hotelAmenities = request.data.amenities;

    const newHotelAmenities = {
      Id: hotelAmenities.Id,
      BusinessActive: hotelAmenities.BusinessActive,
      BusinessCenterHours: hotelAmenities.BusinessCenterHours,
      DinningActive: hotelAmenities.DinningActive,
      DinningHours: hotelAmenities.DinningHours,
      EventActive: hotelAmenities.EventActive,
      EventHours: hotelAmenities.EventHours,
      GymActive: hotelAmenities.GymActive,
      GymHours: hotelAmenities.GymHours,
      HotelId: hotelAmenities.HotelId,
      IsActive: hotelAmenities.IsActive,
      OtherActive: hotelAmenities.OtherActive,
      OtherHours: hotelAmenities.OtherHours,
      PoolActive: hotelAmenities.PoolActive,
      PoolHours: hotelAmenities.PoolHours,
      SpaActive: hotelAmenities.SpaActive,
      SpaHours: hotelAmenities.SpaHours,
      CheckboxCheck: {
        IsCheckedPool: hotelAmenities.PoolActive || false,
        IsCheckedGym: hotelAmenities.GymActive || false,
        IsCheckedSpa: hotelAmenities.SpaActive || false,
        IsCheckedDining: hotelAmenities.DinningActive || false,
        IsCheckedBusiness: hotelAmenities.BusinessActive || false,
        IsCheckedEvents: hotelAmenities.EventActive || false,
        IsCheckedOther: hotelAmenities.OtherActive || false,
      },
    };

    const result = {
      data: newHotelAmenities,
    };

    localStorage.setItem('hotelAmenities', JSON.stringify(newHotelAmenities));

    dispatch(action(GET_HOTEL_AMENITIES[SUCCESS], {
      hotelAmenities: result.data.newHotelAmenities,
    }));
  } catch (error) {
    dispatch(action(GET_HOTEL_AMENITIES[FAILURE], { error }));
  }
};

export const getNewHotelAmenities = () => async (dispatch) => {
  dispatch(action(GET_HOTEL_AMENITIES[REQUEST]));
  try {
    const newHotelAmenities = {
      Id: 0,
      CheckboxCheck: {
        IsCheckedPool: false,
        IsCheckedGym: false,
        IsCheckedSpa: false,
        IsCheckedDining: false,
        IsCheckedBusiness: false,
        IsCheckedEvents: false,
        IsCheckedOther: false,
      },
    };

    const result = {
      data: newHotelAmenities,
    };

    localStorage.setItem('hotelAmenities', JSON.stringify(newHotelAmenities));

    dispatch(action(GET_HOTEL_AMENITIES[SUCCESS], {
      hotelAmenities: result.data.newHotelAmenities,
    }));
  } catch (error) {
    dispatch(action(GET_HOTEL_AMENITIES[FAILURE], { error }));
  }
};

export const getAmenities = amenityId => async (dispatch) => {
  dispatch(action(GET_AMENITIES[REQUEST]));
  try {
    const amenities = await axios.get(`${process.env.REACT_APP_API_URL}/user/getAmenities/${amenityId}`);

    const newAmenities = amenities.data.amenities.map(item => (
      { ...item, IsNew: false, ToDelete: false }
    ));

    const result = {
      data: newAmenities,
    };

    localStorage.setItem('amenities', JSON.stringify(newAmenities));
    dispatch(action(GET_AMENITIES[SUCCESS], {
      amenities: result.data.newAmenities,
    }));
  } catch (error) {
    dispatch(action(GET_AMENITIES[FAILURE], { error }));
  }
};

export const getNewAmenities = () => async (dispatch) => {
  dispatch(action(GET_AMENITIES[REQUEST]));
  try {
    localStorage.setItem('amenities', JSON.stringify(null));
    dispatch(action(GET_AMENITIES[SUCCESS], {
      amenities: null,
    }));
  } catch (error) {
    dispatch(action(GET_AMENITIES[FAILURE], { error }));
  }
};

export const changeAmenitie = amenitie => async (dispatch) => {
  dispatch(action(AMENITIES[REQUEST]));
  try {
    const amenities = await axios.get(`${process.env.REACT_APP_API_URL}/user/getAmenities/${amenitie.AmentyId}`);

    const selectedAmenitie = {
      ...amenitie,
      amenities: amenities.data.amenities,
    };

    localStorage.setItem('selectedAmenitie', JSON.stringify(selectedAmenitie));

    dispatch(action(AMENITIES[SUCCESS], {
      selectedAmenitie,
    }));
  } catch (error) {
    dispatch(action(AMENITIES[FAILURE], { error }));
  }
};

export const addAmenitieNaive = amenitie => async (dispatch) => {
  dispatch(action(ADD_AMENITIE[REQUEST]));
  try {
    let amenities = JSON.parse(localStorage.getItem('amenities'));

    let maxElem = 0;

    if (amenities !== null && amenities.length > 0) {
      maxElem = amenities.reduce((acc, curr) => (acc.Id > curr.Id ? acc : curr)).Id;
    } else {
      amenities = [];
    }

    const addAmenitie = {
      Id: maxElem + 1,
      AmentyTypeId: amenitie.AmentyTypeId,
      Name: amenitie.Name,
      AmentyOrder: amenitie.AmentyOrder,
      IsActive: amenitie.IsActive,
      IsAmenty: amenitie.IsAmenty,
      AmentyId: amenitie.AmentyId,
      IsNew: true,
      ToDelete: false,
    };

    amenities.push(addAmenitie);

    const result = {
      data: amenities,
    };

    localStorage.setItem('amenities', JSON.stringify(amenities));
    dispatch(action(ADD_AMENITIE[SUCCESS]), {
      amenities: result.data.amenities,
    });
  } catch (error) {
    dispatch(action(ADD_AMENITIE[FAILURE], { error }));
  }
};

export const deleteAmenitieNaive = amenitie => async (dispatch) => {
  dispatch(action(DELETE_AMENITIE[REQUEST]));
  try {
    const amenities = JSON.parse(localStorage.getItem('amenities'));

    const newAmenities = amenities.map((item) => {
      if (item.Id === amenitie.Id) {
        const result = { ...item, ToDelete: true };
        return result;
      }
      return item;
    });

    const result = {
      data: newAmenities,
    };

    localStorage.setItem('amenities', JSON.stringify(newAmenities));
    dispatch(action(DELETE_AMENITIE[SUCCESS]), {
      amenities: result.data.newAmenities,
    });
  } catch (error) {
    dispatch(action(DELETE_AMENITIE[FAILURE], { error }));
  }
};

export const addAmenitie = amenitie => async (dispatch) => {
  dispatch(action(ADD_AMENITIE[REQUEST]));
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/user/addAmenitie`, { amenitie });
    dispatch(action(ADD_AMENITIE[SUCCESS]));
  } catch (error) {
    dispatch(action(ADD_AMENITIE[FAILURE], { error }));
  }
};

export const deleteAmenitie = amenitie => async (dispatch) => {
  dispatch(action(DELETE_AMENITIE[REQUEST]));
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}/user/deleteAmenitie/${amenitie.Id}`);
    dispatch(action(DELETE_AMENITIE[SUCCESS]));
  } catch (error) {
    dispatch(action(DELETE_AMENITIE[FAILURE], { error }));
  }
};

export const changeActiveAmenitie = amenitie => async (dispatch) => {
  dispatch(action(ACTIVE_AMENITIE[REQUEST]));
  try {
    const activeValue = amenitie.IsActive;
    await axios.post(`${process.env.REACT_APP_API_URL}/user/changeActiveAmenitie/${amenitie.Id}`, { activeValue });
    dispatch(action(ACTIVE_AMENITIE[SUCCESS]));
  } catch (error) {
    dispatch(action(ACTIVE_AMENITIE[FAILURE], { error }));
  }
};

export const deleteHotelAmenitie = hotelAmenitie => async (dispatch) => {
  dispatch(action(HOTEL_AMENITIE_DELETE[REQUEST]));
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}/user/deleteHotelAmenitie/${hotelAmenitie.Id}`);
    dispatch(action(HOTEL_AMENITIE_DELETE[SUCCESS]));
  } catch (error) {
    dispatch(action(HOTEL_AMENITIE_DELETE[FAILURE], { error }));
  }
};

export const fetchHotelAmenitie = hotelId => async (dispatch) => {
  dispatch(action(HOTEL_AMENITIE_FETCH[REQUEST]));
  try {
    const request = await axios.get(`${process.env.REACT_APP_API_URL}/user/getHotelAmenities/${hotelId}`);

    const hotelAmenities = request.data.amenities;

    const newHotelAmenities = {
      Id: hotelAmenities.Id,
      BusinessActive: hotelAmenities.BusinessActive,
      BusinessCenterHours: hotelAmenities.BusinessCenterHours,
      DinningActive: hotelAmenities.DinningActive,
      DinningHours: hotelAmenities.DinningHours,
      EventActive: hotelAmenities.EventActive,
      EventHours: hotelAmenities.EventHours,
      GymActive: hotelAmenities.GymActive,
      GymHours: hotelAmenities.GymHours,
      HotelId: hotelAmenities.HotelId,
      IsActive: hotelAmenities.IsActive,
      OtherActive: hotelAmenities.OtherActive,
      OtherHours: hotelAmenities.OtherHours,
      PoolActive: hotelAmenities.PoolActive,
      PoolHours: hotelAmenities.PoolHours,
      SpaActive: hotelAmenities.SpaActive,
      SpaHours: hotelAmenities.SpaHours,
      CheckboxCheck: {
        IsCheckedPool: hotelAmenities.PoolActive,
        IsCheckedGym: hotelAmenities.GymActive,
        IsCheckedSpa: hotelAmenities.SpaActive,
        IsCheckedDining: hotelAmenities.DinningActive,
        IsCheckedBusiness: hotelAmenities.BusinessActive,
        IsCheckedEvents: hotelAmenities.EventActive,
        IsCheckedOther: hotelAmenities.OtherActive,
      },
    };

    const result = {
      data: newHotelAmenities,
    };

    localStorage.setItem('hotelAmenities', JSON.stringify(newHotelAmenities));
    dispatch(action(HOTEL_AMENITIE_FETCH[SUCCESS], {
      hotelAmenities: result.data.newHotelAmenities,
    }));
  } catch (error) {
    dispatch(action(HOTEL_AMENITIE_FETCH[FAILURE], { error }));
  }
};

export const fetchNewHotelAmenitie = () => async (dispatch) => {
  dispatch(action(HOTEL_AMENITIE_FETCH[REQUEST]));
  try {
    const newHotelAmenities = {
      Id: 0,
      CheckboxCheck: {
        IsCheckedPool: false,
        IsCheckedGym: false,
        IsCheckedSpa: false,
        IsCheckedDining: false,
        IsCheckedBusiness: false,
        IsCheckedEvents: false,
        IsCheckedOther: false,
      },
    };

    const result = {
      data: newHotelAmenities,
    };

    localStorage.setItem('hotelAmenities', JSON.stringify(newHotelAmenities));
    dispatch(action(HOTEL_AMENITIE_FETCH[SUCCESS], {
      hotelAmenities: result.data.newHotelAmenities,
    }));
  } catch (error) {
    dispatch(action(HOTEL_AMENITIE_FETCH[FAILURE], { error }));
  }
};

export const saveHotelAmenitie = hotelAmenitie => async (dispatch) => {
  dispatch(action(HOTEL_AMENITIE_SAVE[REQUEST]));
  try {
    const hotelAmenitieId = await axios.post(`${process.env.REACT_APP_API_URL}/user/saveHotelAmenitie/${hotelAmenitie.Id}`, { hotelAmenitie });

    this.getHotelAmenities(hotelAmenitieId);

    dispatch(action(HOTEL_AMENITIE_SAVE[SUCCESS]));
  } catch (error) {
    dispatch(action(HOTEL_AMENITIE_SAVE[FAILURE], { error }));
  }
};

export const addHotelAmenitie = hotelAmenitie => async (dispatch) => {
  dispatch(action(HOTEL_AMENITIE_ADD[REQUEST]));
  let result = 0;

  try {
    const hotelAmenitieId = await axios.post(`${process.env.REACT_APP_API_URL}/user/addHotelAmenitie`, { hotelAmenitie });

    result = hotelAmenitieId.data.hotelAmenitieId;

    dispatch(action(HOTEL_AMENITIE_ADD[SUCCESS]));
  } catch (error) {
    dispatch(action(HOTEL_AMENITIE_ADD[FAILURE], { error }));
  }

  return result;
};
