/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable react/no-unused-state */
import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchBookings, redeemBooking, downloadExcel } from '@/redux/actions';
import moment from 'moment';
import {
  Table,
  Button,
  Input,
  DatePicker,
  Icon,
  Modal,
  Descriptions,
} from 'antd';
import debounce from 'lodash.debounce';
import './booking-page.css';

const { RangePicker } = DatePicker;

class BookingsPage extends React.Component {
  state = {
    modalVisible: false,
    content: '',
    page: 1,
  };

 columns = [
   {
     title: 'Passholder Name',
     dataIndex: 'user',
     key: 'user',
     render: (val, record) => (
       <span>
         {record.FirstName}
         {' '}
         {record.LastName}
       </span>
     ),
   },
   {
     title: 'Product Name',
     dataIndex: 'ProductName',
     key: 'ProductName',
   },
   {
     title: 'Booking Code',
     dataIndex: 'BookingCode',
     key: 'bookingCode',
   },
   {
     title: 'Check-In Date',
     dataIndex: 'CheckinDate',
     key: 'CheckinDate',
     render: date => (
       <span>
         {moment.utc(date).format('MM/DD/YYYY')}
       </span>
     ),
   },
   {
     title: 'Tickets',
     dataIndex: 'Quantity',
     key: 'Quantity',
   },
   {
     title: 'User Rating',
     dataIndex: 'HotelId',
     key: 'HotelId',
   },
   {
     title: 'Pass Status',
     dataIndex: 'PassStatus',
     key: 'PassStatus',
     render: (status) => {
       const statuses = [
         'Active',
         'Expired',
         'Redeemed',
         'Refunded',
         'Capture',
       ];

       return (
         <span>
           {statuses[status]}
         </span>
       );
     },
   },
   {
     title: 'Action',
     key: 'action',
     width: 10,
     render: (val, record) => {
       if (record.PassStatus === 0 && this.props.user.user.isSuperAdmin) {
         return (
           <Button type="primary" onClick={() => this.handleBookingRedeem(record)}>Redeem</Button>

         );
       }
       return (
         <Button type="primary" disabled>Redeem</Button>
       );
     },
   },
 ];

  state = {
    pagination: {},
    searchParam: '',
  };

  debouncedChanges = debounce((newChanges) => {
    this.setState({ searchParam: newChanges }, () => {
      this.fetchBookings();
    });
  }, 300);

  async componentDidMount() {
    this.fetchBookings();
  }

  handleDownload = async () => {
    const hotelId = this.props.user.selectedHotel.id;
    const { searchParam, beginDate, endDate } = this.state;
    await this.props.actions.downloadExcel(hotelId, searchParam, beginDate, endDate);
  };

  modalContent = booking => (
    <React.Fragment>
      <Descriptions
        border
        column={{
          xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1,
        }}
      >
        <Descriptions.Item label="Name">{`${booking.FirstName} ${booking.LastName}`}</Descriptions.Item>
        <Descriptions.Item label="Check-In Date">{moment.utc(booking.CheckinDate).format('MM/DD/YYYY')}</Descriptions.Item>
        <Descriptions.Item label="Tickets">{booking.Quantity}</Descriptions.Item>
        <Descriptions.Item label="Each Ticket">{`${booking.MaxGuest} guest(s)`}</Descriptions.Item>
        <Descriptions.Item label="Booking Code">{booking.BookingCode}</Descriptions.Item>
      </Descriptions>
    </React.Fragment>
  )

  handleBookingRedeem = async (booking) => {
    const content = this.modalContent(booking);
    this.setState({ content, modalVisible: true, contentId: booking.BookingId });
  };

  modalOk = async () => {
    const { contentId } = this.state;
    await this.props.actions.redeemBooking(contentId);
    await this.fetchBookings(this.state.page);
    this.setState({ modalVisible: false });
  }

  modalCancel = () => {
    this.setState({ modalVisible: false });
  }

  handleTableChange = async (pagination) => {
    this.setState({ page: pagination.current });
    this.fetchBookings(pagination.current);
  };

  handleDatePickerChange = async (dates) => {
    if (dates.length > 1) {
      await this.setState({
        beginDate: dates[0].format('YYYY-MM-DD'),
        endDate: dates[1].format('YYYY-MM-DD'),
        page: 1,
      }, async () => this.fetchBookings(1));
    }
  }

  handleDatePickerClear = async (dates) => {
    if (dates && dates.length === 0) {
      await this.setState({
        beginDate: null,
        endDate: null,
        page: 1,
      }, async () => this.fetchBookings(1));
    }
  }

  handleSearch = async (e) => {
    this.debouncedChanges(e.target.value);
  }

  async fetchBookings(pagination = 1) {
    const hotelId = this.props.user.selectedHotel.id;
    const { searchParam, beginDate, endDate } = this.state;

    await this.props.actions.fetchBookings(hotelId, pagination, searchParam, beginDate, endDate);
  }

  render() {
    const { bookings, total } = this.props.bookings;
    const { page } = this.state;
    const pagination = {
      total,
      pageSize: 10,
      current: page,
    };
    const dateFormat = 'MM/DD/YYYY';
    const { content } = this.state;
    return (
      <React.Fragment>
        <Modal
          title="Ticket Validation"
          centered
          visible={this.state.modalVisible}
          onOk={() => this.modalOk()}
          onCancel={() => this.modalCancel()}
          okText="Redeem"
          cancelText="Cancel"
        >
          {content}
        </Modal>
        <div className="search-props">
          <Input
            prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Search guest name or booking code"
            allowClear
            onChange={value => this.handleSearch(value)}
          />
          <RangePicker
            defaultValue={[null, null]}
            format={dateFormat}
            onCalendarChange={dates => this.handleDatePickerChange(dates)}
            onChange={this.handleDatePickerClear}
          />
          <Button type="primary" icon="file-excel" onClick={this.handleDownload}>Download Excel</Button>
        </div>

        <Table
          columns={this.columns}
          rowKey="BookingId"
          dataSource={bookings}
          onChange={this.handleTableChange}
          pagination={pagination}
          size="small"
          loading={this.props.bookings.isBookingsFetching}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  bookings: state.bookings,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    fetchBookings,
    redeemBooking,
    downloadExcel,
  },
  dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingsPage);
