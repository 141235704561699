/* eslint-disable react/prefer-stateless-function */

import React from 'react';
import './bookings.css';
import { Table } from 'antd';
import dateFns from 'date-fns';

const columns = [
  {
    title: 'ID',
    dataIndex: 'BookingId',
    key: 'BookingId',
  },
  {
    title: 'Code',
    dataIndex: 'BookingCode',
    key: 'BookingCode',
  },
  {
    title: 'Check-in Date',
    dataIndex: 'CheckinDate',
    key: 'CheckinDate',
    render: date => (
      <span>
        {dateFns.format(date, 'MM/DD/YYYY')}
      </span>
    ),
  },
  {
    title: 'Quantity',
    dataIndex: 'Quantity',
    key: 'Quantity',
  },
  {
    title: 'Status',
    dataIndex: 'PassStatus',
    key: 'PassStatus',
    render: (status) => {
      const statuses = [
        'Active',
        'Expired',
        'Redeemed',
        'Refunded',
        'Capture',
      ];

      return (
        <span>
          {statuses[status]}
        </span>
      );
    },
  },
];
export default class BookingsCalendar extends React.Component {
  render() {
    const data = this.props.bookings;
    return (
      <div className="calendar-table">
        <Table columns={columns} dataSource={data} rowKey="BookingId" />
      </div>
    );
  }
}
