import {
  REQUEST,
  SUCCESS,
  FAILURE,
  GET_AMENITIES,
  GET_HOTEL_AMENITIES,
  AMENITIES,
  ADD_AMENITIE,
  DELETE_AMENITIE,
  HOTEL_AMENITIE_FETCH,
  HOTEL_AMENITIE_SAVE,
  HOTEL_AMENITIE_ADD,
  ACTIVE_AMENITIE,
} from '@/redux/store/action-types';

const initialState = {
  hotelAmenities: JSON.parse(localStorage.getItem('hotelAmenities')) || null,
  amenities: JSON.parse(localStorage.getItem('amenities')) || null,
  selectedAmenitie: JSON.parse(localStorage.getItem('selectedAmenitie')) || null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case HOTEL_AMENITIE_SAVE[REQUEST]:
      return {
        ...state,
        error: null,
      };
    case HOTEL_AMENITIE_SAVE[SUCCESS]:
      return {
        ...state,
        ...action.payload,
        hotelAmenities: JSON.parse(localStorage.getItem('hotelAmenities')) || null,
      };
    case HOTEL_AMENITIE_SAVE[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case HOTEL_AMENITIE_FETCH[REQUEST]:
      return {
        ...state,
        error: null,
      };
    case HOTEL_AMENITIE_FETCH[SUCCESS]:
      return {
        ...state,
        ...action.payload,
        hotelAmenities: JSON.parse(localStorage.getItem('hotelAmenities')) || null,
      };
    case HOTEL_AMENITIE_FETCH[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIVE_AMENITIE[REQUEST]:
      return {
        ...state,
        error: null,
      };
    case ACTIVE_AMENITIE[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIVE_AMENITIE[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case AMENITIES[REQUEST]:
      return {
        ...state,
        error: null,
      };
    case AMENITIES[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case AMENITIES[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case GET_AMENITIES[REQUEST]:
      return {
        ...state,
      };
    case GET_AMENITIES[SUCCESS]:
      return {
        ...state,
        ...action.payload,
        amenities: JSON.parse(localStorage.getItem('amenities')) || null,
      };
    case GET_AMENITIES[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case GET_HOTEL_AMENITIES[REQUEST]:
      return {
        ...state,
      };
    case GET_HOTEL_AMENITIES[SUCCESS]:
      return {
        ...state,
        ...action.payload,
        hotelAmenities: JSON.parse(localStorage.getItem('hotelAmenities')) || null,
      };
    case GET_HOTEL_AMENITIES[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case ADD_AMENITIE[REQUEST]:
      return {
        ...state,
      };
    case ADD_AMENITIE[SUCCESS]:
      return {
        ...state,
        ...action.payload,
        amenities: JSON.parse(localStorage.getItem('amenities')) || null,
      };
    case ADD_AMENITIE[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case DELETE_AMENITIE[REQUEST]:
      return {
        ...state,
      };
    case DELETE_AMENITIE[SUCCESS]:
      return {
        ...state,
        ...action.payload,
        amenities: JSON.parse(localStorage.getItem('amenities')) || null,
      };
    case DELETE_AMENITIE[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case HOTEL_AMENITIE_ADD[REQUEST]:
      return {
        ...state,
        error: null,
      };
    case HOTEL_AMENITIE_ADD[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case HOTEL_AMENITIE_ADD[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
