import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './styles.css';

export default class EditableMarkup extends React.Component {
  constructor(props) {
    super(props);
    this.modules = {
      toolbar: [
        [{ header: 1 }, { header: 2 }, { size: ['small', false, 'large'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [
          { color: [] },
          { background: [] },
          { script: 'sub' },
          { script: 'super' },
        ],
        [
          { align: [] },
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
        ],
        ['link', 'image', 'blockquote', 'code-block'],
        ['clean'],
      ],
    };
    this.formats = [
      'header',
      'header',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'color',
      'background',
      'script',
      'script',
      'align',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'blockquote',
      'code-block',
      'width',
    ];
  }

  render() {
    return (
      <div className={styles.markupContainer}>
        {
          <ReactQuill
            readOnly={false}
            theme="snow"
            value={this.props.value}
            onChange={this.props.onChange}
            modules={this.modules}
            formats={this.formats}
          />
        }
      </div>
    );
  }
}
