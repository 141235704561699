/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchDetails, saveDetails } from '@/redux/actions';
import debounce from 'lodash.debounce';

import {
  PageHeader, InputNumber, Button,
} from 'antd';

const routes = [
  {
    path: 'dashboard',
    breadcrumbName: 'Dashboard',
  },
  {
    path: 'details',
    breadcrumbName: 'Details',
  },
];

class DetailsPage extends React.Component {
  debouncedChanges = debounce((newChanges) => {
    this.setState({ changedDetails: newChanges });
  }, 300);


  async componentDidMount() {
    await this.fetchDetails(this.props.selectedHotel.id);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedHotel.id !== this.props.selectedHotel.id) {
      await this.fetchDetails(this.props.selectedHotel.id);
    }
  }

  fetchDetails = async (hotelId) => {
    await this.props.actions.fetchDetails(hotelId);
    this.setState({
      changedDetails: this.props.details.details,
    });
  }


  changeDetail = (value, fieldName) => {
    const newChanges = this.state.changedDetails;
    newChanges[fieldName] = value;
    this.debouncedChanges(newChanges);
  }

  saveDetails = async () => {
    await this.props.actions.saveDetails(this.state.changedDetails);
  }

  render() {
    if (!this.props.details.details.length === 0) return '';
    return (
      <PageHeader title="Details" breadcrumb={{ routes }}>
        <div className="wrap">
          <div className="content">
            <table>
              <tbody>
                <tr>
                  <td style={{ textAlign: 'left', paddingRight: '25px' }}>Resort Tax [flat]:</td>
                  <td><InputNumber min={0} step={0.05} value={this.props.details.details.ResortTax} onChange={e => this.changeDetail(e, 'ResortTax')} /></td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left', paddingRight: '25px' }}>State Tax %:</td>
                  <td><InputNumber min={0} step={0.05} value={this.props.details.details.StateTax} onChange={e => this.changeDetail(e, 'StateTax')} /></td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left', paddingRight: '25px' }}>City Tax %:</td>
                  <td><InputNumber min={0} step={0.05} value={this.props.details.details.CityTax} onChange={e => this.changeDetail(e, 'CityTax')} /></td>
                </tr>
              </tbody>
            </table>
            <div>
              <Button type="primary" style={{ marginTop: '15px' }} onClick={() => this.saveDetails()}>Save</Button>
            </div>
          </div>
        </div>
      </PageHeader>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  selectedHotel: state.user.selectedHotel,
  details: state.details,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    fetchDetails,
    saveDetails,
  },
  dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailsPage);
