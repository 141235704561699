/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import LayoutHotel from './components/LayoutHotel';
import HomePage from './pages/HomePage';

import 'antd/dist/antd.css';

const SuperAdminRoute = ({ component: Component, user, ...rest }) => {
  const isSuperAdmin = user ? user.isSuperAdmin : false;
  return (
    <Route
      {...rest}
      render={matchProps => (
        isSuperAdmin ? (
          <LayoutHotel {...matchProps}>
            <Component {...matchProps} />
          </LayoutHotel>
        ) : (
          <LayoutHotel {...matchProps}>
            <HomePage />
          </LayoutHotel>
        )
      )}
    />
  );
};

const mapStateToProps = state => ({
  user: state.user.user,
});

const connectedApp = connect(mapStateToProps)(SuperAdminRoute);
export default connectedApp;
