/* eslint-disable react/no-unused-state */
/* eslint-disable no-confusing-arrow */
/* eslint-disable quotes */
/* eslint-disable jsx-quotes */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchProductsFull, saveProduct, deleteProduct } from '@/redux/actions';
import {
  Table, PageHeader, Icon, Checkbox, Button, Modal,
} from 'antd';

import styles from './styles.module.css';
import { getProductType } from '../../utils/helper';

const { confirm } = Modal;
const routes = [
  {
    path: 'dashboard',
    breadcrumbName: 'Dashboard',
  },
  {
    path: 'products',
    breadcrumbName: 'Products',
  },
];

class ProductsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
    };
  }

  async componentDidMount() {
    await this.fetchProducts(this.props.selectedHotel.id);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedHotel.id !== this.props.selectedHotel.id) {
      await this.fetchProducts(this.props.selectedHotel.id);
    }
  }

  fetchProducts = async (hotelId) => {
    await this.props.actions.fetchProductsFull(hotelId, false);
    const formattedProducts = this.props.products.map(product => ({
      id: product.id,
      thumbnail: '',
      productName: product.productName,
      productType: getProductType(product.productType),
      price: Math.min(
        product.priceMon,
        product.priceTue,
        product.priceWed,
        product.priceThu,
        product.priceFri,
        product.priceSat,
        product.priceSun,
      ),
      discount: Math.min(
        product.discountMon,
        product.discountTue,
        product.discountWed,
        product.discountThu,
        product.discountFri,
        product.discountSat,
        product.discountSun,
      ),
      isActive: product.isActive,
      coverUrl: product.coverUrl,
    }));
    this.setState({ products: [...formattedProducts] });
  }

  columns = () => [
    {
      title: 'Thumbnail',
      dataIndex: 'coverUrl',
      render: (text, row) => (
        <img
          src={`${process.env.REACT_APP_CDN}${row.coverUrl}`}
          alt={row.productName}
          style={{ maxWidth: "310px", maxHeight: "210px" }}
        />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'productName',
    },
    {
      title: 'Type',
      dataIndex: 'productType',
    },
    {
      title: 'Regular price',
      dataIndex: 'price',
      className: 'column-money',
    },
    {
      title: 'Upgrade discount',
      dataIndex: 'discount',
      className: 'column-money',
    },
    {
      title: 'Active',
      className: 'column-money',
      dataIndex: 'isActive',
      render: text => (<Checkbox checked={text} />),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (text, row) => (
        <>
          <Icon onClick={async () => { await this.editProduct(row.id); }} type="edit" style={{ fontSize: '18px' }} />
          <Icon onClick={async () => { await this.showDeleteConfirm(this, row.id); }} type="delete" style={{ fontSize: '18px' }} />
        </>
      ),
    },
  ];

  showDeleteConfirm = (thisInstance, id) => {
    confirm({
      title: 'Are you sure?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        thisInstance.deleteProduct(id);
      },
      onCancel() {},
    });
  }

  editProduct = async (id) => {
    await this.props.history.push(`/product/${id}`);
  }

  deleteProduct = async (id) => {
    await this.props.actions.deleteProduct(id);
    this.fetchProducts(this.props.selectedHotel.id);
  }

  render() {
    return (
      <PageHeader title="Products" breadcrumb={{ routes }}>
        <div className="wrap">
          <div className="content">
            <div className={styles.saveButton}>
              <Button type="primary" onClick={() => this.props.history.push(`/product/new`)}>
                Add new
              </Button>
            </div>
            <Table
              rowKey={product => product.id}
              columns={this.columns()}
              dataSource={this.state.products}
              bordered
            />
          </div>
        </div>
      </PageHeader>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  selectedHotel: state.user.selectedHotel,
  products: state.products.products,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchProductsFull,
      saveProduct,
      deleteProduct,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage);
