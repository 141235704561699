/* eslint-disable max-len */
import {
  REQUEST,
  SUCCESS,
  FAILURE,
  BOOKINGS_FETCH,
  BOOKINGS_REDEEM,
} from '@/redux/store/action-types';
import action from '../action';
import axios from 'axios';
import Notification from '@/components/Notification';
import { downloadExcelInNewTab } from '../../../utils/helper';

export const fetchBookings = (hotelId, page = 1, search = '', beginDate = null, endDate = null) => async (dispatch) => {
  dispatch(action(BOOKINGS_FETCH[REQUEST]));
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/bookings/${hotelId}`, {
      params: {
        page, search, beginDate, endDate,
      },
    });
    dispatch(action(BOOKINGS_FETCH[SUCCESS], {
      ...res.data.bookings,
    }));
  } catch (error) {
    dispatch(action(BOOKINGS_FETCH[FAILURE], { error }));
  }
};

export const redeemBooking = bookingId => async (dispatch) => {
  dispatch(action(BOOKINGS_REDEEM[REQUEST]));
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/bookings/redeem`, { bookingId });
    Notification.openNotification({
      message: 'Bookings is redeemed.',
      description: 'Your changes will take immediate effect.',
      type: 'success',
    });
    dispatch(action(BOOKINGS_REDEEM[SUCCESS]));
  } catch (error) {
    Notification.openNotification({
      message: 'Something happened',
      description: 'We are working on fixing problems',
      type: 'error',
    });
    dispatch(action(BOOKINGS_REDEEM[FAILURE], { error }));
  }
};


export const downloadExcel = (hotelId, searchParam, beginDate, endDate) => async (dispatch) => {
  dispatch(action(BOOKINGS_REDEEM[REQUEST]));
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/bookings/excel?hotelId=${hotelId}&search=${searchParam}&beginDate=${beginDate}&endDate=${endDate}`, { responseType: 'blob' });

    downloadExcelInNewTab(response);

    dispatch(action(BOOKINGS_REDEEM[SUCCESS]));
  } catch (error) {
    Notification.openNotification({
      message: 'Something happened',
      description: 'We are working on fixing problems',
      type: 'error',
    });
    dispatch(action(BOOKINGS_REDEEM[FAILURE], { error }));
  }
};
