/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchNewHotelAmenitie,
  addHotelAmenitie,
  addHotel,
  addHotelMarket,
  getNewHotel,
  getNewHotelAmenities,
  getNewHotelPolicies,
  getNewAmenities,
} from '@/redux/actions';
import './hotel-page.css';
import AddEditHotelForm from './AddEditHotelForm';

class AddHotelPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      hotelPolicies: this.props.hotelPolicies,
      hotelAmenities: this.props.hotelAmenities,
      amenities: this.props.amenities,
      changedHotel: this.props.hotel,
      uploadedFiles: this.props.uploadedFiles || [],
      selectedMarket: this.props.selectedMarket,
    };
  }

  async componentDidMount() {
    await this.props.actions.fetchNewHotelAmenitie();
    await this.props.actions.getNewHotel();
    await this.props.actions.getNewHotelAmenities();
    await this.props.actions.getNewHotelPolicies();
    await this.props.actions.getNewAmenities();
  }

  render() {
    return (
      <div className="wrap">
        <div className="content">
          <div className="flex-container page">
            <AddEditHotelForm
              saveHotel={this.props.actions.addHotel}
              processHotelMarket={this.props.actions.addHotelMarket}
              processHotelAmenitie={this.props.actions.addHotelAmenitie}
              {...this.props}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  selectedHotel: state.user.selectedHotel,
  hotel: state.user.hotel,
  policies: state.policy.policies,
  selectedPolicy: state.policy.selectedPolicy,
  hotelPolicies: state.policy.hotelPolicies,
  hotelAmenities: state.amenities.hotelAmenities,
  amenities: state.amenities.amenities,
  selectedMarket: state.user.selectedMarket,
});


const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    fetchNewHotelAmenitie,
    addHotel,
    addHotelAmenitie,
    addHotelMarket,
    getNewHotel,
    getNewHotelAmenities,
    getNewHotelPolicies,
    getNewAmenities,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddHotelPage);
