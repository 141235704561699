import {
  REQUEST,
  SUCCESS,
  FAILURE,
  HOTEL_MARKETS,
  EDIT_HOTEL_ADD,
  EDIT_HOTEL_SAVE,
  HOTEL_MARKET_DELETE,
} from '@/redux/store/action-types';
import axios from 'axios';
import action from '../action';

export const changeMarket = market => async (dispatch) => {
  dispatch(action(HOTEL_MARKETS[REQUEST]));
  try {
    localStorage.setItem('selectedMarket', JSON.stringify(market));

    const res = {
      data: market,
    };

    dispatch(action(HOTEL_MARKETS[SUCCESS], {
      selectedMarket: res.data.market,
    }));
  } catch (error) {
    dispatch(action(HOTEL_MARKETS[FAILURE], { error }));
  }
};

export const addHotelMarket = hotelMarket => async (dispatch) => {
  dispatch(action(EDIT_HOTEL_ADD[REQUEST]));
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/user/addHotelMarket`, { hotelMarket });
    dispatch(action(EDIT_HOTEL_ADD[SUCCESS]));
  } catch (error) {
    dispatch(action(EDIT_HOTEL_ADD[FAILURE], { error }));
  }
};

export const deleteHotelMarket = hotelMarket => async (dispatch) => {
  dispatch(action(HOTEL_MARKET_DELETE[REQUEST]));
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}/user/deleteHotelMarket/${hotelMarket.Id}`);
    dispatch(action(HOTEL_MARKET_DELETE[SUCCESS]));
  } catch (error) {
    dispatch(action(HOTEL_MARKET_DELETE[FAILURE], { error }));
  }
};

export const saveHotelMarket = hotelMarket => async (dispatch) => {
  dispatch(action(EDIT_HOTEL_SAVE[REQUEST]));
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/user/saveHotelMarket/${hotelMarket.Id}`, { hotelMarket });
    dispatch(action(EDIT_HOTEL_SAVE[SUCCESS]));
  } catch (error) {
    dispatch(action(EDIT_HOTEL_SAVE[FAILURE], { error }));
  }
};
