/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Layout,
  Button,
  Form,
  Icon,
  Input,
  Typography,
} from 'antd';
import {
  restoreUser,
} from '@/redux/actions';

import styles from './styles.module.css';

const { Title } = Typography;

const {
  Header, Footer, Sider, Content,
} = Layout;


class LoginPage extends React.Component {
  componentDidMount() {
    document.title = 'Dayaxe Portal - Forgot Password';
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const { email } = this.state;

    if (email) {
      const result = await this.props.actions.restoreUser(email);

      if (result) {
        this.props.history.push('/');
      }
    }
  }

  render() {
    return (
      <Layout className="layout" style={{ height: '100vh' }}>
        <Header>
          <div className="logo" style={{ margin: 0 }}>
            <img src="img/logo.svg" alt="logo" />
          </div>
        </Header>
        <Content style={{ padding: '50px 50px', height: '100%' }}>
          <div className={styles.loginContent}>

            <Form onSubmit={this.handleSubmit} style={{ maxWidth: '300px' }}>
              <div className={styles.loginTitle}>
                <Title level={3}>Restore Pasword</Title>
              </div>
              <Form.Item>
                <Input
                  prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Email address"
                  onChange={this.handleChange}
                  name="email"
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ width: '100%' }} className="login-form-button">
                  Send link
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          <Button type="link" href="https://dayaxe.zendesk.com/hc/en-us/requests/new">Contact Us</Button>
          <Button type="link" href="https://www.hotels.dayaxe.com/how-it-works">How it works</Button>
          <Button type="link" href="https://www.dayaxe.com/terms">Terms & services</Button>
        </Footer>
      </Layout>

    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    restoreUser,
  },
  dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
