import {
  REQUEST,
  SUCCESS,
  FAILURE,
  BOOKING_FETCH,
  BOOKING_RECEIPT,
  BOOKING_REFUND,
  BOOKING_CHECKIN_DATE,
} from '@/redux/store/action-types';
import axios from 'axios';
import action from '../action';
import moment from 'moment';
import Notification from '@/components/Notification';

export const fetchBooking = bookingId => async (dispatch) => {
  dispatch(action(BOOKING_FETCH[REQUEST]));
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/booking/${bookingId}`);

    const bookingDate = moment.utc(res.data.booking.CheckinDate).format('YYYY-MM-DD');
    const calendar = await axios.get(`${process.env.REACT_APP_API_URL}/calendar-product`, {
      params: {
        date: bookingDate,
        productId: res.data.booking.ProductId,
      },
    });

    dispatch(action(BOOKING_FETCH[SUCCESS], {
      common: res.data.booking,
      customer: res.data.customer,
      payments: res.data.payments,
      discounts: res.data.discounts,
      checkinCalendar: calendar.data,
    }));
  } catch (error) {
    dispatch(action(BOOKING_FETCH[FAILURE], { error }));
  }
};

export const refundPayment = (paymentId, amount, method) => async (dispatch) => {
  dispatch(action(BOOKING_REFUND[REQUEST]));

  const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/booking/${paymentId}/refund`, { method, amount });

  if (data.res === 'error') {
    Notification.openNotification({
      message: 'Failure',
      description: 'There was an error processing your request.',
      type: 'error',
    });
    dispatch(action(BOOKING_REFUND[FAILURE], { error: data.res }));
    return;
  }
  dispatch(action(BOOKING_REFUND[SUCCESS]));
  Notification.openNotification({
    message: 'Success',
    description: 'Booking has been successfully refunded.',
    type: 'success',
  });
};

export const changeCheckinDate = (bookingId, checkinDate) => async (dispatch) => {
  dispatch(action(BOOKING_CHECKIN_DATE[REQUEST]));
  try {
    await axios.get(`${process.env.REACT_APP_API_URL}/booking/${bookingId}/checkin`, { params: { checkinDate } });

    dispatch(action(BOOKING_CHECKIN_DATE[SUCCESS]));
    Notification.openNotification({
      message: 'Success',
      description: `Checkin date was successfully changed to ${moment(checkinDate).format('MMM DD, YYYY')}`,
      type: 'success',
    });
  } catch (error) {
    Notification.openNotification({
      message: 'Failure',
      description: 'There was a problem processing your request.',
      type: 'error',
    });
    dispatch(action(BOOKING_CHECKIN_DATE[FAILURE], { error }));
  }
};


export const resendReceipt = (bookingId, type) => async (dispatch) => {
  dispatch(action(BOOKING_RECEIPT[REQUEST]));
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/booking/${bookingId}/resend`, { params: { type } });

    if (res && res.data.emailTaskId) {
      Notification.openNotification({
        message: 'Success',
        description: 'Booking confirmation receipt has been sent.',
        type: 'success',
      });
    }
    dispatch(action(BOOKING_RECEIPT[SUCCESS]));
  } catch (error) {
    Notification.openNotification({
      message: 'Failure',
      description: 'We are unable to complete your request. Please try again later.',
      type: 'error',
    });
    dispatch(action(BOOKING_RECEIPT[FAILURE], { error }));
  }
};
