import {
  REQUEST,
  SUCCESS,
  FAILURE,
  INVENTORY_FETCH,
  INVENTORY_SAVE,
} from '@/redux/store/action-types';


const initialState = {
  products: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INVENTORY_FETCH[REQUEST]:
      return {
        ...state,
      };
    case INVENTORY_FETCH[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case INVENTORY_FETCH[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case INVENTORY_SAVE[REQUEST]:
      return {
        ...state,
      };
    case INVENTORY_SAVE[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case INVENTORY_SAVE[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
