/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable quotes */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Layout,
  Button,
  Form,
  Icon,
  Input,
  Typography,
  Spin,
} from 'antd';
import {
  validateResetPasswordToken,
  setNewPassword,
} from '@/redux/actions';
import { MIN_PASSWORD_LENGTH } from '../../helpers/const';
import styles from './styles.module.css';

const { Title } = Typography;

const {
  Header, Footer, Content,
} = Layout;


class PasswordRecoveryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confirmPassword: '',
      errorStatus: null,
      error: '',
      isValidToken: null,
    };
  }

  componentDidMount() {
    document.title = 'Dayaxe Portal - Create New Password';

    this.validateToken();
  }

  validateToken = async () => {
    const { token } = this.props.match.params;
    let isValid = false;

    try {
      isValid = await this.props.actions.validateResetPasswordToken(token);
    } catch (error) {
      isValid = false;
    }

    this.setState({ isValidToken: isValid, token });
  }


  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const { password, confirmPassword, token } = this.state;

    if (confirmPassword === password) {
      if (password.length < MIN_PASSWORD_LENGTH) {
        this.setState({ errorStatus: 'warning', error: `Password should have at least ${MIN_PASSWORD_LENGTH} symbols` });
        return;
      }

      const result = await this.props.actions.setNewPassword(password, token);

      if (result) {
        this.props.history.push('/');
      } else {
        this.setState({ errorStatus: 'error', error: 'New password was not set' });
      }
    } else {
      this.setState({ errorStatus: 'error', error: 'New Password and Confirm Password should be the same' });
    }
  }

  render() {
    const { error, errorStatus } = this.state;

    return (
      <Layout className="layout" style={{ height: "100vh" }}>
        <Header>
          <div className="logo" style={{ margin: 0 }}>
            <img src="img/logo.svg" alt="logo" />
          </div>
        </Header>
        <Content style={{ padding: "50px 50px", height: "100%" }}>
          <div className={styles.loginContent}>
            <Spin spinning={this.state.isValidToken == null}>
              {this.state.isValidToken ? (
                <Form
                  onSubmit={this.handleSubmit}
                  style={{ maxWidth: "300px" }}
                >
                  <div className={styles.loginTitle}>
                    <Title level={3}>Create New Password</Title>
                  </div>
                  <Form.Item validateStatus={errorStatus}>
                    <Input
                      prefix={(
                        <Icon
                          type="lock"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      )}
                      type="password"
                      placeholder="Create a new password"
                      onChange={this.handleChange}
                      name="password"
                    />
                  </Form.Item>

                  <Form.Item validateStatus={errorStatus} help={error}>
                    <Input
                      prefix={(
                        <Icon
                          type="lock"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      )}
                      type="password"
                      placeholder="Confirm a new password"
                      onChange={this.handleChange}
                      name="confirmPassword"
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      className="login-form-button"
                    >
                      Submit
                    </Button>
                    <span style={{ textAlign: "center" }}>
                      <a
                        className="login-form-forgot"
                        style={{ display: "block" }}
                        href="/"
                      >
                        Remembered password?
                      </a>
                    </span>
                  </Form.Item>
                </Form>
              ) : (
                <div>
                  <p style={{ fontSize: "18px" }}>
                    Looks like your link is invalid
                  </p>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={() => this.props.history.push("/")}
                  >
                    Go back
                  </Button>
                </div>
              )}
            </Spin>
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          <Button
            type="link"
            href="https://dayaxe.zendesk.com/hc/en-us/requests/new"
          >
            Contact Us
          </Button>
          <Button type="link" href="https://www.hotels.dayaxe.com/how-it-works">
            How it works
          </Button>
          <Button type="link" href="https://www.dayaxe.com/terms">
            Terms & services
          </Button>
        </Footer>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    validateResetPasswordToken,
    setNewPassword,
  },
  dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecoveryPage);
