import {
  REQUEST,
  SUCCESS,
  FAILURE,
  IMAGE_SAVE,
  GET_IMAGE,
  DELETE_IMAGE,
  PUT_IMAGE_ORDER,
} from '@/redux/store/action-types';

const initialState = {
  images: JSON.parse(localStorage.getItem('images')) || null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_IMAGE[REQUEST]:
      return {
        ...state,
      };
    case GET_IMAGE[SUCCESS]:
      return {
        ...state,
        ...action.payload,
        images: JSON.parse(localStorage.getItem('images')) || null,
      };
    case GET_IMAGE[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case IMAGE_SAVE[REQUEST]:
      return {
        ...state,
      };
    case IMAGE_SAVE[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case IMAGE_SAVE[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case DELETE_IMAGE[REQUEST]:
      return {
        ...state,
      };
    case DELETE_IMAGE[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case DELETE_IMAGE[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case PUT_IMAGE_ORDER[REQUEST]:
      return {
        ...state,
      };
    case PUT_IMAGE_ORDER[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case PUT_IMAGE_ORDER[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
