import React from 'react';
import { notification, Spin, Icon } from 'antd';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

class NotificationLoader {
  constructor({
    description, message, duration, minTimeBeforeClose,
  }) {
    this.description = description || 'Please wait, the page is loading.';
    this.message = message || 'Please wait';
    this.key = '1';
    this.startDate = Date.now();
    this.duration = duration || 0;
    this.minTimeBeforeClose = minTimeBeforeClose || 700;
    this.open();
  }

  static openNotification = ({ message, description, type = 'error' }) => {
    notification[type]({
      message,
      description,
    });
  };

  open = () => {
    const {
      message, description, key, duration,
    } = this;
    notification.info({
      message,
      description: (
        <div>
          {description}
          {' '}
          <Spin indicator={antIcon} />
        </div>
      ),
      key,
      duration,
    });
  };

  close = () => {
    const { minTimeBeforeClose, startDate } = this;
    const endData = Date.now();
    if (endData - startDate < minTimeBeforeClose) {
      setTimeout(() => {
        this.closeNotification();
      }, minTimeBeforeClose - (endData - startDate));
    } else {
      this.closeNotification();
    }
  };

  closeNotification = () => {
    const { key } = this;
    notification.close(key);
  };
}

export default NotificationLoader;
