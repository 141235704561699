/* eslint-disable no-loop-func */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Badge, Icon } from 'antd';
import './Calendar.css';
import moment from 'moment';

export default class ProductCalendar extends React.Component {
   onDateClick = async (day) => {
     if (day.isBefore(new Date(), 'day')) {
       return;
     }

     if (!this.props.endRange) {
       await this.props.chageDateRange({
         selectedBeginDate: day.format('YYYY-MM-DD'),
         selectedEndDate: day.format('YYYY-MM-DD'),
       });
       this.props.beginChanged();
     } else {
       await this.props.chageDateRange({
         selectedBeginDate: moment.utc(this.props.beginRange).format('YYYY-MM-DD'),
         selectedEndDate: day.format('YYYY-MM-DD'),
       });
     }
   };

  nextMonth = async () => {
    const newMonth = moment(this.props.currentMonth).add(1, 'months');
    this.props.chageMonth(newMonth.format('YYYY-MM-01'));
  };

  prevMonth = async () => {
    const newMonth = moment(this.props.currentMonth).subtract(1, 'months');
    this.props.chageMonth(newMonth.format('YYYY-MM-01'));
  };


  renderCells() {
    const {
      customDates,
      defaultPrices,
      bookings,
    } = this.props.product;

    const {
      beginRange,
      endRange,
    } = this.props;

    const currentMonth = moment.utc(this.props.currentMonth);

    const monthStart = moment.utc(currentMonth).startOf('month');
    const monthEnd = moment.utc(monthStart).endOf('month');
    const startDate = moment(monthStart.startOf('week'));
    const endDate = moment(monthEnd.endOf('week'));

    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = '';

    while (day <= endDate) {
      for (let i = 0; i < 7; i += 1) {
        formattedDate = day.format('D');
        const cloneDay = moment.utc(day);

        const defaultPrice = defaultPrices.find(val => day.isBetween(moment(val.dateStart), moment(val.dateEnd), null, '[)'));
        const customPrice = customDates.customPrices.find(val => moment.utc(val.date, 'YYYY-MM-DD').isSame(day, 'day'));

        const dayOfWeek = day.day();
        const isBlocked = !customPrice && defaultPrice && ((customDates.blockedDates.find(val => moment.utc(day).isSame(val, 'day')) !== undefined) || defaultPrice[dayOfWeek].capacity === 0);
        const isSelected = beginRange && endRange && day.isSameOrAfter(moment.utc(beginRange)) && day.isSameOrBefore(moment.utc(endRange));

        const bookingsOnly = bookings
          .filter(val => val.status !== 3 && moment.utc(val.date).isSame(day, 'day'))
          .reduce(((acc, val) => acc + val.quantity), 0);

        const redemptions = bookings
          .filter(val => val.status === 2 && moment.utc(val.date).isSame(day, 'day'))
          .reduce(((acc, val) => acc + val.quantity), 0);

        const bookingInfo = defaultPrice || customPrice ? (
          <React.Fragment>
            <span className="price">
              {`$ ${customPrice ? customPrice.price : defaultPrice[dayOfWeek].price}`}
            </span>
            <div className="bottom-info">
              <span className="capacity">
                <Badge overflowCount={1000} count={customPrice ? customPrice.capacity : defaultPrice[dayOfWeek].capacity} style={{ backgroundColor: '#f8e71c' }} />
              </span>
              <span className="booked">
                <Badge overflowCount={1000} count={bookingsOnly} style={{ backgroundColor: '#13C1F4' }} />
              </span>
              <span className="redeemed">
                <Badge overflowCount={1000} count={redemptions} style={{ backgroundColor: '#50e3c2' }} />
              </span>
            </div>
          </React.Fragment>
        ) : '';

        days.push(
          <div
            className={`col cell 
              ${day.isSame(currentMonth, 'months') ? '' : 'disabled'} 
              ${day.isSame(moment.utc(beginRange), 'day') ? 'start-date' : ''} 
              ${day.isSame(moment.utc(endRange), 'day') ? 'end-date' : ''}
              ${isSelected ? 'selected' : ''}
              ${isBlocked && !customPrice ? 'blocked' : ''}
              `}
            key={day.format('x')}
            onClick={() => this.onDateClick(cloneDay)}
          >
            <span className="number">{formattedDate}</span>
            {day.isSame(currentMonth, 'months') && !isBlocked ? bookingInfo : ''}

          </div>,
        );

        day = day.add(1, 'day');
      }
      rows.push(
        <div className="row" key={day.format('x')}>
          {days}
        </div>,
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  }


  renderDays = () => {
    const dateFormat = 'dddd';
    const days = [];
    for (let i = 0; i < 7; i += 1) {
      days.push(
        <div className="col col-center" key={i}>
          {moment().day(i).format(dateFormat)}
        </div>,
      );
    }

    return <div className="days row">{days}</div>;
  }

  renderHeader() {
    const dateFormat = 'MMMM YYYY';

    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <div className="icon" onClick={this.prevMonth}>
            <Icon type="left" />
          </div>
        </div>
        <div className="col col-center">
          <span>{moment(this.props.currentMonth).format(dateFormat)}</span>
        </div>
        <div className="col col-end" onClick={this.nextMonth}>
          <div className="icon">
            <Icon type="right" />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="calendar">
        {this.renderHeader()}
        {this.renderDays()}
        {this.renderCells()}
      </div>
    );
  }
}
