/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  changePolicy,
  addHotelPolicyNaive,
  deleteHotelPolicyNaive,
  addHotelPolicy,
  deleteHotelPolicy,
  addAmenitieNaive,
  deleteAmenitieNaive,
  deleteAmenitie,
  getHotels,
  changeMarket,
  addAmenitie,
  changeHotel,
  addImage,
  deleteImage,
  updateImageOrder,
} from '@/redux/actions';

import {
  Input, Button, Descriptions, Select, Switch,
} from 'antd';
import Dropzone from '@/components/DropzoneComponent';
import './hotel-page.css';
import TextArea from 'antd/lib/input/TextArea';

const { Option } = Select;

const AmenitiesTypes = ['pool', 'gym', 'spa', 'dining', 'business', 'events', 'other'];

class AddEditHotelForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      hotelPolicies: this.props.hotelPolicies,
      hotelAmenities: this.props.hotelAmenities,
      amenities: this.props.amenities,
      changedHotel: this.props.hotel,
      uploadedFiles: this.props.uploadedFiles || [],
      selectedMarket: this.props.selectedMarket,
    };
    this.amenitiesInputs = {
      Pool: React.createRef(),
      Gym: React.createRef(),
      Spa: React.createRef(),
      Dining: React.createRef(),
      Business: React.createRef(),
      Events: React.createRef(),
      Other: React.createRef(),
    };
    this.amenitiesUpgradeInputs = {
      Pool: React.createRef(),
      Gym: React.createRef(),
      Spa: React.createRef(),
      Dining: React.createRef(),
      Business: React.createRef(),
      Events: React.createRef(),
      Other: React.createRef(),
    };
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.hotel.HotelId !== this.props.hotel.HotelId) {
      this.setState({
        modalVisible: false,
        changedHotel: this.props.hotel,
      });
    }
    if (prevProps.selectedMarket !== this.props.selectedMarket) {
      this.setState({ selectedMarket: this.props.selectedMarket });
    }
    if (prevProps.hotelPolicies !== this.props.hotelPolicies) {
      this.setState({ hotelPolicies: this.props.hotelPolicies });
    }
    if (prevProps.amenities !== this.props.amenities) {
      this.setState({
        amenities: this.props.amenities,
        hotelAmenities: this.props.hotelAmenities || [],
      });
    }
    if (prevProps.uploadedFiles !== this.props.uploadedFiles) {
      this.setState({ uploadedFiles: this.props.uploadedFiles || [] });
    }
  }

  isFormDisabled = () => this.state.changedHotel && this.state.changedHotel.IsPublished && !this.props.user.isSuperAdmin;

  saveHotel = async () => {
    const enoughPhotos = this.validatePhotosQuantity(this.state.uploadedFiles);
    const amenitiesOk = this.validateAmenities(this.state.hotelAmenities);
    const policiesOk = this.validatePolicies(this.state.hotelPolicies);
    const fieldsAreFilled = this.validateRequiredFields(this.state.changedHotel, this.state.selectedMarket, this.state);
    if (!(fieldsAreFilled && policiesOk && enoughPhotos && amenitiesOk)) return;

    const hotelId = await this.props.saveHotel(this.state.changedHotel);
    await this.props.actions.getHotels();

    const market = { HotelId: hotelId, ...this.state.selectedMarket };
    await this.props.processHotelMarket(market);

    const hotelAmenitie = {
      ...this.state.hotelAmenities,
      ...this.state.hotelAmenities.CheckboxCheck,
      HotelId: hotelId,
    };

    const hotelAmenitieId = await this.props.processHotelAmenitie(hotelAmenitie);

    const updatedItems = await this.updateImageOrders(hotelId);
    await this.addingProcces(hotelId, hotelAmenitieId, updatedItems);
    await this.deletingProcces();

    const selectedHotel = this.props.user.hotels.find(val => val.id === hotelId);

    await this.props.actions.changeHotel(selectedHotel);

    await this.props.history.push('/calendar');
  }

  validateRequiredFields = (changedHotel, market) => {
    const requiredTextFields = [
      { key: 'HotelName', caption: 'Hotel Name' },
      { key: 'Neighborhood', caption: 'Neighborhood' },
      { key: 'StreetAddress', caption: 'Street Address' },
      { key: 'City', caption: 'City' },
      { key: 'State', caption: 'State' },
      { key: 'ZipCode', caption: 'ZIP Code' },
      { key: 'PhoneNumber', caption: 'Phone Number' },
      { key: 'Latitude', caption: 'Latitude' },
      { key: 'Longitude', caption: 'Longitude' },
      { key: 'GeneralHours', caption: 'General Hours' },
      { key: 'Recommendation', caption: 'Recommendation' },
      { key: 'HotelParking', caption: 'Hotel Parking' },
    ];
    let pass = true;
    if (!market) {
      if (pass) {
        document.getElementById('label_MarketLocation').scrollIntoView();
      }
      pass = false;
      this.changeDisplayValidationError('MarketLocation', false);
    }
    requiredTextFields.forEach((pair) => {
      const value = changedHotel[pair.key];
      if (!value || value === '') {
        if (pass) {
          document.getElementById(`label_${pair.key}`).scrollIntoView();
        }
        pass = false;
        this.changeDisplayValidationError(pair.key, false, `${pair.caption} is required!`);
      }
    });

    return pass;
  }

  validateAmenities = (amenities) => {
    const pass = amenities != null && amenities.CheckboxCheck
              && (amenities.CheckboxCheck.IsCheckedPool
              || amenities.CheckboxCheck.IsCheckedGym
              || amenities.CheckboxCheck.IsCheckedSpa
              || amenities.CheckboxCheck.IsCheckedDining
              || amenities.CheckboxCheck.IsCheckedBusiness
              || amenities.CheckboxCheck.IsCheckedEvents
              || amenities.CheckboxCheck.IsCheckedOther);
    this.changeDisplayValidationError('Amenities', pass);
    if (!pass) {
      document.getElementById('label_Amenities').scrollIntoView();
    }
    return pass;
  }

  validatePhotosQuantity = (uploadedFiles) => {
    if (uploadedFiles.filter(image => !image.ToDelete).length >= 6) {
      document.getElementById('error_Photos').style.display = 'none';
      document.getElementById('error_Photos').classList.remove('active');
      return true;
    }
    document.getElementById('error_Photos').style.display = 'block';
    document.getElementById('error_Photos').className += ' active';
    return false;
  }

  validatePolicies = (policies) => {
    const pass = policies.filter(pol => pol.ToDelete === false).length > 0;
    this.changeDisplayValidationError('Policies', pass);
    if (!pass) {
      document.getElementById('label_Policies').scrollIntoView();
    }
    return pass;
  }

  updateImageOrders = async (HotelId) => {
    const updatedItems = this.state.uploadedFiles.filter(image => !image.ToDelete).map((item, index) => ({
      ...item,
      ImageTypeId: 5, // Cover
      Order: index + 1,
      Url: `/HotelImage/${HotelId}/${index + 1}_${item.Name}`,
      HotelId,
      IsActive: true,
    }));

    if (updatedItems) {
      updatedItems.forEach((item) => {
        if (!item.IsNew) {
          this.props.actions.updateImageOrder(item.Id, item.Order);
        }
      });
    }

    return updatedItems;
  }

  addingProcces = async (hotelId, hotelAmenitieId, updatedItems) => {
    if (this.state.hotelPolicies) {
      this.state.hotelPolicies.forEach((item) => {
        if (item.IsNew && !item.ToDelete) {
          const addPolicy = {
            HotelId: hotelId,
            ...item,
          };

          this.props.actions.addHotelPolicy(addPolicy);
        }
      });

      this.setState({ hotelPolicies: this.props.hotelPolicies });
    }

    if (this.state.amenities) {
      this.state.amenities.forEach((item) => {
        if (item.IsNew && !item.ToDelete) {
          const result = { ...item, AmentyId: hotelAmenitieId };
          this.props.actions.addAmenitie(result);
        }
      });

      this.setState({ amenities: this.props.amenities });
    }

    if (updatedItems) {
      updatedItems.forEach((item) => {
        if (item.IsNew && !item.ToDelete) {
          this.props.actions.addImage(item);
        }
      });
    }
  }

  deletingProcces = async () => {
    if (this.state.hotelPolicies) {
      this.state.hotelPolicies.forEach((item) => {
        if (item.ToDelete) {
          this.props.actions.deleteHotelPolicy(item);
        }
      });
      this.setState({ hotelPolicies: this.props.hotelPolicies });
    }

    if (this.state.amenities) {
      this.state.amenities.forEach((item) => {
        if (item.ToDelete) {
          this.props.actions.deleteAmenitie(item);
        }
      });
      this.setState({ amenities: this.props.amenities });
    }

    this.state.uploadedFiles.forEach((item) => {
      if (item.ToDelete) {
        this.props.actions.deleteImage(item.Id);
      }
    });
    const images = this.state.uploadedFiles.filter(image => image.ToDelete === false);
    this.setState({ uploadedFiles: images });
  }

  changeHotel = (value, fieldName) => {
    const newChanges = this.state.changedHotel;
    newChanges[fieldName] = value;
    this.setState({ changedHotel: newChanges });
  }

  changeHotelAmenities = (value, fieldName) => {
    const newChanges = this.state.hotelAmenities;
    newChanges[fieldName] = value;
    this.setState({ hotelAmenities: newChanges });
  }

  // //////////////////////////////////////////////////////////////////////////////////////

  onChangeValidation = (value, fieldName) => {
    const validationPattern = this.getValidationPattern(fieldName);
    if (validationPattern !== null) {
      this.changeDisplayValidationError(fieldName, validationPattern.test(value));

      this.changeHotel(value, fieldName);
    }
  }

  changeDisplayValidationError = (fieldName, hide, error = undefined) => {
    if (hide) {
      document.getElementById(`error_${fieldName}`).style.display = 'none';
      document.getElementById(`error_${fieldName}`).classList.remove('active');
    } else {
      document.getElementById(`error_${fieldName}`).style.display = 'block';
      document.getElementById(`error_${fieldName}`).className += ' active has-error ant-form-explain';
      if (error) {
        document.getElementById(`error_${fieldName}`).innerText = error;
      }
    }
  }

  getValidationPattern = (fieldName) => {
    if (fieldName === 'HotelName') {
      const validationPattern = /^[a-zA-Z0-9\s\-',.()&]+$/;
      return validationPattern;
    }

    if (fieldName === 'Neighborhood') {
      const validationPattern = /^[a-zA-Z0-9\s\-',.()&]+$/;
      return validationPattern;
    }

    if (fieldName === 'TripAdvisorRating') {
      const validationPattern = /^[1-5]$/;
      return validationPattern;
    }

    if (fieldName === 'HotelDiscountPercent') {
      return /^(?:100|\d{1,2})$/;
    }

    if (fieldName === 'HotelDiscountCode') {
      const validationPattern = /^[a-zA-Z0-9]{6,12}$/;
      return validationPattern;
    }

    if (fieldName === 'PhoneNumber') {
      const validationPattern = /^\+?[1-9]\d{1,14}$/;
      return validationPattern;
    }

    if (fieldName === 'StreetAddress') {
      const validationPattern = /^[a-zA-Z0-9\s,'.-]{3,}$/;
      return validationPattern;
    }

    if (fieldName === 'City') {
      const validationPattern = /^[a-zA-Z\s\-',.()&]+$/;
      return validationPattern;
    }

    if (fieldName === 'State') {
      const validationPattern = /^[A-Z]{2}$/;
      return validationPattern;
    }

    if (fieldName === 'GeneralHours') {
      const validationPattern = /^[a-zA-Z0-9\s,'.-]{3,}$/;
      return validationPattern;
    }

    if (fieldName === 'Recommendation') {
      const validationPattern = /^[a-zA-Z\s\-',.()&!0-9]+$/;
      return validationPattern;
    }

    if (fieldName === 'HotelParking') {
      const validationPattern = /^[a-zA-Z0-9\s\-',.()&]+$/;
      return validationPattern;
    }

    if (fieldName === 'Latitude') {
      const validationPattern = /-?\d{1,3}\.\d+/;
      return validationPattern;
    }

    if (fieldName === 'Longitude') {
      const validationPattern = /-?\d{1,3}\.\d+/;
      return validationPattern;
    }

    if (fieldName === 'ZipCode') {
      const validationPattern = /^\d{5}(?:-\d{4})?$/;
      return validationPattern;
    }

    return null;
  }
  // ////////////////////////////////////////////////////////////////// RENDER MAIN HOTEL

  renderMainHotel = () => (
    <div className="flex-element">
      <div className="flex-container main-hotel">
        <div className="flex-element main-hotel-element">
          <div id="label_HotelName" className="required-field">HotelName</div>
          <Input disabled={this.isFormDisabled()} placeholder="" value={this.state.changedHotel.HotelName} onChange={e => this.onChangeValidation(e.target.value, 'HotelName')} />
          <div id="error_HotelName" className="error-validation">HotelName validation error</div>
        </div>
        <div className="flex-element main-hotel-element">
          <div id="label_Neighbourhood" className="required-field">Neighborhood</div>
          <Input disabled={this.isFormDisabled()} placeholder="" value={this.state.changedHotel.Neighborhood} onChange={e => this.onChangeValidation(e.target.value, 'Neighborhood')} />
          <div id="error_Neighborhood" className="error-validation">Neighborhood validation error</div>
        </div>
        <div className="flex-element main-hotel-element">
          <div style={{ textAlign: 'left', paddingRight: '25px' }}>Trip Advisor Rating</div>
          <Input disabled={this.isFormDisabled()} placeholder="" value={this.state.changedHotel.TripAdvisorRating} onChange={e => this.onChangeValidation(e.target.value, 'TripAdvisorRating')} />
          <div id="error_TripAdvisorRating" className="error-validation">TripAdvisorRating validation error</div>
        </div>
        <div className="flex-element main-hotel-element">
          <div style={{ textAlign: 'left', paddingRight: '25px' }}>Hotel Type</div>
          {this.renderHotelType()}
        </div>
        <div className="flex-element main-hotel-element">
          <div className="flex-container discount-code">
            <div className="flex-element" style={{ width: '170px' }}>
              <div className="required-field" id="label_MarketLocation">Market & Location</div>
              <Select
                disabled={this.isFormDisabled()}
                showSearch
                style={{ width: 170, marginRight: '20px' }}
                placeholder="Select Market & Location:"
                value={this.state.selectedMarket ? this.state.selectedMarket.Location : undefined}
                optionFilterProp="children"
                onChange={this.handleChangeMarket}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {this.renderMarketOptions()}
              </Select>
              <div id="error_MarketLocation" className="error-validation">Please select a location!</div>
            </div>
            {this.props.user.isSuperAdmin && (
              <div className="flex-element">
                <div style={{ textAlign: 'left', paddingRight: '25px' }}>IsPublished</div>
                <Switch
                  disabled={this.isFormDisabled()}
                  onClick={checked => this.handleSwitchChange(checked, 'IsPublished')}
                  checked={this.state.changedHotel.IsPublished}
                  className="react-switch"
                  id="IsPublished"
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex-element main-hotel-element">
          <div className="flex-container discount-code">
            <div className="flex-element">
              <div style={{ textAlign: 'left', paddingRight: '25px' }}>Check-in at</div>
              <Input disabled={this.isFormDisabled()} placeholder="Front Desk" style={{ width: '170px' }} value={this.state.changedHotel.CheckInPlace} onChange={e => this.changeHotel(e.target.value, 'CheckInPlace')} />
            </div>
            <div className="flex-element" style={{ alignContent: 'center' }}>
              <div style={{ textAlign: 'left', paddingRight: '25px', width: '120px' }}>Coming Soon</div>
              <Switch
                disabled={this.isFormDisabled()}
                onClick={checked => this.handleSwitchChange(checked, 'IsComingSoon')}
                checked={this.state.changedHotel.IsComingSoon}
                className="react-switch"
                id="IsComingSoon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  renderHotelType = () => (
    <div className="flex-container discount-code" style={{ gridGap: 0 }}>
      <div className="flex-element">
        <label htmlFor="type0">
          <input disabled={this.isFormDisabled()} type="radio" id="type0" name="type0" value="0" checked={this.state.changedHotel.HoteltypeId === 0} onChange={this.changeHotelType} />
          Festive
        </label>
      </div>
      <div className="flex-element">
        <label htmlFor="type1">
          <input disabled={this.isFormDisabled()} type="radio" id="type1" name="type1" value="1" checked={this.state.changedHotel.HoteltypeId === 1} onChange={this.changeHotelType} />
          Tranquil
        </label>
      </div>
      <div className="flex-element">
        <label htmlFor="type2">
          <input disabled={this.isFormDisabled()} type="radio" id="type2" name="type2" value="2" checked={this.state.changedHotel.HoteltypeId === 2} onChange={this.changeHotelType} />
          Family
        </label>
      </div>
      <div className="flex-element">
        <label htmlFor="type3">
          <input disabled={this.isFormDisabled()} type="radio" id="type3" name="type3" value="3" checked={this.state.changedHotel.HoteltypeId === 3} onChange={this.changeHotelType} />
          Basic
        </label>
      </div>
    </div>
  )

  changeHotelType = (event) => {
    const newChangeHotel = { ...this.state.changedHotel, HoteltypeId: Number(event.target.value) };

    this.setState({ changedHotel: newChangeHotel });
  }

  renderMarketOptions = () => (
    this.state.changedHotel.HotelMarkets.map(val => <Option value={val.Id} key={val.Id}>{val.MarketCode}-{val.LocationName}</Option>)
  );

  // ////////////////////////////////////////////////////////////////// RENDER MAIN HOTEL
  // ////////////////////////////////////////////////////////////////// HANDLERS

  handleChangeMarket = async (value) => {
    const selectedMarket = this.state.changedHotel.HotelMarkets.find(val => val.Id === value);

    const oldMarket = JSON.parse(localStorage.getItem('selectedMarket')) || null;

    let market = {
      MarketId: selectedMarket.Id,
      Location: `${selectedMarket.MarketCode}-${selectedMarket.LocationName}`,
    };

    if (oldMarket !== null) {
      market = { ...market, Id: oldMarket.Id, HotelId: oldMarket.HotelId };
    }

    await this.props.actions.changeMarket(market);
    this.changeDisplayValidationError('MarketLocation', true);
    this.setState({ selectedMarket: market });
  }

  handleSwitchChange = (checked, type) => {
    if (type === 'IsPublished') {
      const newChangeHotel = { ...this.state.changedHotel, IsPublished: checked };

      this.setState({ changedHotel: newChangeHotel });
    }
    if (type === 'IsComingSoon') {
      const newChangeHotel = { ...this.state.changedHotel, IsComingSoon: checked };

      this.setState({ changedHotel: newChangeHotel });
    }
  }

  // //////////////////////////////////////////////////////////////////////////////////////
  // ////////////////////////////////////////////////////////////////// RENDER DISCOUNT PANEL

  renderDiscountPanel = () => (
    <div className="flex-element discount">
      <Descriptions.Item label="Discount">HOTEL DISCOUNT SETUP</Descriptions.Item>
      <div className="flex-container discount-page" style={{ width: 315, height: 468 }}>
        <div className="flex-element" style={{ height: 5, width: 275 }}>
          <div className="flex-container discount-code">
            <div className="flex-element">
              <div style={{ textAlign: 'left', paddingRight: '25px' }}>Discount</div>
              <Input disabled={this.isFormDisabled()} placeholder="" value={this.state.changedHotel.HotelDiscountPercent} onChange={e => this.onChangeValidation(e.target.value, 'HotelDiscountPercent')} />
              <div id="error_HotelDiscountPercent" className="error-validation">HotelDiscountPercent validation error</div>
            </div>
            <div className="flex-element">
              <div style={{ textAlign: 'left', paddingRight: '25px' }}>Code</div>
              <Input disabled={this.isFormDisabled()} placeholder="" value={this.state.changedHotel.HotelDiscountCode} onChange={e => this.onChangeValidation(e.target.value, 'HotelDiscountCode')} />
              <div id="error_HotelDiscountCode" className="error-validation">HotelDiscountCode validation error</div>
            </div>
          </div>
        </div>
        <div className="flex-element">
          <div style={{ textAlign: 'left', paddingRight: '25px' }}>Hotel Discount Disclaimer</div>
          <Input disabled={this.isFormDisabled()} placeholder="" style={{ height: 110, width: 275 }} value={this.state.changedHotel.HotelDiscountDisclaimer} onChange={e => this.changeHotel(e.target.value, 'HotelDiscountDisclaimer')} />
        </div>
        <div className="flex-element">
          <div id="label_HotelParking" className="required-field">HOTEL PARKING SETUP</div>
          <Input disabled={this.isFormDisabled()} placeholder="" style={{ height: 110, width: 275 }} value={this.state.changedHotel.HotelParking} onChange={e => this.onChangeValidation(e.target.value, 'HotelParking')} />
          <div id="error_HotelParking" className="error-validation">Hotel Parking validation error</div>
        </div>
      </div>
    </div>
  );

  // //////////////////////////////////////////////////////////////////////////////////////
  // ////////////////////////////////////////////////////////////////// RENDER ADDRESS CARD

  renderAddressCard = () => (
    <div className="flex-container address-card">
      <div className="flex-element main-hotel-element">
        <div id="label_StreetAddress" className="required-field">Street Address</div>
        <Input disabled={this.isFormDisabled()} placeholder="" value={this.state.changedHotel.StreetAddress} onChange={e => this.onChangeValidation(e.target.value, 'StreetAddress')} />
        <div id="error_StreetAddress" className="error-validation">Street Address validation error</div>
      </div>
      <div className="flex-element main-hotel-element">
        <div className="flex-container discount-code">
          <div className="flex-element">
            <div id="label_City" className="required-field">City</div>
            <Input disabled={this.isFormDisabled()} placeholder="" value={this.state.changedHotel.City} onChange={e => this.onChangeValidation(e.target.value, 'City')} />
            <div id="error_City" className="error-validation">City validation error</div>
          </div>
          <div className="flex-element">
            <div id="label_State" className="required-field">State</div>
            <Input disabled={this.isFormDisabled()} placeholder="CA" value={this.state.changedHotel.State} onChange={e => this.onChangeValidation(e.target.value, 'State')} />
            <div id="error_State" className="error-validation">State validation error</div>
          </div>
        </div>
      </div>
      <div className="flex-element main-hotel-element">
        <div className="flex-container discount-code">
          <div className="flex-element">
            <div id="label_Zipcode" className="required-field">ZIP Code</div>
            <Input disabled={this.isFormDisabled()} placeholder="00000" value={this.state.changedHotel.ZipCode} onChange={e => this.onChangeValidation(e.target.value, 'ZipCode')} />
            <div id="error_ZipCode" className="error-validation">ZIP Code validation error</div>
          </div>
          <div className="flex-element">
            <div id="label_PhoneNumber" className="required-field">Phone Number</div>
            <Input disabled={this.isFormDisabled()} placeholder="+12120000000" value={this.state.changedHotel.PhoneNumber} onChange={e => this.onChangeValidation(e.target.value, 'PhoneNumber')} />
            <div id="error_PhoneNumber" className="error-validation">Phone Number validation error</div>
          </div>
        </div>
      </div>
      <div className="flex-element main-hotel-element">
        <div className="flex-container discount-code">
          <div className="flex-element">
            <div id="label_Latitude" className="required-field">Latitude</div>
            <Input disabled={this.isFormDisabled()} placeholder="00.0" value={this.state.changedHotel.Latitude} onChange={e => this.onChangeValidation(e.target.value, 'Latitude')} />
            <div id="error_Latitude" className="error-validation">Latitude validation error</div>
          </div>
          <div className="flex-element">
            <div id="label_Longitude" className="required-field">Longitude</div>
            <Input disabled={this.isFormDisabled()} placeholder="00.0" value={this.state.changedHotel.Longitude} onChange={e => this.onChangeValidation(e.target.value, 'Longitude')} />
            <div id="error_Longitude" className="error-validation">Longitude validation error</div>
          </div>
        </div>
      </div>
    </div>
  );

  // //////////////////////////////////////////////////////////////////////////////////////
  // ////////////////////////////////////////////////////////////////// RENDER TERMS

  renderTerms = () => (
    <div className="flex-container terms-block">
      <div className="flex-element">
        <div id="label_GeneralHours" className="required-field">General Hours</div>
        <Input disabled={this.isFormDisabled()} placeholder="7am - 5pm" value={this.state.changedHotel.GeneralHours} onChange={e => this.onChangeValidation(e.target.value, 'GeneralHours')} />
        <div id="error_GeneralHours" className="error-validation">General Hours validation error</div>
      </div>
      <div className="flex-element terms">
        <div style={{ textAlign: 'left', paddingRight: '25px' }}>Hotel Terms & Conditions</div>
        <Input disabled={this.isFormDisabled()} placeholder="" style={{ marginLeft: '15px', width: '401px' }} value={this.state.changedHotel.TermsAndConditions} onChange={e => this.changeHotel(e.target.value, 'TermsAndConditions')} />
      </div>
    </div>
  );

  // //////////////////////////////////////////////////////////////////////////////////////
  // ////////////////////////////////////////////////////////////////// RECOMENDATION AND POLICY

  renderRecommendationPanel = () => (
    <div className="flex-container recomendation-block">
      <div className="flex-element">
        <div id="label_Recommendation" className="required-field">Recommendation</div>
        <TextArea maxLength={1000} disabled={this.isFormDisabled()} placeholder="" style={{ height: 110 }} value={this.state.changedHotel.Recommendation} onChange={e => this.onChangeValidation(e.target.value, 'Recommendation')} />
        <div id="error_Recommendation" className="error-validation">Recommendation validation error</div>
      </div>
      <div className="flex-element terms" style={{ width: 418, height: 'fit-content' }}>
        <div id="label_Policies" className="required-field">Select applicable policies:</div>
        <div className="flex-container discount-code" style={{ height: 50 }}>
          <div className="flex-element">
            <Select
              disabled={this.isFormDisabled()}
              showSearch
              style={{ width: 241, marginRight: '-30px' }}
              placeholder="Select applicable policies:"
              optionFilterProp="children"
              onChange={this.handleChangePolicy}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {this.renderPolicyOptions()}
            </Select>
          </div>
          <div className="flex-element">
            <Button disabled={this.isFormDisabled()} type="primary" style={{ marginRight: '150px' }} onClick={() => this.addHotelPolicyNaive()}>Add</Button>
          </div>
        </div>
        {this.renderHotelPolicies()}
        <div id="error_Policies" className="error-validation">Please select at least one policy</div>
      </div>
    </div>
  );

  renderPolicyOptions = () => (
    this.props.policies && this.props.policies.map(val => <Option value={val.Id} key={val.Id}>{val.Name}</Option>)
  );

  renderHotelPolicies = () => {
    if (this.props.hotelPolicies && this.props.hotelPolicies.filter) {
      const hotelPolicies = this.props.hotelPolicies.filter(pol => pol.ToDelete === false);

      if (hotelPolicies.length > 0) {
        return (
          <div style={{ border: '1px solid #ccc' }}>
            {hotelPolicies.map(item => (
              <div key={item.Id}>
                <Descriptions.Item label>
                  {item.Name}
                  {'       '}
                  <input
                    id={`checkbox_${item.Id}`}
                    type="checkbox"
                    onClick={() => this.handlePolicyCheck(item.Id)}
                  />
                </Descriptions.Item>
              </div>
            ))}
            <Button disabled={this.isFormDisabled()} onClick={() => this.deleteHotelPolicyNaive()}>DELETE</Button>
          </div>
        );
      }
      return null;
    }

    return null;
  }

  // ////////////////////////////////////////////////////////////////// RECOMENDATION AND POLICY
  // ////////////////////////////////////////////////////////////////// HANDLERS

  handleChangePolicy = async (value) => {
    const selectedPolicy = this.props.policies.find(val => val.Id === value);
    await this.props.actions.changePolicy(selectedPolicy);
  }

  handlePolicyCheck = (id) => {
    const policies = this.state.hotelPolicies.map((item) => {
      if (item.Id === id) {
        return { ...item, IsChecked: document.getElementById(`checkbox_${item.Id}`).checked };
      }
      return item;
    });

    this.setState({ hotelPolicies: policies });
  };

  // ////////////////////////////////////////////////////////////////// RECOMENDATION AND POLICY
  // ////////////////////////////////////////////////////////////////// FUNCTION

  addHotelPolicyNaive = async () => {
    const newHotelPolicie = {
      PolicyId: this.props.selectedPolicy.Id,
      ProductId: 0,
      Order: 0,
    };

    await this.props.actions.addHotelPolicyNaive(newHotelPolicie);

    this.setState({ hotelPolicies: this.props.hotelPolicies });
    this.validatePolicies(this.props.hotelPolicies);
  }

  deleteHotelPolicyNaive = async () => {
    this.state.hotelPolicies.forEach((item) => {
      if (item.IsChecked) {
        this.props.actions.deleteHotelPolicyNaive(item);
      }
    });

    this.setState({ hotelPolicies: this.props.hotelPolicies });
  }

  // //////////////////////////////////////////////////////////////////////////////////////
  // ////////////////////////////////////////////////////////////////// RENDER AMENITIES

  renderAmenities = () => (
    <div id="label_Amenities" className="Amenities required-field">
      <div id="error_Amenities" className="flex-element error-validation">At least one amenity should be filled!</div>
      <div className="tab">
        <Button className="tablinks" onClick={() => this.openAmenitie('pool')}>Pool</Button>
        <Button className="tablinks" onClick={() => this.openAmenitie('gym')}>Gym</Button>
        <Button className="tablinks" onClick={() => this.openAmenitie('spa')}>Spa</Button>
        <Button className="tablinks" onClick={() => this.openAmenitie('business')}>Business</Button>
        <Button className="tablinks" onClick={() => this.openAmenitie('dining')}>Dining</Button>
        <Button className="tablinks" onClick={() => this.openAmenitie('events')}>Events</Button>
        <Button className="tablinks" onClick={() => this.openAmenitie('other')}>Other</Button>
      </div>

      <div id="pool" className="tabcontent active" style={{ display: 'block' }}>
        {(this.renderAmenitie(0, 'Pool'))}
      </div>

      <div id="gym" className="tabcontent">
        {(this.renderAmenitie(1, 'Gym'))}
      </div>

      <div id="spa" className="tabcontent">
        {(this.renderAmenitie(2, 'Spa'))}
      </div>

      <div id="business" className="tabcontent">
        {(this.renderAmenitie(3, 'Business'))}
      </div>

      <div id="dining" className="tabcontent">
        {(this.renderAmenitie(4, 'Dining'))}
      </div>

      <div id="events" className="tabcontent">
        {(this.renderAmenitie(5, 'Events'))}
      </div>
      <div id="other" className="tabcontent">
        {(this.renderAmenitie(6, 'Other'))}
      </div>
    </div>
  );

  openAmenitie = (value) => {
    AmenitiesTypes.forEach((type) => {
      document.getElementById(type).style.display = 'none';
      document.getElementById(type).classList.remove('active');
    });

    document.getElementById(value).style.display = 'block';
    document.getElementById(value).className += ' active';
  }

  // ////////////////////////////////////////////////////////////////// RENDER AMENITIES
  // ////////////////////////////////////////////////////////////////// BASE

  renderAmenitie = (amenitieType, amenitieName) => (
    <div className="flex-container" style={{ flexDirection: 'column', gridGap: '25px' }}>
      <div className="flex-element">
        <Descriptions.Item label>
          {'IsActive'}
          {'       '}
          <input
            disabled={this.isFormDisabled()}
            id={`checkbox_${amenitieName}`}
            type="checkbox"
            defaultChecked={this.renderAmenitieCheck(amenitieName)}
            onClick={() => this.handleHotelAmenitieCheck(amenitieName)}
          />
        </Descriptions.Item>
      </div>
      <div className="flex-element">
        {this.renderAmenitieListResult(this.renderAmenitieCheck(amenitieName), amenitieType, amenitieName)}
      </div>
    </div>
  )

  renderAmenitieCheck = (amenitieName) => {
    if (amenitieName === 'Pool') {
      return (
        this.state.hotelAmenities.CheckboxCheck.IsCheckedPool
      );
    }
    if (amenitieName === 'Gym') {
      return (
        this.state.hotelAmenities.CheckboxCheck.IsCheckedGym
      );
    }
    if (amenitieName === 'Spa') {
      return (
        this.state.hotelAmenities.CheckboxCheck.IsCheckedSpa
      );
    }
    if (amenitieName === 'Dining') {
      return (
        this.state.hotelAmenities.CheckboxCheck.IsCheckedDining
      );
    }
    if (amenitieName === 'Business') {
      return (
        this.state.hotelAmenities.CheckboxCheck.IsCheckedBusiness
      );
    }
    if (amenitieName === 'Events') {
      return (
        this.state.hotelAmenities.CheckboxCheck.IsCheckedEvents
      );
    }
    if (amenitieName === 'Other') {
      return (
        this.state.hotelAmenities.CheckboxCheck.IsCheckedOther
      );
    }

    return null;
  }

  renderAmenitieListResult = (checkValue, amenitieType, amenitieName) => {
    if (checkValue) {
      return (
        <div className="flex-container" style={{ flexDirection: 'column', gridGap: '25px' }}>
          <div className="flex-element">
            <div style={{ textAlign: 'left', paddingRight: '25px' }}>{amenitieName} Hours</div>
            {this.renderAmenitieHour(amenitieName)}
          </div>
          <div className="flex-element">
            {this.renderAmenitieList(amenitieType, amenitieName)}
          </div>
          <div className="flex-element">
            {this.renderUpgradeAmenitieList(amenitieType, amenitieName)}
          </div>
        </div>
      );
    }
    return null;
  }

  // ////////////////////////////////////////////////////////////////// RENDER AMENITIES
  // ////////////////////////////////////////////////////////////////// CHARACTERS

  renderAmenitieHour = (amenitieName) => {
    if (amenitieName === 'Pool') {
      return (
        <Input disabled={this.isFormDisabled()} placeholder="" style={{ height: 50, width: 473 }} value={this.state.hotelAmenities.PoolHours} onChange={e => this.changeHotelAmenities(e.target.value, 'PoolHours')} />
      );
    }
    if (amenitieName === 'Gym') {
      return (
        <Input disabled={this.isFormDisabled()} placeholder="" style={{ height: 50, width: 473 }} value={this.state.hotelAmenities.GymHours} onChange={e => this.changeHotelAmenities(e.target.value, 'GymHours')} />
      );
    }
    if (amenitieName === 'Spa') {
      return (
        <Input disabled={this.isFormDisabled()} placeholder="" style={{ height: 50, width: 473 }} value={this.state.hotelAmenities.SpaHours} onChange={e => this.changeHotelAmenities(e.target.value, 'SpaHours')} />
      );
    }
    if (amenitieName === 'Dining') {
      return (
        <Input disabled={this.isFormDisabled()} placeholder="" style={{ height: 50, width: 473 }} value={this.state.hotelAmenities.DinningHours} onChange={e => this.changeHotelAmenities(e.target.value, 'DinningHours')} />
      );
    }
    if (amenitieName === 'Business') {
      return (
        <Input disabled={this.isFormDisabled()} placeholder="" style={{ height: 50, width: 473 }} value={this.state.hotelAmenities.BusinessCenterHours} onChange={e => this.changeHotelAmenities(e.target.value, 'BusinessCenterHours')} />
      );
    }
    if (amenitieName === 'Events') {
      return (
        <Input disabled={this.isFormDisabled()} placeholder="" style={{ height: 50, width: 473 }} value={this.state.hotelAmenities.EventHours} onChange={e => this.changeHotelAmenities(e.target.value, 'EventHours')} />
      );
    }
    if (amenitieName === 'Other') {
      return (
        <Input disabled={this.isFormDisabled()} placeholder="" style={{ height: 50, width: 473 }} value={this.state.hotelAmenities.OtherHours} onChange={e => this.changeHotelAmenities(e.target.value, 'OtherHours')} />
      );
    }

    return null;
  }

  renderAmenitieList = (amenitieType, amenitieName) => (
    <div className="flex-element terms">
      <div style={{ textAlign: 'left', paddingRight: '25px' }}>{amenitieName} Amenitie</div>
      <div className="flex-container discount-code" style={{ height: 50, width: 502 }}>
        <div className="flex-element">
          <Input disabled={this.isFormDisabled()} placeholder="" style={{ height: 40, width: 472 }} ref={this.getRefByAmenitieType(amenitieType)} />
        </div>
        <div className="flex-element">
          <Button disabled={this.isFormDisabled()} type="primary" style={{ marginRight: '150px' }} onClick={() => this.addAmenitieNaive(amenitieType, true)}>Add</Button>
        </div>
      </div>
      {this.props.amenities && (
        <div style={{ border: '1px solid #ccc' }}>
          {this.props.amenities.filter(amenitie => amenitie.AmentyTypeId === amenitieType && amenitie.ToDelete === false && amenitie.IsAmenty === true).map(item => (
            <div key={item.Id}>
              <Descriptions.Item label>
                {item.Name}
                {'       '}
                <input
                  disabled={this.isFormDisabled()}
                  id={`checkbox_${amenitieName}${item.Id}`}
                  type="checkbox"
                  onClick={() => this.handleAmenitieCheck(item.Id, amenitieType, amenitieName)}
                />
              </Descriptions.Item>
            </div>
          ))}
          <Button disabled={this.isFormDisabled()} onClick={() => this.deleteAmenitieNaive(true)}>DELETE</Button>
        </div>
      )}
    </div>
  )

  renderUpgradeAmenitieList = (amenitieType, amenitieName) => (
    <div className="flex-element terms">
      <div style={{ textAlign: 'left', paddingRight: '25px' }}>{amenitieName} Upgrades</div>
      <div className="flex-container discount-code" style={{ height: 50, width: 502 }}>
        <div className="flex-element">
          <Input disabled={this.isFormDisabled()} placeholder="" style={{ height: 40, width: 472 }} ref={this.getUpgradeRefByAmenitieType(amenitieType)} />
        </div>
        <div className="flex-element">
          <Button disabled={this.isFormDisabled()} type="primary" style={{ marginRight: '150px' }} onClick={() => this.addAmenitieNaive(amenitieType, false)}>Add</Button>
        </div>
      </div>
      {this.props.amenities && (
        <div style={{ border: '1px solid #ccc' }}>
          {this.props.amenities.filter(amenitie => amenitie.AmentyTypeId === amenitieType && amenitie.ToDelete === false && amenitie.IsAmenty === false).map(item => (
            <div key={item.Id}>
              <Descriptions.Item label>
                {item.Name}
                {'       '}
                <input
                  disabled={this.isFormDisabled()}
                  id={`checkbox_${amenitieName}${item.Id}`}
                  type="checkbox"
                  onClick={() => this.handleAmenitieCheck(item.Id, amenitieType, amenitieName)}
                />
              </Descriptions.Item>
            </div>
          ))}
          <Button disabled={this.isFormDisabled()} onClick={() => this.deleteAmenitieNaive(false)}>DELETE</Button>
        </div>
      )}
    </div>
  )

  getRefByAmenitieType = (type) => {
    if (type === 0) {
      return this.amenitiesInputs.Pool;
    }

    if (type === 1) {
      return this.amenitiesInputs.Gym;
    }

    if (type === 2) {
      return this.amenitiesInputs.Spa;
    }

    if (type === 3) {
      return this.amenitiesInputs.Dining;
    }

    if (type === 4) {
      return this.amenitiesInputs.Business;
    }

    if (type === 5) {
      return this.amenitiesInputs.Events;
    }

    if (type === 6) {
      return this.amenitiesInputs.Other;
    }

    return null;
  };

  getUpgradeRefByAmenitieType = (type) => {
    if (type === 0) {
      return this.amenitiesUpgradeInputs.Pool;
    }

    if (type === 1) {
      return this.amenitiesUpgradeInputs.Gym;
    }

    if (type === 2) {
      return this.amenitiesUpgradeInputs.Spa;
    }

    if (type === 3) {
      return this.amenitiesUpgradeInputs.Dining;
    }

    if (type === 4) {
      return this.amenitiesUpgradeInputs.Business;
    }

    if (type === 5) {
      return this.amenitiesUpgradeInputs.Events;
    }

    if (type === 6) {
      return this.amenitiesUpgradeInputs.Other;
    }

    return null;
  };

  renderAmenitieOptions = type => (
    this.props.amenities.filter(amenitie => amenitie.AmentyTypeId === type).map(val => <Option value={val.Id} key={val.Id}>{val.Name}</Option>)
  );

  // ////////////////////////////////////////////////////////////////// RENDER AMENITIES
  // ////////////////////////////////////////////////////////////////// HANDLERS

  handleHotelAmenitieCheck = (checkboxId) => {
    const policies = this.getHotelAmenitieCheckResult(checkboxId);

    const result = { ...this.state.hotelAmenities, CheckboxCheck: policies };

    this.setState({ hotelAmenities: result });

    this.validateAmenities(result);
  };

  getHotelAmenitieCheckResult = (checkboxId) => {
    if (checkboxId === 'Pool') {
      return { ...this.state.hotelAmenities.CheckboxCheck, IsCheckedPool: document.getElementById(`checkbox_${checkboxId}`).checked };
    }
    if (checkboxId === 'Gym') {
      return { ...this.state.hotelAmenities.CheckboxCheck, IsCheckedGym: document.getElementById(`checkbox_${checkboxId}`).checked };
    }
    if (checkboxId === 'Spa') {
      return { ...this.state.hotelAmenities.CheckboxCheck, IsCheckedSpa: document.getElementById(`checkbox_${checkboxId}`).checked };
    }
    if (checkboxId === 'Dining') {
      return { ...this.state.hotelAmenities.CheckboxCheck, IsCheckedDining: document.getElementById(`checkbox_${checkboxId}`).checked };
    }
    if (checkboxId === 'Business') {
      return { ...this.state.hotelAmenities.CheckboxCheck, IsCheckedBusiness: document.getElementById(`checkbox_${checkboxId}`).checked };
    }
    if (checkboxId === 'Events') {
      return { ...this.state.hotelAmenities.CheckboxCheck, IsCheckedEvents: document.getElementById(`checkbox_${checkboxId}`).checked };
    }
    if (checkboxId === 'Other') {
      return { ...this.state.hotelAmenities.CheckboxCheck, IsCheckedOther: document.getElementById(`checkbox_${checkboxId}`).checked };
    }

    return { ...this.state.hotelAmenities };
  }

  handleAmenitieCheck = (id, type, amenitieName) => {
    const amenitie = this.state.amenities.filter(am => am.AmentyTypeId === type).map((item) => {
      if (item.Id === id) {
        return { ...item, IsChecked: document.getElementById(`checkbox_${amenitieName}${item.Id}`).checked };
      }
      return item;
    });

    this.setState({ amenities: amenitie });
  };

  // ////////////////////////////////////////////////////////////////// RENDER AMENITIES
  // ////////////////////////////////////////////////////////////////// FUNCTION

  addAmenitieNaive = async (amenitieType, IsAmenityValue) => {
    const newAmenitie = {
      AmentyTypeId: amenitieType,
      Name: IsAmenityValue ? this.getRefByAmenitieType(amenitieType).current.input.value : this.getUpgradeRefByAmenitieType(amenitieType).current.input.value,
      AmentyOrder: 1,
      IsActive: true,
      IsAmenty: IsAmenityValue,
      IsNew: true,
      ToDelete: false,
    };

    await this.props.actions.addAmenitieNaive(newAmenitie);

    this.setState({ amenities: this.props.amenities });
  }

  deleteAmenitieNaive = async (isAmenty) => {
    this.state.amenities.forEach((item) => {
      if (item.IsChecked && item.IsAmenty === isAmenty) {
        this.props.actions.deleteAmenitieNaive(item);
      }
    });

    this.setState({ amenities: this.props.amenities });
  }

  // //////////////////////////////////////////////////////////////////////////////////////
  // ////////////////////////////////////////////////////////////////// RENDER IMAGE

  renderImageZone = () => (
    <div>
      <div className="flex-column">
        <div id="error_Photos" className="flex-element error-validation">Please upload at least 6 photos!</div>
        <div className="flex-element">
          <Dropzone
            disabled={this.isFormDisabled()}
            name="images"
            value={this.state.uploadedFiles}
            onChange={
              (newFiles) => {
                this.setState({ uploadedFiles: newFiles });
                this.validatePhotosQuantity(newFiles);
              }
            }
          />
        </div>
      </div>
    </div>
  );

  render() {
    return (
      <div className="wrap">
        <div className="content">
          <div className="flex-container page">
            <div className="flex-element" style={{ height: '110%' }}>
              <div className="flex-container main" style={{ width: 863 }}>
                {this.renderMainHotel()}
                {this.renderDiscountPanel()}
              </div>
            </div>
            <div className="flex-element" style={{ width: 830 }}>
              {this.renderAddressCard()}
            </div>
            <div className="flex-element" style={{ paddingTop: '29px', width: 830 }}>
              {this.renderTerms()}
            </div>
            <div className="flex-element" style={{ paddingTop: '45px', width: 830 }}>
              {this.renderRecommendationPanel()}
            </div>
            <div className="flex-element" style={{ paddingTop: '45px', width: 830 }}>
              <div style={{ textAlign: 'left', paddingRight: '25px' }}>TripAdvisor Script</div>
              <Input placeholder="" style={{ height: 110 }} value={this.state.changedHotel.TripAdvisorScript} onChange={e => this.changeHotel(e.target.value, 'TripAdvisorScript')} />
            </div>
            <div className="flex-element" style={{ paddingTop: '60px', width: 830 }}>
              {this.renderAmenities()}
            </div>
            <div className="flex-element" style={{ paddingTop: '60px', width: 1055 }}>
              {this.renderImageZone()}
            </div>
            <div className="flex-container discount-code" style={{ paddingTop: '60px', width: 830 }}>
              <div className="flex-element">
                <Button disabled={this.isFormDisabled()} type="primary" style={{ marginTop: '15px' }} onClick={() => this.saveHotel()}>
                  {this.props.user.isSuperAdmin ? 'Save' : 'Send to verification'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  uploadedFiles: state.image.images,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    changePolicy,
    addHotelPolicyNaive,
    deleteHotelPolicyNaive,
    addHotelPolicy,
    deleteHotelPolicy,
    addAmenitieNaive,
    deleteAmenitieNaive,
    deleteAmenitie,
    getHotels,
    changeMarket,
    addAmenitie,
    changeHotel,
    addImage,
    deleteImage,
    updateImageOrder,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditHotelForm);
