import {
  REQUEST,
  SUCCESS,
  FAILURE,
  SEARCH_FETCH,
  SEARCH_REDEEM,
  SEARCH_EXCEL,
} from '@/redux/store/action-types';


const initialState = {
  bookings: [],
  total: 0,
  isSearchFetching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_FETCH[REQUEST]:
      return {
        ...state,
        isSearchFetching: true,
      };
    case SEARCH_FETCH[SUCCESS]:
      return {
        ...state,
        ...action.payload,
        isSearchFetching: false,
      };
    case SEARCH_FETCH[FAILURE]:
      return {
        ...state,
        ...action.payload,
        isSearchFetching: false,
      };
    case SEARCH_REDEEM[REQUEST]:
      return {
        ...state,
      };
    case SEARCH_REDEEM[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case SEARCH_REDEEM[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case SEARCH_EXCEL[REQUEST]:
      return {
        ...state,
      };
    case SEARCH_EXCEL[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case SEARCH_EXCEL[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
