import {
  REQUEST,
  SUCCESS,
  FAILURE,
  IMAGE_SAVE,
  GET_IMAGE,
  DELETE_IMAGE,
  PUT_IMAGE_ORDER,
} from '@/redux/store/action-types';
import axios from 'axios';
import action from '../action';

export const addImage = image => async (dispatch) => {
  dispatch(action(IMAGE_SAVE[REQUEST]));
  try {
    const response = await axios.get(image.Source, {
      responseType: 'arraybuffer',
    });

    const dataBuffer = Buffer.from(response.data, 'binary');

    const newImage = { ...image, Data: dataBuffer };

    const url = process.env.REACT_APP_CDN;
    // const url = 'http://localhost:43833';

    let endpoint = `${url}/Handler/SaveImage.ashx`;
    if (newImage.ProductId) {
      endpoint = `${url}/Handler/SaveProductImage.ashx`;
    }
    const responseOldPortal = await fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(newImage),
    });

    console.log(responseOldPortal);

    // await axios.post(`${process.env.REACT_APP_API_URL}/user/addImage`, { newImage });

    dispatch(action(IMAGE_SAVE[SUCCESS]));
  } catch (error) {
    dispatch(action(IMAGE_SAVE[FAILURE], { error }));
  }
};

export const getHotelImage = hotelId => async (dispatch) => {
  dispatch(action(GET_IMAGE[REQUEST]));
  try {
    const images = await axios.get(`${process.env.REACT_APP_API_URL}/user/getImages/${hotelId}`);

    const url = process.env.REACT_APP_CDN;
    // const url = 'http://localhost:43833';

    const imagesResult = images.data.images.map((item) => {
      const newItem = { ...item, Source: `${url}${item.Url}` };
      return newItem;
    });

    const result = {
      data: imagesResult,
    };

    localStorage.setItem('images', JSON.stringify(imagesResult));
    dispatch(action(GET_IMAGE[SUCCESS], {
      images: result.data.imagesResult,
    }));
  } catch (error) {
    dispatch(action(GET_IMAGE[FAILURE], { error }));
  }
};

export const updateImageOrder = (imageId, order, productId = undefined) => async (dispatch) => {
  dispatch(action(PUT_IMAGE_ORDER[REQUEST]));
  try {
    let endpoint = `${process.env.REACT_APP_API_URL}/user/updateImageOrder/${imageId}`;
    if (productId) {
      endpoint = `${process.env.REACT_APP_API_URL}/user/updateProductImageOrder/${imageId}`;
    }
    await axios.post(endpoint, { order });
    dispatch(action(PUT_IMAGE_ORDER[SUCCESS]));
  } catch (error) {
    dispatch(action(PUT_IMAGE_ORDER[FAILURE], { error }));
  }
};

export const deleteImage = (imageId, productId = undefined) => async (dispatch) => {
  dispatch(action(DELETE_IMAGE[REQUEST]));
  try {
    const url = process.env.REACT_APP_CDN;
    // const url = 'http://localhost:43833';

    let endpoint = `${url}/Handler/DeleteImage.ashx`;
    if (productId) {
      endpoint = `${url}/Handler/DeleteProductImage.ashx`;
    }
    const responseOldPortal = await fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(imageId),
    });

    console.log(responseOldPortal);

    // await axios.delete(`${process.env.REACT_APP_API_URL}/user/deleteImage/${imageId}`);

    dispatch(action(DELETE_IMAGE[SUCCESS]));
  } catch (error) {
    dispatch(action(DELETE_IMAGE[FAILURE], { error }));
  }
};

export const hardDeleteImage = imageId => async (dispatch) => {
  dispatch(action(DELETE_IMAGE[REQUEST]));
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}/user/deleteImage/${imageId}`);

    dispatch(action(DELETE_IMAGE[SUCCESS]));
  } catch (error) {
    dispatch(action(DELETE_IMAGE[FAILURE], { error }));
  }
};
