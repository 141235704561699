/* eslint-disable max-len */
import React from 'react';
import moment from 'moment';
import ProductCalendar from '@/components/ProductCalendar';
import BookingsCalendar from '@/components/BookingsCalendar';
import {
  PageHeader,
  InputNumber,
  Button,
  Radio,
  Row,
  Col,
  DatePicker,
  Checkbox,
  Spin,
} from 'antd';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  chageDateRange,
  fetchProducts,
  fetchCalendarDates,
  chageMonth,
  cancelSelect,
  saveCalendarProducts,
} from '@/redux/actions';
import './calendar-page.css';
import Notification from '@/components/Notification';

const routes = [
  {
    path: 'dashboard',
    breadcrumbName: 'Dashboard',
  },
  {
    path: 'calendar',
    breadcrumbName: 'Calendar',
  },
];

class CalendarPage extends React.Component {
  constructor(props) {
    super(props);
    const { selectedHotel } = props;
    this.state = {
      selectedProduct: selectedHotel && selectedHotel.products && selectedHotel.products.length > 0 ? selectedHotel.products[0].id : null,
      currentProduct: null,
    };
  }

  async componentDidMount() {
    await this.fetchCalendar();
    this.initProductsList();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.calendar.currentMonth !== this.props.calendar.currentMonth) {
      this.fetchCalendar();
    }
  }

  getProductPrice = (product, priceDay) => {
    const day = moment(priceDay);
    const defaultPrice = product.defaultPrices.find(val => day.isBetween(moment(val.dateStart), moment(val.dateEnd)));
    const customPrice = product.customDates.customPrices.find(val => moment.utc(val.date).isSame(day, 'day'));
    if (customPrice) {
      return {
        price: customPrice.price,
        tickets: customPrice.capacity,
      };
    }
    const info = defaultPrice[day.day()];
    return {
      price: info.price,
      tickets: info.capacity,
    };
  }

  initProductsList = () => {
    const { products } = this.props.calendar;
    const { selectedBeginDate } = this.props.calendar;

    if (!selectedBeginDate && products[0]) {
      const selectedProduct = products[0].id;
      const changedProducts = products.map(val => ({
        id: val.id,
        price: 0,
        tickets: 0,
        enabled: true,
      }));
      this.setState({ selectedProduct, changedProducts });
    } else {
      const changedProducts = products.reduce(((acc, val) => {
        const productPrice = this.getProductPrice(val, selectedBeginDate);
        return [
          ...acc,
          {
            id: val.id,
            price: productPrice.price,
            tickets: productPrice.tickets,
            enabled: true,
          },
        ];
      }), []);
      this.setState({ changedProducts });
    }
  }

  enableEdit = async (productId, checked) => {
    const changedProducts = this.state.changedProducts.map((val) => {
      if (val.id === productId) {
        return {
          ...val,
          enabled: checked,
        };
      }
      return val;
    });
    await this.setState({ changedProducts });
  }

  fetchCalendar = async () => {
    const monthFormat = moment.utc(this.props.calendar.currentMonth).format('YYYY-MM-DD');
    await this.props.actions.fetchCalendarDates({ date: monthFormat, hotelId: this.props.selectedHotel.id });
    const { selectedProduct } = this.state;
    const currentProduct = this.props.calendar.products.find(val => val.id === selectedProduct);
    this.setState({ currentProduct });
  }

  // TODO : make common util
  parseProductType = (productType) => {
    switch (productType) {
      case 0:
        return 'Day Pass';
      case 1:
        return 'Cabana';
      case 2:
        return 'Daybeds';
      case 7:
        return 'Beach Day Pass';
      case 8:
        return 'Pool and Beach Day Pass';
      default:
        return 'Day Pass';
    }
  }

  handleSaveProducts = async () => {
    const { selectedBeginDate, selectedEndDate, products } = this.props.calendar;
    const changedProducts = this.state.changedProducts.filter(val => val.enabled);

    if (changedProducts.length === 0) {
      Notification.openNotification({
        message: 'Error.',
        description: 'Select at least one product',
        type: 'error',
      });
      return;
    }

    if (!selectedBeginDate || !selectedEndDate) {
      Notification.openNotification({
        message: 'Error.',
        description: 'Select begin and end range',
        type: 'error',
      });
      return;
    }

    const bookings = [];

    for (let i = 0; i < products.length; i += 1) {
      const newValues = changedProducts.find(val => val.id === products[i].id);

      const foundBookings = products[i].fullBookings.filter((val) => {
        const checkinDate = moment.utc(val.CheckinDate, 'YYYY-MM-DD');
        const begin = moment.utc(selectedBeginDate);
        const end = moment.utc(selectedEndDate);
        return newValues
        && newValues.tickets < val.Quantity
        && checkinDate.isSameOrAfter(begin)
        && checkinDate.isSameOrBefore(end);
      });

      bookings.push(...foundBookings);
    }

    if (bookings.length > 0) {
      Notification.openNotification({
        message: 'Error.',
        description: 'Cannot block dates which have bookings. Please decrease ticket number to already sold amount to close off additional bookings and contact our customer service if you\'d like us o further cancel those that have already been booked. ',
        type: 'error',
      });
      return;
    }

    await this.props.actions.saveCalendarProducts(selectedBeginDate, selectedEndDate, changedProducts);
    await this.fetchCalendar();
    this.initProductsList();
  }

  handleProductChange = (e) => {
    const currentProduct = this.props.calendar.products.find(val => val.id === e.target.value);
    this.setState({ currentProduct, selectedProduct: currentProduct.id });
  }

  handleProductPropertiesChange = (e, type, productId) => {
    this.setState((prevState) => {
      const changedProducts = prevState.changedProducts.filter(val => val.id !== productId);
      let newProduct = prevState.changedProducts.find(val => val.id === productId);
      newProduct = {
        ...newProduct,
        [type]: e,
      };

      return {
        changedProducts: [
          ...changedProducts,
          newProduct,
        ],
      };
    });
  }

  handleBeginChanged = () => {
    this.initProductsList();
  }

  dateBeginChanged = (date) => {
    if (date) {
      this.props.actions.chageDateRange({
        selectedBeginDate: date.format('YYYY-MM-DD'),
      });
    }
  }

  dateEndChanged = (date) => {
    this.props.actions.chageDateRange({
      selectedEndDate: date.format('YYYY-MM-DD'),
    });
  }

  disabledBeginDate = (current, end) => current && (current < moment().endOf('day') || current > moment(end, 'YYYY-MM-DD').endOf('day'));

  disabledEndDate = (current, begin) => current && (current < moment().endOf('day') || current <= moment(begin, 'YYYY-MM-DD').startOf('day'));

  renderProductSelect = () => this.props.selectedHotel.products.map(val => <Radio.Button key={val.id} value={val.id}>{val.name}</Radio.Button>);

  renderProduct = () => this.props.selectedHotel.products.map((val) => {
    if (!this.state.changedProducts) return '';

    const productPrice = this.state.changedProducts.find(pr => pr.id === val.id);
    if (!productPrice) return '';
    return (

      <div className="product-item" key={val.id}>
        <Row type="flex" justify="space-around">
          <Col span={10}>
            <div className="product-item-description">
              <span className="product-type">{this.parseProductType(val.type)}</span>
              <span className="product-name">{val.name}</span>
            </div>

          </Col>

          {/* console.log(productPrice.edit) */}
          <Col span={14} key={val.id}>
            <Row type="flex" justify="start">
              <Col span={12}>
                <Checkbox
                  checked={productPrice.enabled}
                  onChange={value => this.enableEdit(val.id, value.target.checked)}
                >
                Edit
                </Checkbox>
              </Col>
            </Row>
            <Row type="flex" justify="start">
              <Col span={12}>
                <span>Price: </span>
                <InputNumber
                  min={0}
                  step={0.05}
                  defaultValue={0}
                  disabled={!productPrice.enabled}
                  value={productPrice.price}
                  formatter={value => `$ ${value.toString().replace(' ', '')}`}
                  parser={value => value.replace('$', '')}
                  onChange={e => this.handleProductPropertiesChange(e, 'price', val.id)}
                />
              </Col>
              <Col span={12}>
                <span>Tickets: </span>
                <InputNumber
                  min={0}
                  defaultValue={0}
                  disabled={!productPrice.enabled}
                  value={productPrice.tickets}
                  onChange={e => this.handleProductPropertiesChange(e, 'tickets', val.id)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

    );
  })

  render() {
    const beginRange = this.props.calendar.selectedBeginDate;
    const endRange = this.props.calendar.selectedEndDate;

    const tableBookings = beginRange && endRange
      ? this.state.currentProduct.fullBookings.filter((val) => {
        const checkinDate = moment.utc(val.CheckinDate, 'YYYY-MM-DD');
        const begin = moment.utc(beginRange);
        const end = moment.utc(endRange);

        return checkinDate.isSameOrAfter(begin) && checkinDate.isSameOrBefore(end);
      }) : [];
    const dateFormatList = ['MM/DD/YYYY', 'M/D/YY'];
    return (
      <PageHeader title="Product Calendar" breadcrumb={{ routes }}>

        <div className="wrap">
          <Spin spinning={this.props.calendar.isLoading}>
            <div className="calendar-page-content">

              <div className="calendar-component">
                <div className="calendar-product-select">
                  <Radio.Group value={this.state.selectedProduct} buttonStyle="solid" onChange={e => this.handleProductChange(e)}>
                    {this.renderProductSelect()}
                  </Radio.Group>
                </div>
                { this.state.currentProduct ? (
                  <ProductCalendar
                    product={this.state.currentProduct}
                    currentMonth={this.props.calendar.currentMonth}
                    beginRange={beginRange}
                    endRange={endRange}
                    chageDateRange={this.props.actions.chageDateRange}
                    chageMonth={this.props.actions.chageMonth}
                    beginChanged={this.handleBeginChanged}
                  />
                ) : ''
              }
                <div className="calendar-legend">
                  <div className="legend-item">
                    <div className="legend-blocked" />
                    <span>Blocked</span>
                  </div>

                  <div className="legend-item">
                    <div className="legend-open" />
                    <span>Open</span>
                  </div>

                  <div className="legend-item">
                    <div className="legend-allocation" />
                    <span>Ticket allocation</span>
                  </div>

                  <div className="legend-item">
                    <div className="legend-bookings" />
                    <span>Bookings</span>
                  </div>

                  <div className="legend-item">
                    <div className="legend-redemptions" />
                    <span>Redemptions</span>
                  </div>

                </div>
              </div>

              <div className="calendar-form">
                <div className="date-range">
                  <DatePicker
                    defaultValue={null}
                    value={beginRange ? moment(beginRange) : null}
                    format={dateFormatList}
                    onChange={this.dateBeginChanged}
                    allowClear={false}
                    disabledDate={val => this.disabledBeginDate(val, endRange)}
                  />

                  <span> - </span>
                  <DatePicker
                    defaultValue={null}
                    value={endRange ? moment(endRange) : null}
                    format={dateFormatList}
                    onChange={this.dateEndChanged}
                    allowClear={false}
                    disabledDate={val => this.disabledEndDate(val, beginRange)}
                  />

                </div>
                <h3>Products : </h3>
                <div className="products-list">
                  {this.props.selectedHotel.products ? this.renderProduct() : ''}
                  <div className="products-save">
                    <Button onClick={this.props.actions.cancelSelect}>Cancel</Button>
                    <Button onClick={this.handleSaveProducts} loading={this.props.calendar.isSaving} type="primary">{this.props.calendar.isSaving ? 'Saving...' : 'Save'}</Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="calendar-page-footer">
              <BookingsCalendar bookings={tableBookings} />
            </div>
          </Spin>
        </div>
      </PageHeader>
    );
  }
}


const mapStateToProps = state => ({
  calendar: state.calendar,
  selectedHotel: state.user.selectedHotel,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    chageDateRange,
    fetchProducts,
    fetchCalendarDates,
    chageMonth,
    cancelSelect,
    saveCalendarProducts,
  },
  dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage);
