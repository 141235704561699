import {
  REQUEST,
  SUCCESS,
  FAILURE,
  PRODUCTS_FETCH,
  PRODUCT_DELETE,
} from '@/redux/store/action-types';


const initialState = {
  products: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PRODUCTS_FETCH[REQUEST]:
      return {
        ...state,
      };
    case PRODUCTS_FETCH[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case PRODUCTS_FETCH[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case PRODUCT_DELETE[REQUEST]:
      return {
        ...state,
      };
    case PRODUCT_DELETE[SUCCESS]:
      return {
        ...state.products.filter(p => p.id !== action.payload.id),
      };
    case PRODUCT_DELETE[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
