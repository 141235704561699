import {
  REQUEST,
  SUCCESS,
  FAILURE,
  CALENDAR_DATE_RANGE,
  CALENDAR_PRODUCT,
  CALENDAR_SET_MONTH,
  CALENDAR_DATE_RANGE_CANCEL,
  CALENDAR_PRODUCT_SAVE,
} from '@/redux/store/action-types';
import action from '../action';
import axios from 'axios';
import Notification from '@/components/Notification';

export const chageDateRange = daterange => (dispatch) => {
  dispatch(action(CALENDAR_DATE_RANGE, { ...daterange }));
};

export const cancelSelect = () => (dispatch) => {
  const parsedDateRange = {
    selectedBeginDate: null,
    selectedEndDate: null,
  };
  dispatch(action(CALENDAR_DATE_RANGE_CANCEL, { ...parsedDateRange }));
};

export const chageMonth = currentMonth => action(CALENDAR_SET_MONTH, { currentMonth });


export const fetchCalendarDates = calendarProps => async (dispatch) => {
  dispatch(action(CALENDAR_PRODUCT[REQUEST]));
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/calendar`, { params: calendarProps });
    dispatch(action(CALENDAR_PRODUCT[SUCCESS], {
      products: [...res.data],
    }));
  } catch (error) {
    dispatch(action(CALENDAR_PRODUCT[FAILURE], { error }));
  }
};

export const saveCalendarProducts = (beginDate, endDate, props) => async (dispatch) => {
  dispatch(action(CALENDAR_PRODUCT_SAVE[REQUEST]));
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/calendar/update`, { beginDate, endDate, products: props });
    Notification.openNotification({
      message: 'Data is saved.',
      description: 'Your changes will take immediate effect.',
      type: 'success',
    });
    dispatch(action(CALENDAR_PRODUCT_SAVE[SUCCESS]));
  } catch (error) {
    Notification.openNotification({
      message: 'Something happened',
      description: 'We are working on fixing problems',
      type: 'error',
    });
    dispatch(action(CALENDAR_PRODUCT_SAVE[FAILURE], { error }));
  }
};
