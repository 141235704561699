/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchProducts, saveProduct } from '@/redux/actions';
import debounce from 'lodash.debounce';

import {
  PageHeader, InputNumber, Card, Button,
} from 'antd';


import styles from './styles.module.css';

const { Meta } = Card;

const routes = [
  {
    path: 'dashboard',
    breadcrumbName: 'Dashboard',
  },
  {
    path: 'inventory',
    breadcrumbName: 'Inventory',
  },
];

class InventoryPage extends React.Component {
  debouncedChanges = debounce((newChanges) => {
    this.setState({ changedProducts: newChanges });
  }, 300);


  async componentDidMount() {
    await this.fetchProduct(this.props.selectedHotel.id);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedHotel.id !== this.props.selectedHotel.id) {
      await this.fetchProduct(this.props.selectedHotel.id);
    }
  }

  fetchProduct = async (hotelId) => {
    await this.props.actions.fetchProducts(hotelId);
    this.setState({
      changedProducts: [...this.props.inventory.products],
    });
  }


  changeProduct = (value, productIndex, fieldName) => {
    const newChanges = [...this.state.changedProducts];
    newChanges[productIndex][fieldName] = value;
    this.debouncedChanges(newChanges);
  }

  saveProduct = async (productIndex) => {
    const sendProduct = this.state.changedProducts[productIndex];
    await this.props.actions.saveProduct(sendProduct);
  }

  parseProductType = (productType) => {
    switch (productType) {
      case 0:
        return 'Day Pass';
      case 1:
        return 'Cabana';
      case 2:
        return 'Daybeds';
      case 7:
        return 'Beach Day Pass';
      case 8:
        return 'Pool and Beach Day Pass';
      default:
        return 'Day Pass';
    }
  }

  renderProductTable = (product, index) => (
    <div className={styles.productWrapper} key={product.id}>
      <Card
        style={{ width: 240 }}
        cover={<img alt="example" src={`${process.env.REACT_APP_CDN}${product.coverUrl}`} />}
      >
        <Meta title={product.productName} description={this.parseProductType(product.productType)} />
      </Card>
      <div className={styles.productPriceTable}>
        <table className={styles.productTable}>
          <thead>
            <tr>
              <th style={{ textAlign: 'center', backgroundColor: '#DDDDDD' }}>Sun</th>
              <th style={{ textAlign: 'center', backgroundColor: '#DDDDDD' }}>Mon</th>
              <th style={{ textAlign: 'center', backgroundColor: '#DDDDDD' }}>Tue</th>
              <th style={{ textAlign: 'center', backgroundColor: '#DDDDDD' }}>Wed</th>
              <th style={{ textAlign: 'center', backgroundColor: '#DDDDDD' }}>Thu</th>
              <th style={{ textAlign: 'center', backgroundColor: '#DDDDDD' }}>Fri</th>
              <th style={{ textAlign: 'center', backgroundColor: '#DDDDDD' }}>Sat</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="7">Price: </td>
            </tr>
            <tr>
              <td><InputNumber min={0} step={0.50} defaultValue={product.priceSun} formatter={value => `$ ${value.replace(' ', '')}`} parser={value => value.replace('$', '')} onChange={e => this.changeProduct(e, index, 'priceSun')} /></td>
              <td><InputNumber min={0} step={0.05} defaultValue={product.priceMon} formatter={value => `$ ${value.replace(' ', '')}`} parser={value => value.replace('$', '')} onChange={e => this.changeProduct(e, index, 'priceMon')} /></td>
              <td><InputNumber min={0} step={0.05} defaultValue={product.priceTue} formatter={value => `$ ${value.replace(' ', '')}`} parser={value => value.replace('$', '')} onChange={e => this.changeProduct(e, index, 'priceTue')} /></td>
              <td><InputNumber min={0} step={0.05} defaultValue={product.priceWed} formatter={value => `$ ${value.replace(' ', '')}`} parser={value => value.replace('$', '')} onChange={e => this.changeProduct(e, index, 'priceWed')} /></td>
              <td><InputNumber min={0} step={0.05} defaultValue={product.priceThu} formatter={value => `$ ${value.replace(' ', '')}`} parser={value => value.replace('$', '')} onChange={e => this.changeProduct(e, index, 'priceThu')} /></td>
              <td><InputNumber min={0} step={0.50} defaultValue={product.priceFri} formatter={value => `$ ${value.replace(' ', '')}`} parser={value => value.replace('$', '')} onChange={e => this.changeProduct(e, index, 'priceFri')} /></td>
              <td><InputNumber min={0} step={0.50} defaultValue={product.priceSat} formatter={value => `$ ${value.replace(' ', '')}`} parser={value => value.replace('$', '')} onChange={e => this.changeProduct(e, index, 'priceSat')} /></td>
            </tr>
            <tr>
              <td colSpan="7">Tickets:</td>
            </tr>
            <tr>
              <td><InputNumber min={0} defaultValue={product.capSun} onChange={e => this.changeProduct(e, index, 'capSun')} /></td>
              <td><InputNumber min={0} defaultValue={product.capMon} onChange={e => this.changeProduct(e, index, 'capMon')} /></td>
              <td><InputNumber min={0} defaultValue={product.capTue} onChange={e => this.changeProduct(e, index, 'capTue')} /></td>
              <td><InputNumber min={0} defaultValue={product.capWed} onChange={e => this.changeProduct(e, index, 'capWed')} /></td>
              <td><InputNumber min={0} defaultValue={product.capThu} onChange={e => this.changeProduct(e, index, 'capThu')} /></td>
              <td><InputNumber min={0} defaultValue={product.capFri} onChange={e => this.changeProduct(e, index, 'capFri')} /></td>
              <td><InputNumber min={0} defaultValue={product.capSat} onChange={e => this.changeProduct(e, index, 'capSat')} /></td>
            </tr>
          </tbody>
        </table>
        <div className={styles.saveButton}>
          <Button type="primary" onClick={() => this.saveProduct(index)}>Save</Button>
        </div>

      </div>
    </div>
  );

  render() {
    if (!this.props.inventory.products.length === 0) return '';
    return (
      <PageHeader title="Inventory & Pricing" breadcrumb={{ routes }}>
        <div className="wrap">
          <div className="content">
            {this.props.inventory.products.map((val, index) => this.renderProductTable(val, index))}
          </div>
        </div>

      </PageHeader>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  selectedHotel: state.user.selectedHotel,
  inventory: state.inventory,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    fetchProducts,
    saveProduct,
  },
  dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryPage);
