import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';

import reducer from '@/redux/reducers';

let composeEnhancers = compose;
if (process.browser && process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line no-underscore-dangle
  composeEnhancers = typeof window === 'undefined' ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

export default (initialState) => {
  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk)),
  );

  return store;
};
