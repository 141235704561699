/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable react/no-unused-state */
import React from 'react';
import moment from 'moment-timezone';
import currency from 'currency.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CheckinDatePicker from '@/components/CheckinDatePicker';
import RefundComponent from '@/components/RefundComponent';
import {
  fetchBooking,
  resendReceipt,
  redeemBooking,
  changeCheckinDate,
} from '@/redux/actions';

import {
  Divider,
  Typography,
  Tag,
  Modal,
  Select,
  Button,
  Popconfirm,
  Descriptions,
  Radio,
  Input,
  Row,
  Col,
} from 'antd';
import * as utils from '@/utils/helper';
import './booking-page.css';

const { Option } = Select;

const { Title } = Typography;

const formatDate = (date, timeZoneId) => (moment(date).isValid() && timeZoneId
  ? moment(date).tz(timeZoneId).format('h:mm a z MMM DD, YYYY [(Hotel Timezone)]')
  : 'Invalid Date');

class BookingPage extends React.Component {
  state = {
    bookingText: '',
    refundModal: {
      refundType: 0,
    },
    modalRefundVisible: false,
  }

  async componentDidMount() {
    const { bookingId } = this.props.match.params;
    await this.props.actions.fetchBooking(bookingId);
  }

  resendReceipt = (type) => {
    const { bookingId } = this.props.match.params;
    this.props.actions.resendReceipt(bookingId, type);
  }

  showCheckinConfirmation = (newValue) => {
    const contentCheckin = this.modalConfirmCheckinContent(this.props.common.CheckinDate, newValue);
    this.setState({ contentCheckin, modalCheckinVisible: true, newCheckinDate: newValue });
  }

  displayRefundStatus = (payment) => {
    if (payment.RefundStatus === 2) {
      return (
        <React.Fragment>
          {`Full refund $ ${this.centsToDollar(payment.RefundAmount)}`}
        </React.Fragment>
      );
    }
    if (payment.RefundStatus === 1) {
      return (
        <React.Fragment>
          {`Partial refund $ ${this.centsToDollar(payment.RefundAmount)}` }
        </React.Fragment>
      );
    }
    return <React.Fragment />;
  }

  centsToDollar = val => (val / 100).toFixed(2)

  formatPrice = val => currency(val, { formatWithSymbol: true }).format();


  // eslint-disable-next-line react/jsx-one-expression-per-line
  renderRow = (header, data) => <tr><td>{header}:</td><td>{data}</td></tr>

  renderTax = () => {
    const { common } = this.props;
    if (common.TaxPrice) {
      return (
        <tr>
          <td>Tax</td>
          <td colSpan="3" />
          <td>{`$ ${common.TaxPrice}`}</td>
        </tr>
      );
    }
    return <React.Fragment />;
  }

  renderServiceFee = () => {
    // TODO SERVICE Fee hardcoded 1
    // console.log(this.props.payments);
    const servicePayment = this.props.payments.find(val => val.PaymentMethod === 'SERVICE');
    if (servicePayment) {
      const amount = (servicePayment.Amount / 100).toFixed(2);
      return (
        <tr>
          <td>Service Fee</td>
          <td colSpan="3" />
          <td>{this.formatPrice(amount)}</td>
        </tr>
      );
    }

    return (
      <tr>
        <td>Service Fee</td>
        <td colSpan="3" />
        <td>$ 0.00</td>
      </tr>
    );
  }

  renderDiscount = () => {
    const { common } = this.props;
    if (common.PercentOff) {
      // TODO : change to normal case (PromoType)
      if (common.PromoType === 0) {
        const totalPrice = common.Quantity * common.MerchantPrice;
        const percent = totalPrice * (common.PercentOff / 100);
        return (
          <tr>
            <td>Discount</td>
            <td style={{ textAlign: 'left' }}>
              {`${common.Code} ${common.DiscountName}`}
            </td>
            <td />
            <td>
              {`$ ${totalPrice} x ${common.PercentOff} %`}
            </td>
            <td>{`- $ ${percent}`}</td>
          </tr>
        );
      }

      if (common.PromoType === 1) {
        return (
          <tr>
            <td>Discount</td>
            <td style={{ textAlign: 'left' }}>
              {`${common.Code} ${common.DiscountName}`}
            </td>
            <td />
            <td />
            <td>{`- $ ${common.PercentOff}`}</td>
          </tr>
        );
      }
    }
    return <React.Fragment />;
  }

  renderPayments = () => {
    // WOW ! Slowly! 2 arrays ?
    /* const statusList = [
      'warning_needs_response',
      'warning_under_review',
      'warning_closed',
      'needs_response',
      'under_review',
      'charge_refunded',
      'won',
      'lost',
    ]; */

    const realList = [
      'Disputed - Needs Response',
      'Disputed - Under Review',
      'Disputed - Closed',
      'Disputed - Needs Response',
      'Disputed - Under Review',
      'Disputed - Charge Refunded',
      'Disputed - Won',
      'Disputed - Lost',
    ];
    const { payments } = this.props;

    return payments.filter(val => val.PaymentMethod !== 'SERVICE').map(val => (
      <tr key={val.PaymentId}>
        <td>{val.PaymentMethod} {val.Card4Num}</td>
        <td>{val.DisputeStatus !== 0 ? realList[val.DisputeStatus - 1] : ''}</td>
        <td>{this.displayRefundStatus(val)} </td>
        <td>{val.TransactionId}</td>
        <td>{`$ ${this.centsToDollar(val.Amount)}`}</td>
        <td>{val.Description ? val.Description : 'Primary'}</td>
      </tr>
    ));
  }

  modalConfirmCheckinContent = (oldValue, newValue) => (
    <React.Fragment>
      <Row type="flex" justify="space-around" align="middle"> <h4>Are you sure you want to change check-in date</h4></Row>
      <br />
      <Row type="flex" justify="space-around" align="middle">From : {moment.utc(oldValue).format('MM/DD/YYYY')}</Row>
      <Row type="flex" justify="space-around" align="middle">To : {moment.utc(newValue).format('MM/DD/YYYY')}</Row>
    </React.Fragment>
  )

  modalContent = booking => (
    <React.Fragment>
      <Descriptions
        border
        column={{
          xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1,
        }}
      >
        <Descriptions.Item label="Name">{`${this.props.customer.FirstName} ${this.props.customer.LastName}`}</Descriptions.Item>
        <Descriptions.Item label="Check-In Date">{moment.utc(booking.CheckinDate).format('MM/DD/YYYY')}</Descriptions.Item>
        <Descriptions.Item label="Tickets">{booking.Quantity}</Descriptions.Item>
        <Descriptions.Item label="Each Ticket">{`${booking.MaxGuest} guest(s)`}</Descriptions.Item>
        <Descriptions.Item label="Booking Code">{booking.BookingCode}</Descriptions.Item>
      </Descriptions>
    </React.Fragment>
  )

  handleBookingRedeem = async (booking) => {
    const content = this.modalContent(booking);
    this.setState({ content, modalVisible: true, contentId: booking.BookingId });
  };

  handleBookingRefund = async () => {
    this.setState({ modalRefundVisible: true });
  };

  modalOk = async () => {
    await this.props.actions.redeemBooking(this.props.common.BookingId);
    await this.props.actions.fetchBooking(this.props.common.BookingId);
    this.setState({ modalVisible: false });
  }

  modalCancel = () => {
    this.setState({ modalVisible: false });
  }

  modalCheckinOk = async () => {
    const { bookingId } = this.props.match.params;

    await this.props.actions.changeCheckinDate(this.props.common.BookingId, this.state.newCheckinDate);
    await this.props.actions.fetchBooking(bookingId);

    this.setState({ modalCheckinVisible: false });
  }

  modalCheckinCancel = () => {
    this.setState({ modalCheckinVisible: false });
  }

  modalRefundOk = async () => {
    const { bookingId } = this.props.match.params;
    await this.props.actions.fetchBooking(bookingId);
    this.setState({ modalRefundVisible: false });
  }

  modalRefundCancel = () => {
    this.setState({ modalRefundVisible: false });
  }

  render() {
    const { customer, common } = this.props;
    if (!this.props.customer) return '';
    const { content, contentCheckin } = this.state;
    return (
      <React.Fragment>
        <Modal
          title="Ticket Validation"
          centered
          visible={this.state.modalVisible}
          onOk={() => this.modalOk()}
          onCancel={() => this.modalCancel()}
          okText="Redeem"
          cancelText="Cancel"
        >
          {content}
        </Modal>

        <Modal
          title="Change Check-in Date"
          centered
          visible={this.state.modalCheckinVisible}
          onOk={() => this.modalCheckinOk()}
          onCancel={() => this.modalCheckinCancel()}
          okText="Yes, change it!"
          cancelText="Cancel"
        >
          {contentCheckin}
        </Modal>

        <Modal
          title="Refund booking"
          centered
          visible={this.state.modalRefundVisible}
          onOk={() => this.modalRefundOk()}
          onCancel={() => this.modalRefundCancel()}
          footer={null}
        >
          <RefundComponent
            onOk={() => this.modalRefundOk()}
            onCancel={() => this.modalRefundCancel()}
          />
        </Modal>

        <Title level={4}>Booking Details</Title>
        <Divider orientation="left"> Customer </Divider>
        <div className="info-row">
          <table className="info-table col1">
            <tbody>
              {this.renderRow('ID', customer.CustomerId)}
              {this.renderRow('Name', `${customer.FirstName} ${customer.LastName}`)}
              {this.renderRow('Email', customer.EmailAddress)}
              {this.renderRow('Phone', customer.Phone)}
              {this.renderRow('Market', customer.LocationName || 'Los Angeles')}
            </tbody>
          </table>
          <table className="info-table col-2">
            <tbody>
              {this.renderRow('Orders', customer.TotalOrders)}
              {this.renderRow('Lifetime Value', this.formatPrice(customer.TotalPaid))}
              {this.renderRow('Refferal Code', customer.ReferralCode)}
              {this.renderRow('Credit Balance', this.formatPrice(customer.CreditBalance))}
            </tbody>
          </table>
          <table className="info-table col-3">
            <tbody>
              {this.renderRow('Subscription Plan', customer.SubscriptionName)}
              {this.renderRow('Subscription ID', customer.SubscriptionId)}
              {this.renderRow('Purchased', '')}
              {this.renderRow('Billing Cycle', '')}
              {this.renderRow('Status', customer.SubscriptionStatus ? 'Not Active' : 'Active')}
            </tbody>
          </table>
        </div>

        <div className="two-columns">
          <div className="left">
            <Divider orientation="left">Order</Divider>
            <table className="info-table col1">
              <tbody>
                {this.renderRow('Booking Code', common.BookingCode)}
                {this.renderRow('Booking ID', common.BookingId)}
                {this.renderRow('Purchase Date', formatDate(common.BookedDate, common.TimeZoneId))}
                {this.renderRow('Payout Status', common.PayoutId ? <Tag color="green">Paid</Tag> : <Tag color="red">Not paid</Tag>)}
              </tbody>
            </table>
          </div>
          <div className="right">
            <Divider orientation="left">Check-in</Divider>
            <table className="info-table col1">
              <tbody>
                {this.renderRow('Check-in Date',
                  <CheckinDatePicker
                    price={common.MerchantPrice}
                    quantity={common.Quantity}
                    calendar={this.props.calendar}
                    value={moment(common.CheckinDate).isValid() ? moment(common.CheckinDate).tz(common.TimeZoneId) : moment()}
                    valueText={moment(common.CheckinDate).isValid() ? formatDate(common.CheckinDate, common.TimeZoneId) : ''}
                    handleChange={this.showCheckinConfirmation}
                    timeZone={common.TimeZoneId}
                  />)}
                {this.renderRow('Redeemed', common.RedeemedDate ? formatDate(common.RedeemedDate, common.TimeZoneId) : '')}
                {this.renderRow('Expires', formatDate(common.ExpiredDate, common.TimeZoneId))}
                {this.renderRow('Status', utils.statusText(common.PassStatus))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="product-row">
          <table className="product-table">
            <tbody>
              <tr>
                <th>Product</th>
                <th />
                <th style={{ width: '10%' }}>Qty</th>
                <th style={{ width: '20%' }}>MSRP</th>
                <th style={{ width: '10%' }}>Total</th>
              </tr>
              <tr style={{ borderBottom: '1px solid #e8e8e8' }}>
                <td colSpan="2">{`${common.ProductName} - ${common.HotelName}`}</td>
                <td>{common.Quantity}</td>
                <td>{this.formatPrice(common.MerchantPrice)}</td>
                <td>{this.formatPrice(common.MerchantPrice * common.Quantity)}</td>
              </tr>
              {this.renderDiscount()}
              {this.renderTax()}
              {this.renderServiceFee()}
              <tr style={{ borderTop: '1px solid #e8e8e8' }}>
                <td>Total Paid</td>
                <td colSpan="3" />
                <td>{this.formatPrice(common.TotalPrice)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Divider orientation="left"> Payment </Divider>
        <div className="payment-row">
          <table className="payment-table">
            <tbody>
              <tr>
                <th>Paid with</th>
                <th>Dispute Status</th>
                <th>Refund Status</th>
                <th>Transaction Id</th>
                <th>Amount</th>
                <th>Description</th>
              </tr>
              {this.renderPayments()}
            </tbody>
          </table>
        </div>
        <Divider />
        <div className="booking-actions">
          <div className="left-action">
            <Popconfirm
              title="Are you sure you want to send a copy of booking receipt to this guest ?"
              onConfirm={() => this.resendReceipt(0)}
              onCancel={() => this.resendReceipt(1)}
              okText="Yes, send it!"
              cancelText="Cancel"
              placement="topLeft"
            >
              <Button type="primary">Resend Receipt</Button>
            </Popconfirm>
            {this.props.user.isSuperAdmin && (
              <Button
                type="primary"
                disabled={this.props.common.PassStatus !== 0 && this.props.common.PassStatus !== 1}
                onClick={() => this.handleBookingRedeem(common)}
              >
                Redeem
              </Button>
            )}
            {this.props.user.isSuperAdmin && (
              <Button
                type="primary"
                onClick={() => this.handleBookingRefund(common)}
              >
                Refund
              </Button>
            )}
          </div>
          <div className="right-action" />
        </div>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  common: state.booking.common,
  customer: state.booking.customer,
  payments: state.booking.payments,
  calendar: state.booking.checkinCalendar,
  user: state.user.user,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    fetchBooking,
    resendReceipt,
    redeemBooking,
    changeCheckinDate,
  },
  dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingPage);
