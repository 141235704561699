/* eslint-disable quotes */

export function statusText(status) {
  const statuses = [
    'Active',
    'Expired',
    'Redeemed',
    'Refunded',
    'Capture',
  ];
  return statuses[status];
}

export const ProductTypes = () => [
  { key: 0, value: 'Day Pass' },
  { key: 1, value: 'Cabana' },
  { key: 2, value: 'Spa Pass' },
  { key: 3, value: 'Daybed' },
  { key: 4, value: 'Add-On' }, // the same as Fitness on website
  { key: 7, value: 'Beach Pass' },
  { key: 8, value: 'Pool and Beach Pass' },
];

export const getProductType = key => [
  { key: 0, value: 'Day Pass' },
  { key: 1, value: 'Cabana' },
  { key: 2, value: 'Spa Pass' },
  { key: 3, value: 'Daybed' },
  { key: 4, value: 'Add-On' }, // the same as Fitness on website
  { key: 7, value: 'Beach Pass' },
  { key: 8, value: 'Pool and Beach Pass' },
].find(t => t.key === key).value;

export const downloadExcelInNewTab = (response) => {
  const file = new File([response.data], 'e.xslx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const fileURL = URL.createObjectURL(file);
  const xlsWindow = window.open();
  xlsWindow.location.href = fileURL;
};
