/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
/* eslint-disable quotes */
/* eslint-disable jsx-quotes */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-useless-constructor */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button, Form, PageHeader, Input, Select,
} from 'antd';
import axios from 'axios';
import styles from './styles.module.css';
import { restoreUser, updateMyAccount } from '@/redux/actions';
import { deleteHRU } from '@/redux/actions/user';
import { HOME_PAGE_URL } from '../../helpers/const';

const { Option } = Select;

const routes = [
  {
    path: '',
    breadcrumbName: 'My account',
  },
];

class MyAccountPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <PageHeader breadcrumb={{ routes }}>
        <div className="wrap">
          <div className="content">
            <WrappedMyAccountForm
              user={this.props.user}
              history={this.props.history}
              {...this.props.actions}
            />
          </div>
        </div>
      </PageHeader>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ deleteHRU, restoreUser, updateMyAccount }, dispatch),
});

class MyAccountForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: this.props.user.firstName,
      lastName: this.props.user.lastName,
      phone: this.props.user.phone,
      email: this.props.user.email,
      marketId: this.props.user.marketId === 0 ? undefined : this.props.user.marketId,
      markets: [],
      formDisabled: true,
    };
  }

  async componentDidMount() {
    const res = await axios.get(
      `${this.url()}/hotels/markets`,
    );
    const markets = res.data.reduce((acc, curr) => [...acc, { id: curr.id, name: curr.locationName }], []);
    this.setState({ markets });
  }

  renderLocations = () => this.state.markets.map(opt => (
    <Option value={opt.id} key={opt.id}>
      {opt.name}
    </Option>
  ));

  url = () => `${process.env.REACT_APP_WEB_API}`;

  handleSubmit = async (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(async (err, fieldsValue) => {
      if (err) {
        return;
      }
      this.setState({ formDisabled: true });

      const user = {
        ...fieldsValue,
        userId: this.props.userId,
      };

      await this.props.updateMyAccount(user);
    });
  };

  handleResetPassword = () => this.props.restoreUser(this.state.email);

  renderInfo = getFieldDecorator => (
    <>
      <Form.Item label="First Name">
        {getFieldDecorator("FirstName", {
          rules: [
            {
              required: true,
              message: "Please input a first name!",
              whitespace: false,
            },
          ],
          initialValue: this.state.firstName,
        })(<Input
          name="FirstName"
          onChange={() => { if (this.state.formDisabled) this.setState({ formDisabled: false }); }}
        />)}
      </Form.Item>
      <Form.Item label="Last Name">
        {getFieldDecorator("LastName", {
          rules: [
            {
              required: true,
              message: "Please input a last name!",
              whitespace: false,
            },
          ],
          initialValue: this.state.lastName,
        })(<Input
          name="LastName"
          onChange={() => { if (this.state.formDisabled) this.setState({ formDisabled: false }); }}
        />)}
      </Form.Item>
      <Form.Item label="Phone">
        {getFieldDecorator("Phone", {
          rules: [
            {
              required: true,
              message: "Please input a phone number!",
              whitespace: false,
            },
          ],
          initialValue: this.state.phone,
        })(<Input
          name="Phone"
          onChange={() => { if (this.state.formDisabled) this.setState({ formDisabled: false }); }}
        />)}
      </Form.Item>
      <Form.Item label="Email">
        {getFieldDecorator("Email", {
          rules: [
            {
              required: true,
              type: "email",
              message: "Please input an email!",
            },
          ],
          initialValue: this.state.email,
        })(<Input name="Email" disabled />)}
      </Form.Item>
      <Form.Item label="Primary location">
        {getFieldDecorator("MarketId", {
          initialValue: this.state.marketId,
        })(
          <Select
            name="MarketId"
            onChange={() => { if (this.state.formDisabled) this.setState({ formDisabled: false }); }}
          >
            {this.renderLocations()}
          </Select>,
        )}
      </Form.Item>
    </>
  );

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        name="user"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onSubmit={this.handleSubmit}
        disabled={this.state.formDisabled}
      >
        {this.renderInfo(getFieldDecorator)}
        <div className={styles.saveButton}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={this.state.formDisabled}
          >
            Save
          </Button>

          <Button
            onClick={async () => { await this.props.history.push(HOME_PAGE_URL); }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    );
  }
}

const WrappedMyAccountForm = Form.create({ name: 'user' })(MyAccountForm);

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountPage);
