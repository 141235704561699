/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import './checkin-date-picker.css';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import CustomInput from './customInput';

const formatDate = date => (moment(date).isValid()
  ? moment(date).format('YYYY-MM-DD')
  : 'Invalid Date');

export default class CheckinDatePicker extends React.Component {
    dayPrice = (date) => {
      const { defaultPrices, customDates, bookings } = this.props.calendar;
      const bookingsDay = bookings.find(val => date.isSame(val.date, 'day'));
      if (customDates.blockedDates.find(val => date.isSame(val, 'day'))) {
        return -1;
      }

      const customPrice = customDates.customPrices.find(val => date.isSame(val.date, 'day'));
      if (customPrice) {
        return customPrice;
      }

      const defaultRange = defaultPrices.find(val => date.isBetween(val.dateStart, val.dateEnd, null, '[]'));
      if (!defaultRange) {
        return -1;
      }

      return defaultRange[date.day()];
    }

    filterDate = (val) => {
      const { price, quantity } = this.props;
      const date = moment.utc(val);
      const dayPrice = this.dayPrice(date);
      if (dayPrice === -1) {
        return false;
      }
      return dayPrice.price <= price && dayPrice.capacity >= quantity;
    }

    render() {
      const momentHaHa = formatDate(this.props.value);
      return (
        <div className="checking-date-picker">
          <DatePicker
            customInput={<CustomInput text={this.props.valueText} />}
            selected={new Date(momentHaHa)}
            minDate={new Date()}
            filterDate={this.filterDate}
            onChange={this.props.handleChange}
            dateFormat="yyyy-MM-dd"
          />
        </div>
      );
    }
}
