/* eslint-disable max-len */
/* eslint-disable quotes */
/* eslint-disable no-useless-constructor */
import React from "react";
import { useDropzone } from "react-dropzone";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Descriptions, Button } from "antd";
import Notification from '@/components/Notification';
import './dropzone-component.css';

const allowedImageExtensions = ['jpg', 'jpeg', 'png'];
const MAX_IMAGE_SIZE = 2097152;

const DropzoneComponent = ({ onDrop }) => {
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} style={{ position: "relative" }}>
      <input {...getInputProps()} />
      <div
        style={{
          color: "black",
          height: "70px",
          width: "400px",
          border: "1px dotted",
          backgroundColor: "lightgray",
        }}
      >
        <div className="drag-text">
          <Descriptions.Item label>Drag and drop image here</Descriptions.Item>
        </div>
      </div>
    </div>
  );
};

export default class Dropzone extends React.Component {
  constructor(props) {
    super(props);
  }

  handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(this.props.value);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedItems = items.map((item, index) => ({
      ...item,
      Order: index + 1,
    }));

    this.props.onChange(updatedItems);
  };

  handleDrop = (acceptedFiles) => {
    const updatedFiles = acceptedFiles
      .filter((file) => {
        console.log(file);
        const ext = file.name.split('.').pop();
        let allowed = allowedImageExtensions.includes(ext);
        if (!allowed) {
          Notification.openNotification({
            message: 'Please upload another image',
            description: 'Only .jpg, .jpeg and .png formats are allowed',
            type: 'warning',
          });
          return allowed;
        }
        allowed = file.size < MAX_IMAGE_SIZE;
        if (!allowed) {
          Notification.openNotification({
            message: 'Please upload another image',
            description: 'File size should be less than 2 mb',
            type: 'warning',
          });
          return allowed;
        }
        return allowed;
      })
      .map((file) => {
        const image = {
          Order: this.props.value.length + 1,
          IsActive: true,
          Name: file.name,
          Source: URL.createObjectURL(file),
          IsNew: true,
          ToDelete: false,
        };

        return image;
      });

    this.props.onChange([...this.props.value, ...updatedFiles]);
  };

  deleteImageNaive = async (order) => {
    const images = this.props.value.map((item) => {
      if (item.Order === order) {
        return { ...item, ToDelete: true };
      }
      return item;
    });

    this.props.onChange(images);
  };

  render() {
    return (
      <div>
        <div className="flex-element">
          {!this.props.disabled && <DropzoneComponent onDrop={this.handleDrop} />}
        </div>
        <h4>Uploaded Files:</h4>
        <ul>
          <DragDropContext onDragEnd={this.handleDragEnd}>
            <Droppable droppableId="droppable">
              {provided => (
                <div
                  className="image-table"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {this.props.value && this.props.value
                    .filter(
                      image => !image.ToDelete,
                    )
                    .map((file, index) => (
                      <Draggable
                        key={`${file.Id} ${file.Order}`}
                        draggableId={file.Order.toString()}
                        index={index}
                      >
                        {providedDraggable => (
                          <div
                            className="image-content"
                            ref={providedDraggable.innerRef}
                            {...providedDraggable.draggableProps}
                            {...providedDraggable.dragHandleProps}
                          >
                            <li key={`${file.Id} ${file.Order}`}>
                              <img
                                src={file.Source}
                                alt={file.Name}
                                style={{ maxWidth: "310px", maxHeight: "210px" }}
                              />
                            </li>
                            <div>{file.Name}</div>
                            <div className="image-button">
                              <Button
                                disabled={this.props.disabled}
                                onClick={() => this.deleteImageNaive(file.Order)}
                              >
                                DELETE
                              </Button>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ul>
      </div>
    );
  }
}
