import { combineReducers } from 'redux';

import userReducer from './user';
import detailsReducer from './details';
import inventoryReducer from './inventory';
import productsReducer from './products';
import calendarReducer from './calendar';
import bookingsReducer from './bookings';
import bookingReducer from './booking';
import searchReducer from './search';
import editHotelReducer from './editHotel';
import policyReducer from './policy';
import amenitiesReducer from './amenities';
import imageReducer from './image';

export default combineReducers({
  user: userReducer,
  details: detailsReducer,
  inventory: inventoryReducer,
  products: productsReducer,
  calendar: calendarReducer,
  bookings: bookingsReducer,
  search: searchReducer,
  booking: bookingReducer,
  editHotel: editHotelReducer,
  policy: policyReducer,
  hotelAmenties: amenitiesReducer,
  amenities: amenitiesReducer,
  image: imageReducer,
});
