import {
  REQUEST,
  SUCCESS,
  FAILURE,
  USER_LOGIN,
  USER_LOGOUT,
  USER_HOTEL,
  USER_RESTORE,
  GET_HRUS,
  DELETE_HRU,
} from '@/redux/store/action-types';
import axios from 'axios';
import action from '../action';
import Notification from '@/components/Notification';

export const loginUser = (email, pass) => async (dispatch) => {
  dispatch(action(USER_LOGIN[REQUEST]));
  try {
    // TODO env props
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/signin`, { email, password: pass });
    if (res.data.token) {
      const user = {
        ...res.data.user,
        hotels: res.data.hotels,
      };

      localStorage.setItem('token', res.data.token);
      localStorage.setItem('user', JSON.stringify(user));
      axios.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;

      let selectedHotel = { products: [] };
      if (res.data.hotels.length > 0) {
        const hotel = res.data.hotels[0];
        const hotelProducts = await axios.get(`${process.env.REACT_APP_API_URL}/user/hotel-products/${hotel.id}`);
        selectedHotel = {
          ...hotel,
          products: hotelProducts.data.products,
        };

        localStorage.setItem('selectedHotel', JSON.stringify(selectedHotel));
      }

      dispatch(action(USER_LOGIN[SUCCESS], {
        user,
        selectedHotel,
      }));

      return true;
    }
    dispatch(action(USER_LOGIN[FAILURE], { error: 'No such user' }));
  } catch (error) {
    dispatch(action(USER_LOGIN[FAILURE], { error }));
  }
  return false;
};

export const restoreUser = email => async (dispatch) => {
  dispatch(action(USER_RESTORE[REQUEST]));
  try {
    const res = await axios.post(`${process.env.REACT_APP_WEB_API}/users/reset_password`, { email });
    if (res.data) {
      Notification.openNotification({
        message: 'Activation link / Reset password was sent',
        type: 'success',
      });
      dispatch(action(USER_RESTORE[SUCCESS]));
      return true;
    }
    dispatch(action(USER_RESTORE[FAILURE], { error: 'No such user' }));
  } catch (error) {
    Notification.openNotification({
      message: 'Something happened',
      description: 'Failed to send Activation link / Reset password',
      type: 'error',
    });
    dispatch(action(USER_RESTORE[FAILURE], { error }));
  }
  return false;
};

export const validateResetPasswordToken = resetToken => async (dispatch) => {
  dispatch(action(USER_RESTORE[REQUEST]));
  try {
    await axios.post(`${process.env.REACT_APP_WEB_API}/users/reset_password/check_token`, { resetToken });
    return true;
  } catch (error) {
    return false;
  }
};

export const setNewPassword = (newPassword, resetToken) => async () => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_WEB_API}/users/reset_password/new_password`, { resetToken, newPassword });
    if (res.data) {
      Notification.openNotification({
        message: 'New password was successfully set',
        type: 'success',
      });
      return true;
    }
  } catch (error) {
    Notification.openNotification({
      message: 'Something happened',
      description: 'Failed to set a new password',
      type: 'error',
    });
  }
  return false;
};

export const updateMyPassword = (oldPassword, newPassword) => async () => {
  let res = {};
  try {
    res = await axios.post(`${process.env.REACT_APP_API_URL}/user/update-my-password`, { oldPassword, newPassword });
    if (res.data) {
      Notification.openNotification({
        message: 'New password was successfully set',
        type: 'success',
      });
      return true;
    }
  } catch (error) {
    Notification.openNotification({
      message: 'Something happened',
      description: error.response.data.message,
      type: 'error',
    });
  }
  return false;
};

export const logoutUser = () => async (dispatch) => {
  dispatch(action(USER_LOGOUT[REQUEST]));
  try {
    localStorage.clear();
    axios.defaults.headers.common.Authorization = '';

    dispatch(action(USER_LOGOUT[SUCCESS], {
      user: null,
    }));
  } catch (error) {
    dispatch(action(USER_LOGOUT[FAILURE], { error }));
  }
};


export const changeHotel = hotel => async (dispatch) => {
  dispatch(action(USER_HOTEL[REQUEST]));
  try {
    const hotelProducts = await axios.get(`${process.env.REACT_APP_API_URL}/user/hotel-products/${hotel.id}`);
    const selectedHotel = {
      ...hotel,
      products: hotelProducts.data.products,
    };
    localStorage.setItem('selectedHotel', JSON.stringify(selectedHotel));

    dispatch(action(USER_HOTEL[SUCCESS], {
      selectedHotel,
    }));
  } catch (error) {
    dispatch(action(USER_HOTEL[FAILURE], { error }));
  }
};

export const fetchHRUs = () => async (dispatch) => {
  dispatch(action(GET_HRUS[REQUEST]));
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/hrus`,
    );
    dispatch(action(GET_HRUS[SUCCESS], {
      HRUs: res.data.users,
    }));
  } catch (error) {
    dispatch(action(GET_HRUS[FAILURE], { error }));
  }
};


export const deleteHRU = id => async (dispatch) => {
  dispatch(action(DELETE_HRU[REQUEST]));
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}/user/${id}`);
    Notification.openNotification({
      message: 'User was deleted.',
      type: 'success',
    });
    dispatch(action(DELETE_HRU[SUCCESS], {
      id,
    }));
  } catch (error) {
    Notification.openNotification({
      message: 'Something happened',
      description: 'We are working on fixing problems',
      type: 'error',
    });
    dispatch(action(DELETE_HRU[FAILURE], { error }));
  }
};

export const updateMyAccount = user => async (dispatch, getState) => {
  const oldUserState = getState();
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/user/update-my-account`, { user });
    Notification.openNotification({
      message: 'Data is saved',
      description: 'Your personal information was updated.',
      type: 'success',
    });
    const newUserData = {
      ...oldUserState.user.user,
      firstName: user.FirstName,
      lastName: user.LastName,
      phone: user.Phone,
      marketId: user.MarketId,
    };
    dispatch(action(USER_LOGIN[SUCCESS], {
      user: newUserData,
    }));
    localStorage.setItem('user', JSON.stringify(newUserData));
  } catch (error) {
    Notification.openNotification({
      message: 'Something happened',
      description: 'We are working on fixing problems',
      type: 'error',
    });
  }
};
