/* eslint-disable max-len */
import {
  REQUEST,
  SUCCESS,
  FAILURE,
  SEARCH_FETCH,
  SEARCH_REDEEM,
  SEARCH_EXCEL,
} from '@/redux/store/action-types';
import action from '../action';
import axios from 'axios';
import Notification from '@/components/Notification';
import { downloadExcelInNewTab } from '../../../utils/helper';

export const fetchSearch = (page = 1, search = null, beginDate = null, endDate = null, sortField = null, sortOrder = null) => async (dispatch) => {
  dispatch(action(SEARCH_FETCH[REQUEST]));
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/search`, {
      params: {
        page, search, beginDate, endDate, sortField, sortOrder,
      },
    });
    dispatch(action(SEARCH_FETCH[SUCCESS], {
      ...res.data.bookings,
    }));
  } catch (error) {
    dispatch(action(SEARCH_FETCH[FAILURE], { error }));
  }
};

export const redeemSearchBooking = bookingId => async (dispatch) => {
  dispatch(action(SEARCH_REDEEM[REQUEST]));
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/bookings/redeem`, { bookingId });
    Notification.openNotification({
      message: 'Bookings is redeemed.',
      description: 'Your changes will take immediate effect.',
      type: 'success',
    });
    dispatch(action(SEARCH_REDEEM[SUCCESS]));
  } catch (error) {
    Notification.openNotification({
      message: 'Something happened',
      description: 'We are working on fixing problems',
      type: 'error',
    });
    dispatch(action(SEARCH_REDEEM[FAILURE], { error }));
  }
};


export const downloadSearchExcel = (hotelId, searchParam, beginDate, endDate) => async (dispatch) => {
  dispatch(action(SEARCH_EXCEL[REQUEST]));
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/bookings/excel?hotelId=${hotelId}&search=${searchParam}&beginDate=${beginDate}&endDate=${endDate}`, { responseType: 'blob' });

    downloadExcelInNewTab(response);

    dispatch(action(SEARCH_EXCEL[SUCCESS]));
  } catch (error) {
    Notification.openNotification({
      message: 'Something happened',
      description: 'We are working on fixing problems',
      type: 'error',
    });
    dispatch(action(SEARCH_EXCEL[FAILURE], { error }));
  }
};
