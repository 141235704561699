import {
  REQUEST,
  SUCCESS,
  FAILURE,
  EDIT_HOTEL_SAVE,
  EDIT_HOTEL_ADD,
  HOTEL_DELETE,
} from '@/redux/store/action-types';

const initialState = {
  hotel: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case HOTEL_DELETE[REQUEST]:
      return {
        ...state,
      };
    case HOTEL_DELETE[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case HOTEL_DELETE[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case EDIT_HOTEL_SAVE[REQUEST]:
      return {
        ...state,
      };
    case EDIT_HOTEL_SAVE[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case EDIT_HOTEL_SAVE[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case EDIT_HOTEL_ADD[REQUEST]:
      return {
        ...state,
      };
    case EDIT_HOTEL_ADD[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case EDIT_HOTEL_ADD[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
