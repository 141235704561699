/* eslint-disable react/no-unused-state */
/* eslint-disable no-confusing-arrow */
/* eslint-disable quotes */
/* eslint-disable jsx-quotes */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Table, PageHeader, Icon, Checkbox, Button, Modal, Input,
} from 'antd';
import Highlighter from 'react-highlight-words';

import styles from './styles.module.css';
import { fetchHRUs, deleteHRU } from '@/redux/actions/user';

const { confirm: confirmModal } = Modal;
const routes = [
  {
    path: '',
    breadcrumbName: 'Dashboard',
  },
  {
    path: '',
    breadcrumbName: 'Hotel Representative Users',
  },
];

class UsersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      HRUs: [],
      searchText: '',
      searchedColumn: '',
    };
  }

  async componentDidMount() {
    await this.fetchHRUs();
  }

  fetchHRUs = async () => {
    await this.props.actions.fetchHRUs();
    const formattedHRUs = this.props.HRUs.map(hru => ({
      id: hru.id,
      name: `${hru.firstName} ${hru.lastName}`,
      email: hru.email,
      isActive: hru.isActive,
    }));
    this.setState({ HRUs: [...formattedHRUs] });
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: (
      {
        setSelectedKeys, selectedKeys, confirm, clearFilters,
      },
    ) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },

    render: text => this.state.searchedColumn === dataIndex
      ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  columns = () => [
    {
      title: 'Name',
      dataIndex: 'name',
      ...this.getColumnSearchProps('name'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      ...this.getColumnSearchProps('email'),
    },
    {
      title: 'Active',
      className: 'column-money',
      dataIndex: 'isActive',
      render: text => (<Checkbox checked={text} disabled />),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (text, row) => (
        <>
          <Icon onClick={async () => { await this.editUser(row.id); }} type="edit" style={{ fontSize: '18px' }} />
          <Icon onClick={async () => { await this.showDeleteConfirm(this, row); }} type="delete" style={{ fontSize: '18px', marginLeft: '0.75em' }} />
        </>
      ),
    },
  ];

  showDeleteConfirm = (thisInstance, row) => {
    confirmModal({
      title: `Are you sure you want to Soft Delete ${row.name}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        thisInstance.deleteHRU(row.id);
      },
      onCancel() {},
    });
  }

  editUser = async (id) => {
    await this.props.history.push(`/user/${id}`);
  }

  deleteHRU = async (id) => {
    await this.props.actions.deleteHRU(id);
    this.fetchHRUs();
  }

  render() {
    return (
      <PageHeader breadcrumb={{ routes }}>
        <div className="wrap">
          <div className="content">
            <div className={styles.saveButton}>
              <Button type="primary" onClick={() => this.props.history.push(`/user/new`)}>
                Add new
              </Button>
            </div>
            <Table
              rowKey={hru => hru.id}
              columns={this.columns()}
              dataSource={this.state.HRUs}
              bordered
            />
          </div>
        </div>
      </PageHeader>
    );
  }
}

const mapStateToProps = state => ({
  HRUs: state.user.HRUs,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchHRUs,
      deleteHRU,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
