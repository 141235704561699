/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
/* eslint-disable quotes */
/* eslint-disable jsx-quotes */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-useless-constructor */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button, Form, PageHeader, Input, Select, Modal,
} from 'antd';
import axios from 'axios';
import Notification from '@/components/Notification';
import styles from './styles.module.css';
import { restoreUser } from '@/redux/actions';
import { deleteHRU } from '@/redux/actions/user';

const { Option } = Select;
const { confirm } = Modal;

const routes = [
  {
    path: '',
    breadcrumbName: 'Dashboard',
  },
  {
    path: '',
    breadcrumbName: 'Hotel Representative Users',
  },
  {
    path: '',
    breadcrumbName: 'User',
  },
];

class EditUserPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <PageHeader breadcrumb={{ routes }}>
        <div className="wrap">
          <div className="content">
            <WrappedUserForm
              userId={this.props.match.params.userId}
              history={this.props.history}
              hotels={this.props.hotels.sort((a, b) => ((a.name > b.name) ? 1 : -1))}
              {...this.props.actions}
            />
          </div>
        </div>
      </PageHeader>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  hotels: state.user.user.hotels,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ deleteHRU, restoreUser }, dispatch),
});

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: "",
      firstName: "",
      lastName: "",
      email: "",
      hotelIds: [],
      formDisabled: true,
    };
  }

  async componentDidMount() {
    const { userId } = this.props;
    if (Number(userId)) {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/${userId}`,
      );
      this.setState({ ...res.data });
    }
  }

  roles = () => [
    { key: 0, value: "Manager" },
    { key: 1, value: "Checkin Only" },
  ];

  renderRoles = () => this.roles().map(opt => (
    <Option value={opt.value} key={opt.key}>
      {opt.value}
    </Option>
  ));

  url = () => `${process.env.REACT_APP_API_URL}/user`;

  handleSubmit = async (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(async (err, fieldsValue) => {
      if (err) {
        return;
      }
      this.setState({ formDisabled: true });

      const user = {
        ...fieldsValue,
        userId: this.props.userId,
      };

      if (await this.checkEmail(this.props.userId, user.Email)) {
        const userId = await this.postUser(user, this.state.hotelIds);
        this.setState({ email: user.Email });
        await this.props.history.push(`/user/${userId}`);
      }
    });
  };

  checkEmail = async (userId, email) => {
    if (Number(userId)) {
      return true;
    }

    const isEmailOk = (await axios.get(`${this.url()}/validate-email/${email}`)).data;

    if (!isEmailOk) {
      Notification.openNotification({
        message: "A user with this email already exists",
        description: "Please enter another email",
        type: "warning",
      });
    }

    return isEmailOk;
  }

  postUser = async (user, hotelIds) => {
    let { userId } = user;
    try {
      if (Number(userId)) {
        await axios.post(`${this.url()}/update`, { user, hotelIds });
      } else {
        userId = (await axios.post(`${this.url()}/add`, { user, hotelIds })).data;
        this.setState({ isActive: true });
      }
      Notification.openNotification({
        message: "Data is saved.",
        description: "Your changes will take immediate effect.",
        type: "success",
      });
    } catch (error) {
      Notification.openNotification({
        message: "Something happened",
        description: "We are working on fixing problems",
        type: "error",
      });
    }
    return userId;
  };

  showDeleteConfirm = async (thisInstance, id) => {
    confirm({
      title: `Are you sure you want to Soft Delete this user?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        await thisInstance.deleteHRU(id);
        await thisInstance.props.history.push(`/users`);
      },
      onCancel() {},
    });
  }

  handleResetPassword = () => this.props.restoreUser(this.state.email);

  handleChangeActive = async () => {
    try {
      const newActive = !this.state.isActive;
      await axios.post(
        `${this.url()}/update-active`,
        { userId: this.props.userId, isActive: newActive },
      );
      this.setState({ isActive: newActive });
      Notification.openNotification({
        message: `User was ${newActive ? '' : 'de'}activated`,
        description: "",
        type: "success",
      });
    } catch (error) {
      Notification.openNotification({
        message: "Something happened",
        description: "We are working on fixing problems",
        type: "error",
      });
    }
  }

  deleteHRU = async (id) => {
    await this.props.deleteHRU(id);
  }

  renderInfo = getFieldDecorator => (
    <>
      <Form.Item label="First Name">
        {getFieldDecorator("FirstName", {
          rules: [
            {
              required: true,
              message: "Please input a first name!",
              whitespace: false,
            },
          ],
          initialValue: this.state.firstName,
        })(<Input
          name="FirstName"
          onChange={() => { if (this.state.formDisabled) this.setState({ formDisabled: false }); }}
        />)}
      </Form.Item>
      <Form.Item label="Last Name">
        {getFieldDecorator("LastName", {
          rules: [
            {
              required: true,
              message: "Please input a last name!",
              whitespace: false,
            },
          ],
          initialValue: this.state.lastName,
        })(<Input
          name="LastName"
          onChange={() => { if (this.state.formDisabled) this.setState({ formDisabled: false }); }}
        />)}
      </Form.Item>
      <Form.Item label="Email">
        {getFieldDecorator("Email", {
          rules: [
            {
              required: true,
              type: "email",
              message: "Please input an email!",
            },
          ],
          initialValue: this.state.email,
        })(<Input
          name="Email"
          onChange={() => { if (this.state.formDisabled) this.setState({ formDisabled: false }); }}
        />)}
      </Form.Item>
      <Form.Item label="Role">
        {getFieldDecorator("Role", {
          rules: [{ required: true, message: "Please select a role!" }],
          initialValue: this.state.role,
        })(
          <Select
            name="Role"
            onChange={() => { if (this.state.formDisabled) this.setState({ formDisabled: false }); }}
          >
            {this.renderRoles()}
          </Select>,
        )}
      </Form.Item>
    </>
  );

  renderHotels = () => (
    <Form.Item label="Managed Hotels">
      <Select
        name="hotelIds"
        mode="multiple"
        style={{ width: "100%" }}
        placeholder="Plase select hotels"
        value={this.state.hotelIds}
        onChange={this.handleChangeHotel}
        optionLabelProp="label"
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {this.props.hotels.map(hotel => (
          <Option value={hotel.id} label={hotel.name} key={hotel.id}>
            {hotel.name}
          </Option>
        ))
        }
      </Select>
    </Form.Item>
  );

  handleChangeHotel = async (value) => {
    this.setState({ hotelIds: value, formDisabled: false });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        name="user"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onSubmit={this.handleSubmit}
        disabled={this.state.formDisabled}
      >
        {this.renderInfo(getFieldDecorator)}
        {this.renderHotels()}
        <div className={styles.saveButton}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={this.state.formDisabled}
          >
            Save
          </Button>

          {!!Number(this.props.userId) && this.state.isActive && (
            <Button
              type="secondary"
              disabled={!this.state.formDisabled}
              onClick={this.handleResetPassword}
            >
              {this.state.isConfirmed ? 'Send Reset Password' : 'Send Activation Link'}
            </Button>
          )}

          {!!Number(this.props.userId) && (
            <Button
              type="danger"
              onClick={async () => { await this.showDeleteConfirm(this, Number(this.props.userId)); }}
            >
              Soft Delete
            </Button>
          )}

          {!!Number(this.props.userId) && (
            <Button
              type="secondary"
              onClick={this.handleChangeActive}
            >
              {this.state.isActive ? 'Deactivate' : 'Activate'}
            </Button>
          )}

          <Button
            onClick={async () => { await this.props.history.push(`/users`); }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    );
  }
}

const WrappedUserForm = Form.create({ name: 'user' })(UserForm);

export default connect(mapStateToProps, mapDispatchToProps)(EditUserPage);
