/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable quotes */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button, Form, Icon, Input, Typography,
} from "antd";
import { updateMyPassword } from "@/redux/actions";
import { MIN_PASSWORD_LENGTH } from '../../helpers/const';
import styles from "./styles.module.css";

const { Title } = Typography;


class MyPasswordPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      confirmPassword: "",
      oldPassword: "",
      errorStatus: null,
      error: "",
    };
  }

  componentDidMount() {
    document.title = "Dayaxe Portal - Create New Password";
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const { oldPassword, password, confirmPassword } = this.state;
    if (password.length < MIN_PASSWORD_LENGTH) {
      this.setState({
        errorStatus: "error",
        error: `Password should have at least ${MIN_PASSWORD_LENGTH} symbols`,
      });
      return;
    }
    if (confirmPassword !== password) {
      this.setState({
        errorStatus: "error",
        error: "New Password and Confirm Password should be the same",
      });
      return;
    }
    if (oldPassword === password) {
      this.setState({
        errorStatus: "error",
        error: "New Password should differ from Old Password",
      });
      return;
    }

    await this.props.actions.updateMyPassword(oldPassword, password);
    this.setState({
      password: "",
      confirmPassword: "",
      oldPassword: "",
      errorStatus: null,
      error: "",
    });
  };

  render() {
    const { error, errorStatus } = this.state;

    return (
      <div>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          onSubmit={this.handleSubmit}
        >
          <div>
            <Title level={3}>Password</Title>
          </div>
          <Form.Item label="Old password" validateStatus={errorStatus}>
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Enter old password"
              onChange={this.handleChange}
              name="oldPassword"
            />
          </Form.Item>

          <Form.Item label="New password" validateStatus={errorStatus}>
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Create a new password"
              onChange={this.handleChange}
              name="password"
            />
          </Form.Item>

          <Form.Item label="Confirm password" validateStatus={errorStatus} help={error}>
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Confirm a new password"
              onChange={this.handleChange}
              name="confirmPassword"
            />
          </Form.Item>

          <div className={styles.saveButton}>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateMyPassword,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyPasswordPage);
