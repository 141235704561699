import {
  REQUEST,
  SUCCESS,
  FAILURE,
  GET_HOTEL_POLICIES,
  HOTEL_POLICIES,
  GET_POLICIES,
  ADD_HOTEL_POLICY,
  DELETE_HOTEL_POLICY,
} from '@/redux/store/action-types';
import axios from 'axios';
import action from '../action';

export const getPolicies = () => async (dispatch) => {
  dispatch(action(GET_POLICIES[REQUEST]));
  try {
    const policies = await axios.get(`${process.env.REACT_APP_API_URL}/user/getPolicies`);

    localStorage.setItem('policies', JSON.stringify(policies.data.policies));
    dispatch(action(GET_POLICIES[SUCCESS], {
      policies: policies.data.policies,
    }));
  } catch (error) {
    dispatch(action(GET_POLICIES[FAILURE], { error }));
  }
};

export const getHotelPolicies = hotelId => async (dispatch) => {
  dispatch(action(GET_HOTEL_POLICIES[REQUEST]));
  try {
    const policies = await axios.get(`${process.env.REACT_APP_API_URL}/user/getHotelPolicies/${hotelId}`);

    const hotelPolicies = policies.data.policies.map(item => (
      { ...item, IsNew: false, ToDelete: false }
    ));

    const result = {
      data: hotelPolicies,
    };

    localStorage.setItem('hotelPolicies', JSON.stringify(hotelPolicies));
    dispatch(action(GET_HOTEL_POLICIES[SUCCESS], {
      hotelPolicies: result.data.hotelPolicies,
    }));
  } catch (error) {
    dispatch(action(GET_HOTEL_POLICIES[FAILURE], { error }));
  }
};

export const getNewHotelPolicies = () => async (dispatch) => {
  dispatch(action(GET_POLICIES[REQUEST]));
  try {
    localStorage.setItem('hotelPolicies', JSON.stringify(null));
    dispatch(action(GET_HOTEL_POLICIES[SUCCESS], {
      hotelPolicies: null,
    }));
  } catch (error) {
    dispatch(action(GET_HOTEL_POLICIES[FAILURE], { error }));
  }
};

export const changePolicy = policy => async (dispatch) => {
  dispatch(action(HOTEL_POLICIES[REQUEST]));
  try {
    const Policies = await axios.get(`${process.env.REACT_APP_API_URL}/user/getPolicies`);

    const selectedPolicy = {
      ...policy,
      policies: Policies.data.policies,
    };

    localStorage.setItem('selectedPolicy', JSON.stringify(selectedPolicy));

    dispatch(action(HOTEL_POLICIES[SUCCESS], {
      selectedPolicy,
    }));
  } catch (error) {
    dispatch(action(HOTEL_POLICIES[FAILURE], { error }));
  }
};

export const addHotelPolicyNaive = policy => async (dispatch) => {
  dispatch(action(ADD_HOTEL_POLICY[REQUEST]));
  try {
    const policies = await axios.get(`${process.env.REACT_APP_API_URL}/user/getPolicies`);

    const newPolicy = policies.data.policies.filter(pol => pol.Id === policy.PolicyId)[0];

    let hotelPolicies = JSON.parse(localStorage.getItem('hotelPolicies'));

    let maxElem = 0;

    if (hotelPolicies && hotelPolicies.length > 0) {
      maxElem = hotelPolicies.reduce((acc, curr) => (acc.Id > curr.Id ? acc : curr)).Id;
    } else {
      hotelPolicies = [];
    }

    const addPolicy = {
      HotelId: policy.HotelId,
      IsDelete: newPolicy.IsDelete,
      Name: newPolicy.Name,
      Order: policy.Order,
      PolicyId: policy.PolicyId,
      ProductId: policy.ProductId,
      Id: maxElem + 1,
      IsNew: true,
      ToDelete: false,
    };

    hotelPolicies.push(addPolicy);

    const result = {
      data: hotelPolicies,
    };

    localStorage.setItem('hotelPolicies', JSON.stringify(hotelPolicies));
    dispatch(action(ADD_HOTEL_POLICY[SUCCESS]), {
      hotelPolicies: result.data.hotelPolicies,
    });
  } catch (error) {
    dispatch(action(ADD_HOTEL_POLICY[FAILURE], { error }));
  }
};

export const deleteHotelPolicyNaive = policy => async (dispatch) => {
  dispatch(action(DELETE_HOTEL_POLICY[REQUEST]));
  try {
    const hotelPolicies = JSON.parse(localStorage.getItem('hotelPolicies'));

    const newhotelPolicies = hotelPolicies.map((item) => {
      if (item.Id === policy.Id) {
        return { ...item, ToDelete: true };
      }
      return item;
    });

    const result = {
      data: newhotelPolicies,
    };

    localStorage.setItem('hotelPolicies', JSON.stringify(newhotelPolicies));
    dispatch(action(DELETE_HOTEL_POLICY[SUCCESS]), {
      hotelPolicies: result.data.newhotelPolicies,
    });
  } catch (error) {
    dispatch(action(DELETE_HOTEL_POLICY[FAILURE], { error }));
  }
};

export const addHotelPolicy = policy => async (dispatch) => {
  dispatch(action(ADD_HOTEL_POLICY[REQUEST]));
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/user/addHotelPolicy`, { policy });

    const policies = await axios.get(`${process.env.REACT_APP_API_URL}/user/getHotelPolicies/${policy.HotelId}`);

    const hotelPolicies = policies.data.policies.map(item => (
      { ...item, IsNew: false, ToDelete: false }
    ));

    const result = {
      data: hotelPolicies,
    };
    localStorage.setItem('hotelPolicies', JSON.stringify(hotelPolicies));
    dispatch(action(ADD_HOTEL_POLICY[SUCCESS]), {
      hotelPolicies: result.data.hotelPolicies,
    });
  } catch (error) {
    dispatch(action(ADD_HOTEL_POLICY[FAILURE], { error }));
  }
};

export const deleteHotelPolicy = policy => async (dispatch) => {
  dispatch(action(DELETE_HOTEL_POLICY[REQUEST]));
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}/user/deleteHotelPolicy/${policy.Id}`);

    const policies = await axios.get(`${process.env.REACT_APP_API_URL}/user/getHotelPolicies/${policy.HotelId}`);

    const newHotelPolicies = policies.data.policies.map(item => (
      { ...item, IsNew: false, ToDelete: false }
    ));

    const result = {
      data: newHotelPolicies,
    };

    localStorage.setItem('hotelPolicies', JSON.stringify(newHotelPolicies));
    dispatch(action(DELETE_HOTEL_POLICY[SUCCESS]), {
      hotelPolicies: result.data.newHotelPolicies,
    });
  } catch (error) {
    dispatch(action(DELETE_HOTEL_POLICY[FAILURE], { error }));
  }
};
