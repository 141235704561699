/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { Button } from 'antd';

export default class CustomInput extends React.Component {
/* <button
        className="example-custom-input"
        onClick={this.props.onClick}
        >
        {this.props.value}
      </button> */
  render() {
    return (

      <Button
        icon="calendar"
        onClick={this.props.onClick}
      >
        {' '}
        {this.props.text}
      </Button>
    );
  }
}
