import {
  REQUEST,
  SUCCESS,
  FAILURE,
  DETAILS_FETCH,
  DETAILS_SAVE,
} from '@/redux/store/action-types';
import axios from 'axios';
import action from '../action';
import Notification from '@/components/Notification';

export const fetchDetails = hotelId => async (dispatch) => {
  dispatch(action(DETAILS_FETCH[REQUEST]));
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/hotel-details/${hotelId}`);

    dispatch(action(DETAILS_FETCH[SUCCESS], {
      details: res.data.details,
    }));
  } catch (error) {
    dispatch(action(DETAILS_FETCH[FAILURE], { error }));
  }
};

export const saveDetails = details => async (dispatch) => {
  dispatch(action(DETAILS_SAVE[REQUEST]));
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/details/${details.HotelId}`, { details });
    Notification.openNotification({
      message: 'Data is saved.',
      description: 'Data is saved.',
      type: 'success',
    });
    dispatch(action(DETAILS_SAVE[SUCCESS]));
  } catch (error) {
    Notification.openNotification({
      message: 'Something happened',
      description: 'We are working on fixing problems',
      type: 'error',
    });
    dispatch(action(DETAILS_SAVE[FAILURE], { error }));
  }
};
