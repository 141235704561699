
import axios from 'axios';

export default () => {
  try {
    const token = localStorage.getItem('token');
    if (token === null) {
      return;
    }
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } catch (err) {
    // Ignore
  }
};
