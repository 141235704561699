/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import LayoutHotel from './components/LayoutHotel';
import HomePage from './pages/HomePage';

import 'antd/dist/antd.css';

const HotelRoute = ({ component: Component, selectedHotel, ...rest }) => {
  const hotelId = selectedHotel ? selectedHotel.id : '';
  return (
    <Route
      {...rest}
      render={matchProps => (
        hotelId ? (
          <LayoutHotel {...matchProps}>
            <Component {...matchProps} />
          </LayoutHotel>
        ) : (
          <LayoutHotel {...matchProps}>
            <HomePage />
          </LayoutHotel>
        )
      )}
    />
  );
};

const mapStateToProps = state => ({
  selectedHotel: state.user.selectedHotel,
});

const connectedApp = connect(mapStateToProps)(HotelRoute);
export default connectedApp;
