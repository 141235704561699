import {
  REQUEST,
  SUCCESS,
  FAILURE,
  BOOKINGS_FETCH,
  BOOKINGS_REDEEM,
  BOOKINGS_EXCEL,
} from '@/redux/store/action-types';


const initialState = {
  bookings: [],
  total: 0,
  isBookingsFetching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BOOKINGS_FETCH[REQUEST]:
      return {
        ...state,
        isBookingsFetching: true,
      };
    case BOOKINGS_FETCH[SUCCESS]:
      return {
        ...state,
        ...action.payload,
        isBookingsFetching: false,
      };
    case BOOKINGS_FETCH[FAILURE]:
      return {
        ...state,
        ...action.payload,
        isBookingsFetching: false,
      };
    case BOOKINGS_REDEEM[REQUEST]:
      return {
        ...state,
      };
    case BOOKINGS_REDEEM[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case BOOKINGS_REDEEM[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case BOOKINGS_EXCEL[REQUEST]:
      return {
        ...state,
      };
    case BOOKINGS_EXCEL[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case BOOKINGS_EXCEL[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
