/* eslint-disable react/prefer-stateless-function */

import React from 'react';
import './refund.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Row,
  Select,
  Radio,
  InputNumber,
  Button,
} from 'antd';

import {
  fetchBooking,
  refundPayment,
} from '@/redux/actions';

const { Option } = Select;

class RefundModal extends React.Component {
  constructor(props) {
    super(props);
    const payment = this.props.payments[0];

    this.state = {
      refundModal: {
        refundType: 'full',
        refundAmount: 0,
        maxAmount: 0,
        paymentId: payment.PaymentId,
        method: 'card',
      },
    };
  }


  handleChangePayment = (value) => {
    this.setState({
      refundModal: {
        ...this.state.refundModal,
        paymentId: value,
      },
    });
  }

  changeRefundType = (e) => {
    this.setState({
      refundModal: {
        ...this.state.refundModal,
        refundType: e.target.value,
      },
    });
  }

  changeRefundMethod = (value) => {
    this.setState({
      refundModal: {
        ...this.state.refundModal,
        method: value,
      },
    });
  }

  changeRefundAmount = (e) => {
    this.setState({
      refundModal: {
        ...this.state.refundModal,
        refundAmount: e,
      },
    });
  }

  refundPayment = async () => {
    const { refundModal } = this.state;
    const payment = this.props.payments.find(val => val.PaymentId === refundModal.paymentId);
    const amount = refundModal.refundType === 'full' ? payment.Amount : refundModal.refundAmount * 100;
    const { method } = refundModal;
    await this.props.actions.refundPayment(
      refundModal.paymentId,
      amount,
      method,
    );
    this.props.onOk();
  }


  render() {
    const { payments } = this.props;
    const { refundModal } = this.state;
    const currentPayment = this.props.payments.find(val => val.PaymentId === refundModal.paymentId);

    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };

    return (
      <div className="refund-dialog">
        <React.Fragment>
          <Row>
            <span>Payment: </span>
            {'  '}
            <Select
              value={refundModal.paymentId}
              size="small"
              placeholder="Select payment method"
              style={{ width: 250 }}
              onChange={this.handleChangePayment}
            >
              {payments.filter(val => val.Type === 'PAYPAL' || val.Type === 'STRIPE' || val.Type === 'CREDITS').map(val => (
                <Option key={val.PaymentId} value={val.PaymentId}>
                  {`${val.Description} - ${val.PaymentMethod} ${val.Card4Num ? val.Card4Num : ''} - $ ${(val.Amount / 100).toFixed(2)}`}
                </Option>
              ))}
            </Select>
          </Row>
          <Row>
            <Radio.Group onChange={this.changeRefundType} value={refundModal.refundType}>
              <Radio style={radioStyle} value="full">
              Full (Refund full amount)
              </Radio>
              <Radio style={radioStyle} value="partial">
              Partial (Refund a partial amount)
              </Radio>
            </Radio.Group>
          </Row>
          {refundModal.refundType === 'partial'
            ? (
              <Row>
                <InputNumber
                  min={0}
                  step={0.5}
                  defaultValue={0}
                  formatter={value => `$ ${value.replace(' ', '')}`}
                  parser={value => value.replace('$', '')}
                  onChange={e => this.changeRefundAmount(e)}
                />
              </Row>
            )
            : ''}
          <Row>
            <span>Method: </span>
            {'  '}

            <Select
              value={currentPayment.PaymentMethod === 'CREDITS' ? 'credit' : refundModal.method}
              size="small"
              placeholder="Select payment method"
              style={{ width: 250 }}
              onChange={this.changeRefundMethod}
            >
              {console.log(currentPayment)}
              {currentPayment.PaymentMethod !== 'CREDITS' ? (
                <Option key="card" value="card">
                  {currentPayment.PaymentMethod}
                  {' '}
                  {currentPayment.Card4Num}
                </Option>
              ) : null}

              <Option key="credit" value="credit">
                Credit Balance
              </Option>
            </Select>
          </Row>
          <br />
          <Row>
            <Button onClick={this.props.onCancel}>Cancel</Button>
            {' '}
            <Button loading={this.props.isRefunding} onClick={this.refundPayment} type="primary">Refund</Button>
          </Row>
        </React.Fragment>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  payments: state.booking.payments,
  isRefunding: state.booking.isRefunding,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    fetchBooking,
    refundPayment,
  },
  dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundModal);
