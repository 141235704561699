/* eslint-disable max-len */
import {
  REQUEST,
  SUCCESS,
  FAILURE,
  CALENDAR_DATE_RANGE,
  CALENDAR_PRODUCT,
  CALENDAR_SET_MONTH,
  CALENDAR_DATE_RANGE_CANCEL,
  CALENDAR_PRODUCT_SAVE,
} from '@/redux/store/action-types';
import dayjs from 'dayjs';

// const dayForced = dayjs().format('YYYY-MM-DD');
const initialState = {
  selectedBeginDate: null,
  selectedEndDate: null,
  currentMonth: dayjs().date(1).format('YYYY-MM-DD'),
  products: [],
  isSaving: false,
  isLoading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CALENDAR_DATE_RANGE_CANCEL:
      return {
        ...state,
        ...action.payload,
      };
    case CALENDAR_DATE_RANGE:
      return {
        ...state,
        ...action.payload,
      };
    case CALENDAR_SET_MONTH:
      return {
        ...state,
        ...action.payload,
      };

    case CALENDAR_PRODUCT[REQUEST]:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case CALENDAR_PRODUCT[SUCCESS]:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case CALENDAR_PRODUCT[FAILURE]:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    case CALENDAR_PRODUCT_SAVE[REQUEST]:
      return {
        ...state,
        error: null,
        isSaving: true,
      };
    case CALENDAR_PRODUCT_SAVE[SUCCESS]:
      return {
        ...state,
        ...action.payload,
        isSaving: false,
        selectedBeginDate: null,
        selectedEndDate: null,
      };
    case CALENDAR_PRODUCT_SAVE[FAILURE]:
      return {
        ...state,
        ...action.payload,
        isSaving: false,
      };
    default:
      return state;
  }
};
