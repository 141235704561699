import {
  REQUEST,
  SUCCESS,
  FAILURE,
  BOOKING_FETCH,
  BOOKING_RECEIPT,
  BOOKING_REFUND,
  BOOKING_CHECKIN_DATE,
} from '@/redux/store/action-types';


const initialState = {
  common: null,
  customer: null,
  payments: [],
  checkinCalendar: null,
  isRefunding: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_FETCH[REQUEST]:
      return {
        ...state,
      };
    case BOOKING_FETCH[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case BOOKING_FETCH[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case BOOKING_RECEIPT[REQUEST]:
      return {
        ...state,
      };
    case BOOKING_RECEIPT[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case BOOKING_RECEIPT[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    case BOOKING_REFUND[REQUEST]:
      return {
        ...state,
        isRefunding: true,
      };
    case BOOKING_REFUND[SUCCESS]:
      return {
        ...state,
        ...action.payload,
        isRefunding: false,
      };
    case BOOKING_REFUND[FAILURE]:
      return {
        ...state,
        ...action.payload,
        isRefunding: false,
      };
    case BOOKING_CHECKIN_DATE[REQUEST]:
      return {
        ...state,
      };
    case BOOKING_CHECKIN_DATE[SUCCESS]:
      return {
        ...state,
        ...action.payload,
      };
    case BOOKING_CHECKIN_DATE[FAILURE]:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
