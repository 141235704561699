/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import LayoutHotel from './components/LayoutHotel';
import DetailsPage from '@/pages/DetailsPage';
import CalendarPage from '@/pages/CalendarPage';
import InventoryPage from '@/pages/InventoryPage';
import ProductsPage from '@/pages/ProductsPage';
import BookingsPage from '@/pages/BookingsPage';
import LoginPage from '@/pages/LoginPage';
import ForgotPage from '@/pages/ForgotPage';
import SearchPage from '@/pages/SearchPage';
import BookingPage from '@/pages/BookingPage';
import EditHotelPage from '@/pages/EditHotelPage';
import AddHotelPage from '@/pages/AddHotelPage';
import EditProductPage from '@/pages/EditProductPage';
import UsersPage from '@/pages/UsersPage';
import EditUserPage from '@/pages/EditUserPage';

import 'antd/dist/antd.css';
import HotelRoute from './HotelRoute';
import SuperAdminRoute from './SuperAdminRoute';

const LayoutRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={matchProps => (
      <LayoutHotel {...matchProps}>
        <Component {...matchProps} />
      </LayoutHotel>
    )}
  />
);

class App extends React.Component {
  render() {
    const hotelId = this.props.user && this.props.user.selectedHotel ? this.props.user.selectedHotel.id : '';
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <Route exact path="/forgot-password" component={ForgotPage} />
          <HotelRoute exact path="/details" component={DetailsPage} key={hotelId} />
          <HotelRoute exact path="/calendar" component={CalendarPage} key={hotelId} />
          <HotelRoute exact path="/inventory" component={InventoryPage} />
          <HotelRoute exact path="/products" component={ProductsPage} />
          <HotelRoute exact path="/product/:productId" component={EditProductPage} />
          <SuperAdminRoute exact path="/users" component={UsersPage} />
          <SuperAdminRoute exact path="/user/:userId" component={EditUserPage} />
          <HotelRoute exact path="/bookings" component={BookingsPage} key={hotelId} />
          <SuperAdminRoute exact path="/search" component={SearchPage} />
          <HotelRoute exact path="/editHotel" component={EditHotelPage} />
          <LayoutRoute exact path="/addHotel" component={AddHotelPage} />
          <HotelRoute exact path="/booking/:bookingId" component={BookingPage} />
          <Route path="*" component={LoginPage} />
        </Switch>
      </Router>
    );
  }
}
function mapStateToProps(state) {
  const { user } = state;
  return {
    user,
  };
}

const connectedApp = connect(mapStateToProps)(App);
export default connectedApp;
